import { useParams } from "react-router";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import dayjs from "dayjs";
import { ClickableTypography } from "../../../../../../components/ClickableTypography";
import { useState } from "react";
import { DateOfLossSelectionModal } from "./DateOfLossSelectionModal";

type DateOfLossDetailsProps = {};

function DateOfLossDetails({}: DateOfLossDetailsProps) {
  const { claimId } = useParams();
  if (!claimId) return null;

  const [dateOfLossSelectionModalOpen, setDateOfLossSelectionModalOpen] = useState(false);

  const claim = useSelectedClaimPageStore((state) => state.claim);

  if (claim?.lossDate) {
    return (
      <>
        <ClickableTypography sx={{ fontSize: 14, fontWeight: 600 }} onClick={() => setDateOfLossSelectionModalOpen(true)}>
          Date of loss {dayjs(claim?.lossDate).format("MM/DD/YY")}
        </ClickableTypography>
        {dateOfLossSelectionModalOpen && <DateOfLossSelectionModal setModalOpen={setDateOfLossSelectionModalOpen} />}
      </>
    );
  }

  return <></>;
}

export { DateOfLossDetails };
