import { Box, Card, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { enqueueSnackbar } from "notistack";

type SendSettlementConfirmationModalProps = {
  setModalOpen: (modalOpen: boolean) => void;
};

function SendSettlementConfirmationModal({ setModalOpen }: SendSettlementConfirmationModalProps) {
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const settlementDataQuery = useQuery({
    queryKey: [`getSettlementData-${claim!.id!}`],
    queryFn: async () => {
      const response = await ClaimsApi.getReleaseFormDataForClaim(claim!.id!);
      return response.data;
    },
    enabled: !!claim?.id,
  });

  const formattedSettlementAmount = settlementDataQuery.data?.Settlement
    ? formatCurrency(Number(settlementDataQuery.data.Settlement) / 100)
    : null; // Format the string to go from pennies to currency string ex. 1200 -> $12.00

  const sendSettlementButtonClicked = async () => {
    setRequestLoading(true);
    if (!settlementDataQuery.data || !formattedSettlementAmount) return;
    const requestObject = {
      ...settlementDataQuery.data,
      Settlement: formattedSettlementAmount,
    };
    try {
      await ClaimsApi.sendReleaseFormForClaim(claim!.id!, requestObject);
      await refetchClaim();
      setModalOpen(false);
      enqueueSnackbar("Settlement sent successfully", { variant: "success" });
    } catch (err) {
      enqueueSnackbar("Failed to create settlement. please try again", {
        variant: "error",
      });
    }
    setRequestLoading(false);
  };

  return (
    <Modal
      onClose={() => setModalOpen(false)}
      open={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        {settlementDataQuery.isLoading || settlementDataQuery.isFetching ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : settlementDataQuery.error ? (
          <>error... try refreshing the page</>
        ) : (
          <Stack padding={3} spacing={2}>
            <Typography sx={{ fontSize: 21, fontWeight: 500 }}>Are you sure you want to generate a settlement for this claim?</Typography>
            <Stack spacing={1}>
              <DataDisplayComponent label="Name" value={settlementDataQuery.data!.Name} />
              <DataDisplayComponent label="Facility address" value={settlementDataQuery.data!.Address_from_Store_Location} />
              <DataDisplayComponent label="Facility owner" value={settlementDataQuery.data!.Owner} />
              <DataDisplayComponent label="Unit" value={settlementDataQuery.data!.Unit} />
              <DataDisplayComponent label="Loss date" value={settlementDataQuery.data!.Loss_Date} />
              <DataDisplayComponent label="Settlement amount" value={formattedSettlementAmount || ""} />
            </Stack>
            <SafeLeaseButton onClick={sendSettlementButtonClicked} loading={requestLoading} variant="contained">
              Send settlement
            </SafeLeaseButton>
            <SafeLeaseButton onClick={() => setModalOpen(false)} variant="outlined" color="navy">
              Cancel
            </SafeLeaseButton>
          </Stack>
        )}
      </Card>
    </Modal>
  );
}

// Formatted text display to avoid duplicated code accross each key val to display to the adjuster
const DataDisplayComponent = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ fontSize: 15 }}>{label}:</Typography>
      <Typography sx={{ fontWeight: 500 }}>{value}</Typography>
    </Stack>
  );
};

export { SendSettlementConfirmationModal };
