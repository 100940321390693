import { Stack, Typography } from "@mui/material";

interface PrivatePolicyDetailsSectionProps {
  title: string;
  value: string | number | boolean | Date | undefined;
}

/* A metadata key:value display */
export function PrivatePolicyDetailsSection({ title, value }: PrivatePolicyDetailsSectionProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        sx={{
          fontSize: "14px",
          color: (theme) => theme.palette.grey[800],
          fontFamily: "Open Sans",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          color: (theme) => theme.palette.grey[600],
          fontFamily: "Open Sans",
          textAlign: "right",
        }}
      >
        {value?.toString() ?? "-"}
      </Typography>
    </Stack>
  );
}
