import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { ForgotPasswordStages, useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import { Stack, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { confirmResetPassword } from "aws-amplify/auth";

type NewPasswordSubmitContentProps = {};

type NewPasswordFormValues = {
  password: string;
  passwordConfirmation: string;
  verificationCode: string;
};

const validationSchema = z
  .object({
    verificationCode: z.string().min(1, "Verification code is required."),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
    passwordConfirmation: z.string().min(1, "Confirm password is required."),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"], // This specifies which field the error is associated with
  });

function NewPasswordSubmitContent({}: NewPasswordSubmitContentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const setForgotPasswordStage = useForgotPasswordPageStore((state) => state.setForgotPasswordStage);
  const email = useForgotPasswordPageStore((state) => state.email);

  const methods = useForm<NewPasswordFormValues>({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
      verificationCode: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onButtonClicked: SubmitHandler<NewPasswordFormValues> = async (data) => {
    setLoading(true);
    try {
      await confirmResetPassword({ confirmationCode: data.verificationCode, username: email, newPassword: data.password });
      setForgotPasswordStage(ForgotPasswordStages.ALL_DONE);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onButtonClicked)}>
        <Stack spacing={2}>
          <Stack spacing={0.5} alignItems="center">
            <Typography sx={{ fontSize: 23, fontWeight: 500 }}>Set new password</Typography>
            <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>
              Enter the code sent to your email and set a new password
            </Typography>
          </Stack>
          <Stack spacing={1} alignItems="center">
            <SafeLeaseFormTextField
              name="verificationCode"
              sx={{ width: 400 }}
              label="Enter verification code"
              helperText={methods.formState.errors.verificationCode?.message}
              error={!!methods.formState.errors.verificationCode?.message}
            />
            <SafeLeaseFormTextField
              name="password"
              sx={{ width: 400 }}
              label="Password"
              type="password"
              helperText={methods.formState.errors.password?.message}
              error={!!methods.formState.errors.password?.message}
            />
            <SafeLeaseFormTextField
              name="passwordConfirmation"
              sx={{ width: 400 }}
              label="Confirm password"
              type="password"
              helperText={methods.formState.errors.passwordConfirmation?.message}
              error={!!methods.formState.errors.passwordConfirmation?.message}
            />
          </Stack>
          <SafeLeaseButton type="submit" loading={loading} variant="contained">
            Reset password
          </SafeLeaseButton>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { NewPasswordSubmitContent };
