import { SafeLeaseDataGrid } from "@safelease/components";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router";
import { columnDefs } from "../../../types/prospects/prospects.ts";
import { ProspectPaginationModel } from "./ProspectDataTab.tsx";
import { Box } from "@mui/material";
import { Prospect } from "@safelease/service-utilities/types/Prospects/prospect";
import Typography from "@mui/material/Typography";

export interface ProspectsTableProps {
  prospects: Prospect[];
  paginationModel: ProspectPaginationModel;
  isLoading: boolean;
  totalProspectCount: number;
  onPaginationModelChange?: (paginationModel: ProspectPaginationModel) => void;
}

export function ProspectsTable(props: ProspectsTableProps) {
  const navigate = useNavigate();

  const formatedRows = props.prospects.map((prospect) => {
    return {
      ...prospect,
      placesRating: prospect.placesRating || "—",
      placesName: prospect.placesName ? prospect.placesName : prospect.sfdcName || "—",
      placesAddress: prospect.placesPlaceId
        ? `${prospect.placesAddress}, ${prospect.placesCity}, ${prospect.placesState} ${prospect.placesPostalCode}`
        : "—",
      sfdcAddress: prospect.sfdcAddress
        ? `${prospect.sfdcAddress}, ${prospect.sfdcCity}, ${prospect.sfdcState} ${prospect.sfdcPostalCode}`
        : "—",
    };
  });

  const ZeroState = () => {
    return (
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Typography>No prospects found</Typography>
      </Box>
    );
  };

  return (
    <SafeLeaseDataGrid
      rows={formatedRows}
      columns={columnDefs}
      pagination
      paginationModel={props.paginationModel}
      onPaginationModelChange={props.onPaginationModelChange}
      paginationMode="server"
      pageSizeOptions={[10, 25, 50]} // Options for page size in the pagination bar
      rowCount={props.totalProspectCount}
      onRowClick={(params: GridRowParams<Prospect>) => navigate("/prospects/" + params.row.id)}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
            page: 0,
          },
        },
      }}
      loading={props.isLoading}
      sx={{
        borderColor: "#EBEFF7",
        // Set the height and overflow for the DataGrid
        height: "100%", // Ensure it fills the Card height
        overflow: "auto", // Enable scrolling
        "& .MuiDataGrid-cell": {
          borderColor: "#EBEFF7",
          cursor: "pointer",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none", // Removes cell selection outline
        },
      }}
      hideFooter={false}
      slots={{ noRowsOverlay: ZeroState }}
    />
  );
}
