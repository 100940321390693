import { Card, CircularProgress, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useNewRequestModalStore } from "./useNewRequestModalStore";
import CloseIcon from "@mui/icons-material/Close";
import { SafeLeaseButton, SafeLeaseTextField } from "@safelease/components";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useAdHocRequestsDrawerStore } from "../useAdHocRequestsDrawerStore";

type NewRequestModalProps = {};

function NewRequestModal({}: NewRequestModalProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const { newRequestModalOpen, setNewRequestModalOpen } = useNewRequestModalStore();
  const refetchAdHocRequests = useAdHocRequestsDrawerStore((state) => state.refetchAdHocRequests);
  const [adjusterMessage, setAdjusterMessage] = useState<string>("");
  const [requestPending, setRequestPending] = useState<boolean>(false);

  const handleClose = () => {
    setNewRequestModalOpen(false);
    setAdjusterMessage("");
  };

  const handleRequestButtonClicked = async () => {
    setRequestPending(true);
    try {
      const createTenantActionResponse = await ClaimsApi.createTenantAction({
        type: TenantActionTypes.AD_HOC_REQUEST,
        claimId,
        metadata: {
          [TenantActionTypes.AD_HOC_REQUEST]: {
            requestMessage: adjusterMessage,
          },
        },
      });
      const tenantActionId = createTenantActionResponse.data.id;
      await ClaimsApi.createClaimMessage({
        claimId,
        tenantActionId,
      });

      refetchAdHocRequests();
      setRequestPending(false);
      handleClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Failed to create request", { variant: "error" });
      setRequestPending(false);
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={newRequestModalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ padding: 2, minWidth: 500 }}>
        {/* Header Section */}
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", paddingBottom: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 20 }}>New request</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {/* main content */}
        <Stack paddingTop={2} spacing={3}>
          <Stack spacing={1}>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Adjuster message</Typography>
            <SafeLeaseTextField
              value={adjusterMessage}
              onChange={(e) => setAdjusterMessage(e.target.value)}
              multiline
              minRows={2}
              maxRows={10}
            />
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {requestPending ? (
              <CircularProgress />
            ) : (
              <>
                <SafeLeaseButton variant="outlined" color="navy" onClick={handleClose}>
                  Cancel
                </SafeLeaseButton>
                <SafeLeaseButton disabled={!adjusterMessage.trim()} variant="contained" onClick={handleRequestButtonClicked}>
                  Request
                </SafeLeaseButton>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export default NewRequestModal;
