import { Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useForgotPasswordPageStore, useSignInPageStore } from "./useForgotPasswordPageStore";

type AllDoneContentProps = {};

function AllDoneContent({}: AllDoneContentProps) {
  const { setPage } = useSignInPageStore();
  const clear = useForgotPasswordPageStore((state) => state.clear);
  const goBackToSignIn = () => {
    setPage("signIn");
  };

  return (
    <Stack spacing={3} alignItems="center">
      <Stack spacing={0.5} alignItems="center">
        <Typography sx={{ fontSize: 23, fontWeight: 500 }}>All done</Typography>
        <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>
          Your password has been reset so now you can sign in to your account
        </Typography>
      </Stack>
      <SafeLeaseButton
        onClick={() => {
          clear();
          {
            goBackToSignIn();
          }
        }}
        variant="contained"
        size="small"
        fullWidth
      >
        Sign In
      </SafeLeaseButton>
    </Stack>
  );
}

export default AllDoneContent;
