import { SafeLeaseButton, SafeLeaseFileDropzone } from "@safelease/components";
import { FilePreviews } from "./FilePreviews";
import { Box, ButtonProps, CircularProgress } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import heic2any from "heic2any";
import { useState } from "react";

type FileUploadDropzoneProps = {
  handleNewFiles: (files: File[]) => void;
  uploadedFiles?: { file: File; previewUrl: string }[];
  onSubmit?: () => void;
  uploadButtonProps?: ButtonProps;
};

function FileUploadDropzone({ handleNewFiles, uploadedFiles, uploadButtonProps, onSubmit }: FileUploadDropzoneProps) {
  const [loading, setLoading] = useState(false);

  const convertHeicToJpeg = (file: File): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const blob = new Blob([event.target?.result as ArrayBuffer]);
        heic2any({ blob })
          .then((conversionResult) => {
            resolve(
              new File([conversionResult as Blob], file.name.replace(/\.heic$/i, ".jpeg"), {
                type: "image/jpeg",
                lastModified: Date.now(),
              }),
            );
          })
          .catch((error) => {
            reject(error);
          });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const onFilesUpload = async (files: File[]) => {
    setLoading(true);
    const newFiles = [];
    for (const file of files) {
      if (file.type === "image/heic") {
        newFiles.push(await convertHeicToJpeg(file));
      } else {
        newFiles.push(file);
      }
    }
    handleNewFiles(newFiles);
    setLoading(false);
  };

  return (
    <>
      <SafeLeaseFileDropzone
        containerProps={{
          sx: {
            border: "1px dashed rgba(3, 30, 48, 0.2)",
            borderRadius: "5px",
          },
        }}
        uploadButtonProps={uploadButtonProps}
        accept={{
          "image/png": [".png"],
          "image/jpeg": [".jpeg", ".jpg"],
          "image/heic": [".heic"],
          "application/pdf": [".pdf"],
          "video/mp4": [".mp4"],
          "video/webm": [".webm"],
          "video/ogg": [".ogv"],
          "video/quicktime": [".mov"],
        }}
        handleNewFiles={onFilesUpload}
      />
      {uploadedFiles && <FilePreviews files={uploadedFiles} />}
      {loading && <CircularProgress />}
      {uploadedFiles && uploadedFiles.length > 0 && onSubmit && !loading && (
        <Box sx={{ overflowX: "auto", display: "flex" }}>
          <SafeLeaseButton onClick={onSubmit} startIcon={<FileUploadIcon />} variant="outlined">
            Submit Files
          </SafeLeaseButton>
        </Box>
      )}
    </>
  );
}

export { FileUploadDropzone };
