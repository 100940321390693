import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SignInPage } from "../../pages/SignInPage";
import { PageNotFound } from "./PageNotFound";

type NonAuthenticatedRouterProps = {};

/**
 * These are all of the routes that exist on the FE for the unauthenticated users.
 */

function NonAuthenticatedRouter({}: NonAuthenticatedRouterProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export { NonAuthenticatedRouter };
