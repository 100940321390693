export function formatPercentageShort(number: number): string {
  if (isNaN(number)) return "-";
  return number || number === 0 ? (number * 100).toFixed(2) + "%" : "";
}

export function formatWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function jsDateToGraphQlDate(jsDate: Date | null): string | null {
  if (jsDate) {
    return jsDate.toISOString().split("T")[0];
  }
  return null;
}

export function jsDateToLocalTime(dateTime: string): string {
  return dateTime ? `${new Date(dateTime).toDateString()} ${new Date(dateTime).toLocaleTimeString()}` : "";
}
