import { Box, Stack, Typography } from "@mui/material";
import { LegacyClaim } from "@safelease/service-utilities";
import { ImageViewer } from "../../../../../components/ImageViewer";

type AttachmentsSectionProps = {
  legacyClaim: LegacyClaim;
};

function AttachmentsSection({ legacyClaim }: AttachmentsSectionProps) {
  return (
    <Stack>
      <Typography sx={{ fontWeight: 700, color: "rgba(3, 30, 48, 1)", fontSize: 14 }}>Attachments</Typography>
      {legacyClaim?.attachments?.length === 0 ? (
        <Typography>No files</Typography>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1} paddingY={1} overflow="auto" sx={{ width: "100%" }}>
          {legacyClaim?.attachments?.map((attachment) => (
            <Box key={attachment.id}>
              <ImageViewer
                fileName={attachment.fileName || ""}
                file={attachment.url}
                fileSize={attachment.fileSize ?? null}
                archived={!!attachment.deletedAt}
              />
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export { AttachmentsSection };
