import { InputAdornment, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { SafeleaseLocationMatchData } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { Search } from "@mui/icons-material";
import { AxiosResponse } from "axios";
import { NumericFormat } from "react-number-format";

type IdInputProps = {
  onSearchButtonClickCallback: (
    apiCall: Promise<AxiosResponse<SafeleaseLocationMatchData, any>>,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
};

function IdInput({ onSearchButtonClickCallback }: IdInputProps) {
  const [userProvidedLocationId, setUserProvidedLocationId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearchButtonClick = async () => {
    await onSearchButtonClickCallback(ClaimsApi.getSafeleaseLocationById(userProvidedLocationId), setIsLoading);
  };

  return (
    <Stack direction="row" spacing={2}>
      <NumericFormat
        allowNegative={false}
        value={userProvidedLocationId}
        onChange={(e) => {
          setUserProvidedLocationId(e.target.value);
        }}
        fullWidth
        customInput={TextField}
        decimalScale={0}
        placeholder="Location id: ex. 123456"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <SafeLeaseButton
        loading={isLoading}
        disabled={!userProvidedLocationId.trim()}
        onClick={onSearchButtonClick}
        color="navy"
        variant="contained"
      >
        Search
      </SafeLeaseButton>
    </Stack>
  );
}

export { IdInput };
