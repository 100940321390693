import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseActionView } from "./BaseActionView";
import { LocationDropdown } from "../LocationDropDown";
import { useDataChangeManager } from "../../useDataChangeManager";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";

interface UpdateLocationSfdcPushingViewProps {}

function UpdateLocationSfdcPushingView({}: UpdateLocationSfdcPushingViewProps) {
  const {
    getAllLocationsQuery,
    selectedLocationIds,
    locationExemptFromSfdcPushing,
    relationshipSelectorId,
    setLocationExemptFromSfdcPushing,
  } = useDataChangeManager();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setLocationExemptFromSfdcPushing(value === "true");
  };

  return (
    <BaseActionView actionTitle="Update Location Exempt From SFDC Pushing">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl variant="outlined">
          <InputLabel id="locationExemptFromSfdcPushing">Select Value</InputLabel>
          <Select
            id="locationExemptFromSfdcPushing"
            labelId="locationExemptFromSfdcPushing"
            value={locationExemptFromSfdcPushing.toString()}
            label="Select Value"
            onChange={handleSelectChange}
            sx={{ minWidth: "250px", width: "50vw" }}
            disabled={!selectedLocationIds.length}
          >
            <MenuItem value={"false"}>False</MenuItem>
            <MenuItem value={"true"}>True</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </BaseActionView>
  );
}

export { UpdateLocationSfdcPushingView };
