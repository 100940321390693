import { Box, Stack } from "@mui/material";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { PrivatePolicyFieldSelectionSection } from "./PrivatePolicyFieldSelectionSection";
import { SectionType } from "./useComplianceCenter";
import dayjs from "dayjs";

interface PrivatePolicyReviewFormProps {
  privatePolicy: SafeleasePrivatePolicy;
}

/* Enables a user to review and/or manually override the AI-extracted values for a private policy */
export function PrivatePolicyReviewForm({ privatePolicy }: PrivatePolicyReviewFormProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Stack
        direction="column"
        sx={{
          flex: 2,
        }}
        spacing={3}
      >
        <PrivatePolicyFieldSelectionSection
          userInputValue={privatePolicy?.tenantName}
          categoryTitle="Tenant Name"
          sectionId={SectionType.TENANT_NAME}
        />
        <PrivatePolicyFieldSelectionSection
          userInputValue={privatePolicy?.policyNumber}
          aiExtractedValue={privatePolicy?.extractedPolicyNumber}
          categoryTitle="Private Policy Number"
          sectionId={SectionType.POLICY_NUMBER}
        />
        <PrivatePolicyFieldSelectionSection
          userInputValue={dayjs(privatePolicy?.expiration).format("MM/DD/YYYY")}
          aiExtractedValue={privatePolicy?.extractedExpiration && dayjs(privatePolicy?.extractedExpiration).format("MM/DD/YYYY")}
          categoryTitle="Private Policy Expiration Date"
          sectionId={SectionType.EXPIRATION_DATE}
        />
      </Stack>
    </Box>
  );
}
