import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Key as KeyIcon } from "@mui/icons-material";

import { JobError } from "@safelease/service-utilities/types/Jobs/JobResponses";

export function buildJobDetailsProperties(selectedJobError: JobError | null) {
  // List of properties to display related to the partner / location / job
  let properties = [];

  // Job ID
  properties.push({
    label: "Job ID",
    jobErrorKey: "id",
  });

  // Relationship
  properties.push({
    label: "Relationship",
    jobErrorKey: "relationshipName",
  });

  // If the job is tied to an FMS specifically, display the FMS
  if (selectedJobError && selectedJobError.fms) {
    properties.push({
      label: "FMS",
      jobErrorKey: "fms",
    });
  }

  // If the job is tied to a location, display the location details
  if (selectedJobError && selectedJobError.locationId !== -1) {
    properties.push({
      label: "Location ID",
      jobErrorKey: "locationId",
    });

    properties.push({
      label: "Location Name",
      jobErrorKey: "locationName",
    });

    properties.push({
      label: "Location Address",
      jobErrorKey: "locationAddress",
      renderer: (locationAddress: string, sx = {}) => (
        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
          <Tooltip title="View credentials">
            <IconButton
              target="_blank"
              href={`https://app.safelease.com/admin/tools/credentials/${selectedJobError && selectedJobError.locationId}`}
              size="small"
            >
              <KeyIcon />
            </IconButton>
          </Tooltip>
          <Typography sx={sx}>{locationAddress}</Typography>
        </Stack>
      ),
    });
  }

  // Method
  properties.push({
    label: "Method",
    jobErrorKey: "method",
  });

  return properties;
}
