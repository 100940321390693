import { Box, Card, Checkbox, CircularProgress, FormControlLabel, Modal, Stack, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { FileUploadDropzone } from "../../../../../../components/FileUploadDropzone";
import { enqueueSnackbar } from "notistack";
import { useClaimDocumentViewerModalStore } from "../../BodyCard/MetricsAndActionsCard/ClaimDocumentViewerModal/useClaimDocumentViewerModalStore";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";

interface UploadedFileWithPreview {
  file: File;
  previewUrl: string;
}

type UploadFileModalProps = {
  setOpen: (open: boolean) => void;
  uploadFilesCallback: (files: File[], optionalMetadata: { createEmailedFacilityNote?: boolean }) => Promise<void>;
  title: string;
};

function UploadFileModal({ setOpen, uploadFilesCallback, title }: UploadFileModalProps) {
  const [requestPending, setRequestPending] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileWithPreview[]>([]);
  const [createEmailedFacilityNote, setCreateEmailedFacilityNote] = useState<boolean>(false); // only controlled by additional documentation type uploads
  const documentListRefetch = useClaimDocumentViewerModalStore((state) => state.documentListRefetch);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const isAdditionalDocumentation = title === "Additional Documentation";

  const onSubmit = async () => {
    setRequestPending(true);
    try {
      await uploadFilesCallback(
        uploadedFiles.map((file) => file.file),
        { createEmailedFacilityNote },
      );
      enqueueSnackbar("Files uploaded successfully", { variant: "success" });
      if (isAdditionalDocumentation && createEmailedFacilityNote) {
        enqueueSnackbar("Emailed facility note created successfully", { variant: "success" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error uploading files, please try again.", { variant: "error" });
    }
    documentListRefetch();
    refetchClaim();
    setRequestPending(false);
    setOpen(false);
  };

  const handleNewFiles = (files: File[]) => {
    setUploadedFiles((currentFiles) => {
      const allFiles = [...currentFiles];
      for (const file of files) {
        allFiles.push({
          file,
          previewUrl: URL.createObjectURL(file),
        });
      }
      return allFiles;
    });
  };

  return (
    <Modal
      open={true}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Stack padding={2} width={500} spacing={2}>
          <>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: (theme: Theme) => theme.palette.grey.A200,
              }}
            >
              {title}
            </Typography>
            {requestPending ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <FileUploadDropzone handleNewFiles={handleNewFiles} uploadedFiles={uploadedFiles} onSubmit={onSubmit} />
                {isAdditionalDocumentation && (
                  <FormControlLabel
                    control={
                      <Checkbox checked={createEmailedFacilityNote} onChange={(e) => setCreateEmailedFacilityNote(e.target.checked)} />
                    }
                    label="Create emailed facility note"
                  />
                )}
              </>
            )}
          </>
        </Stack>
      </Card>
    </Modal>
  );
}

export { UploadFileModal };
