import { Box, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { FormQuestionDef_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useState } from "react";

type QuestionDisplayProps = {
  formQuestionDef: FormQuestionDef_ClaimsDbModel;
  pendingTenantActionsFormQuestionDefIds: string[];
  refetchQueries: () => Promise<void>;
};

function QuestionDisplay({ formQuestionDef, pendingTenantActionsFormQuestionDefIds, refetchQueries }: QuestionDisplayProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [requestLoading, setRequestLoading] = useState<boolean>(false);

  const handleRequestButtonClick = async () => {
    setRequestLoading(true);
    try {
      //Create the Tenant Action for the given type based on which button was clicked
      const response = await ClaimsApi.createTenantAction({
        type: TenantActionTypes.FORM_QUESTION_ANSWER,
        claimId: claimId,
        metadata: {
          [TenantActionTypes.FORM_QUESTION_ANSWER]: {
            formQuestionDefId: formQuestionDef.id,
          },
        },
      });
      const tenantActionId = response.data.id;

      //Create the claim message for the adjuster to request the document
      await ClaimsApi.createClaimMessage({
        claimId: claimId,
        tenantActionId,
      });
      setRequestLoading(false);
      await refetchQueries();
    } catch (error) {
      setRequestLoading(false);
      enqueueSnackbar("Error requesting documentation", { variant: "error" });
      console.log(error);
    }
  };
  return (
    <Stack>
      <Stack>
        <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Question</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{formQuestionDef.question}</Typography>
      </Stack>
      <Stack paddingLeft={3}>
        {formQuestionDef.formQuestionResponses?.length ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "&::before": {
                  content: '"•"',
                  display: "inline-block",
                  marginRight: "6px",
                  fontSize: 20,
                },
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Answer</Typography>
            </Box>
            <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
              {/* as of now we only allow for one response per question but this could change in the future */}
              {formQuestionDef.formQuestionResponses[0].response}
            </Typography>
          </>
        ) : requestLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography>N/A</Typography>
            <Box>
              {pendingTenantActionsFormQuestionDefIds.includes(formQuestionDef.id) ? (
                <SafeLeaseCard sx={{ boxShadow: 0, padding: 0.5 }}>
                  <Tooltip
                    title="This question has already been requested from the tenant. Waiting for their response..."
                    placement="right"
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <WatchLaterIcon sx={{ color: "#EED202" }} />
                      <Typography sx={{ fontSize: 12, fontWeight: 500, color: "red" }}>Pending response</Typography>
                    </Stack>
                  </Tooltip>
                </SafeLeaseCard>
              ) : (
                <SafeLeaseButton onClick={handleRequestButtonClick} size="small" variant="contained">
                  Request
                </SafeLeaseButton>
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export { QuestionDisplay };
