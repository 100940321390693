import { Card, Modal } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";
import { SafeleaseApi } from "../../../../../../../utils/apiInstances/SafeleaseApiInstance";
import { SafeLeaseDataGrid } from "@safelease/components";
import { columnDefs } from "./columnDefs";
type PaymentHistoryModalProps = {
  setModalOpen: (open: boolean) => void;
};

function PaymentHistoryModal({ setModalOpen }: PaymentHistoryModalProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const leaseId = claim?.unit?.safeleaseLeaseId;
  if (!leaseId) return null;

  const insurancePaymentsQuery = useQuery({
    queryKey: ["getInsurancePayments", leaseId],
    queryFn: async () => {
      const response = await SafeleaseApi.getInsurancePaymentsForLease(leaseId);
      return response.data;
    },
  });
  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal open={true} onClose={handleClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card sx={{ minHeight: 200, maxHeight: "75vh", width: "65vw", overflow: "auto", padding: 3 }}>
        <SafeLeaseDataGrid
          rows={insurancePaymentsQuery.data ?? []}
          columns={columnDefs}
          autoHeight
          loading={insurancePaymentsQuery.isLoading}
        />
      </Card>
    </Modal>
  );
}

export { PaymentHistoryModal };
