import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { cloneElement } from "react";

type ActionCardsProps = {
  tools: {
    label: string;
    handleClick: () => void;
    icon: React.ReactElement;
    hide?: boolean;
  }[];
};

function ActionCards({ tools }: ActionCardsProps) {
  return (
    <Grid container direction="row" spacing={2}>
      {tools.map((tool) => {
        if (!tool || tool.hide) return null;
        return (
          <Grid item xs={6} sm={4} lg={4} xl={3} key={tool.label}>
            <Card
              onClick={() => tool.handleClick()}
              sx={{
                boxShadow: `0 0 20px rgba(0, 0, 0, 0.05)`,
                border: "1px solid #EBEFF7",
                bgcolor: "white",
                color: blue[400],
                width: "100%",
                py: 2,
                cursor: "pointer",
                transition: "150ms",
                position: "relative",
                "&:hover": {
                  bgcolor: blue[100],
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: 70,
                    width: "100%",
                  }}
                >
                  {cloneElement(tool.icon, { sx: { height: "48px", width: "auto" } })}
                </Box>
                <Typography variant="subtitle1" sx={{ textAlign: "center", fontWeight: "bold", color: "#152744", mt: 2 }}>
                  {tool.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

export { ActionCards };
