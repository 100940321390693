import { create } from "zustand";
import { persist } from "zustand/middleware";

export enum ForgotPasswordStages {
  ALL_DONE = "allDone",
  EMAIL_SUBMIT = "emailSubmit",
  NEW_PASSWORD_SUBMIT = "newPasswordSubmit",
}
type ForgotPasswordPageStore = {
  forgotPasswordStage: ForgotPasswordStages;
  setForgotPasswordStage: (forgotPasswordStage: ForgotPasswordStages) => void;
  email: string; // need to keep context of which email was submitted for forgot password when it comes time to reset the password
  setEmail: (email: string) => void;
  clear: () => void;
};

export const useForgotPasswordPageStore = create<ForgotPasswordPageStore>()(
  persist(
    (set) => ({
      forgotPasswordStage: ForgotPasswordStages.EMAIL_SUBMIT,
      setForgotPasswordStage: (forgotPasswordStage) => set({ forgotPasswordStage }),
      email: "",
      setEmail: (email) => set({ email }),
      clear: () =>
        set({
          forgotPasswordStage: ForgotPasswordStages.EMAIL_SUBMIT,
          email: "",
        }),
    }),
    {
      name: "forgot-password-page",
    },
  ),
);

// Store types for the Sign-In page.
interface SignInPageStore {
  page: "signIn" | "newPassword" | "forgotPassword";
  setPage: (page: "signIn" | "newPassword" | "forgotPassword") => void;
}

// Create the sign-in page store.
export const useSignInPageStore = create<SignInPageStore>((set) => ({
  page: "signIn",
  setPage: (page) => set({ page }),
}));
