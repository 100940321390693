import { Box } from "@mui/material";

type FilePreviewsProps = {
  files: { file: File; previewUrl: string }[];
};

function FilePreviews({ files }: FilePreviewsProps) {
  return (
    <Box sx={{ overflowX: "auto", display: "flex" }}>
      {files?.map((file) => (
        <Box sx={{ padding: "20px" }} key={file.previewUrl}>
          {file.file.type.includes("video") ? (
            <video style={{ width: "100px", height: "100px" }} controls>
              <source src={file.previewUrl} type={file.file.type === "video/quicktime" ? "video/mp4" : file.file.type} />
              Your browser does not support showing the video.
            </video>
          ) : (
            <img style={{ width: "100px", height: "100px" }} src={file.previewUrl} />
          )}
        </Box>
      ))}
    </Box>
  );
}

export { FilePreviews };
