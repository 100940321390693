import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { create } from "zustand";

type TenantCommunicationDrawerStore = {
  refetchMessages: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetClaimMessageResponse[], Error> | void>; // this will be tied to the refetch function from react-query for the selectedClaimQuery
  setRefetchMessages: (
    refetchMessages: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetClaimMessageResponse[], Error> | void>,
  ) => void;
};

export const useTenantCommunicationDrawerStore = create<TenantCommunicationDrawerStore>()((set) => ({
  refetchMessages: async () => {},
  setRefetchMessages: (refetchMessages) => {
    set({ refetchMessages });
  },
}));
