import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useDataChangeManager } from "../useDataChangeManager";

interface RelationshipDropdownProps {}

function RelationshipDropDown({}: RelationshipDropdownProps) {
  const { relationshipSelectorId, setSelectedLocationIds, setRelationshipSelectorId, getAllRelationshipsQuery } = useDataChangeManager();

  const handleRelationshipSelectorChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setRelationshipSelectorId(value);
    setSelectedLocationIds([]);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel id="relationship-selector-label">Select Relationship</InputLabel>
      <Select
        label="Select Relationship"
        labelId="relationship-selector-label"
        value={relationshipSelectorId}
        onChange={handleRelationshipSelectorChange}
        sx={{ minWidth: "250px", width: "50vw" }}
      >
        {getAllRelationshipsQuery.data?.data?.map((relationship) => (
          <MenuItem key={relationship.id} value={relationship.id}>
            {relationship.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { RelationshipDropDown };
