import { useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { useDataChangeManager } from "./useDataChangeManager";
import { SelectChangeEvent } from "@mui/material";
import { ActionValueEnum } from "./pages/config";
import dayjs from "dayjs";

const useActionHandlers = () => {
  const {
    actionType,
    selectedLocationIds,
    selectedDate,
    textFieldState,
    credentials,
    userSelectorId,
    userSelectorRole,
    locationExemptFromSfdcPushing,
    relationshipSelectorId,
    relationshipBillingRequired,
    setConfirmDialogOpen,
    setTextFieldState,
    setActionType,
    setSelectedLocationIds,
    clearAllFields,
    setRelationshipSelectorId,
    addAdminRequestMutation,
  } = useDataChangeManager();

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  // Helper function to simplify the mutate call
  const createMutationPayload = (inputData: any) => {
    const sharedData = {
      note: textFieldState.note,
      type: actionType,
    };

    addAdminRequestMutation.mutate({
      ...sharedData,
      inputData,
    });
  };

  // This function is used to handle the confirm action for the selected action. Runs the mutation to add the admin request for the selected action.
  const handleConfirmAction = () => {
    switch (actionType) {
      case ActionValueEnum.DELETE_LOCATION:
      case ActionValueEnum.DISCONNECT_FMS_FROM_LOCATION:
        createMutationPayload({ locationId: selectedLocationIds[0] });
        break;
      case ActionValueEnum.UPDATE_LIVE_DATE:
        createMutationPayload({
          locationId: selectedLocationIds[0],
          liveDate: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
        });
        break;
      case ActionValueEnum.OFFBOARD_FACILITIES:
        createMutationPayload({ locationIds: selectedLocationIds });
        break;
      case ActionValueEnum.UPDATE_LOCATION_SERVICE_ID:
        createMutationPayload({
          locationId: selectedLocationIds[0],
          serviceId: textFieldState.serviceId,
        });
        break;
      case ActionValueEnum.UPDATE_ACCOUNT_CREDENTIALS:
        createMutationPayload({
          accountId: textFieldState.accountId,
          credentials: credentials,
        });
        break;
      case ActionValueEnum.UPDATE_LOCATION_CANCEL_DATE:
        createMutationPayload({
          locationId: selectedLocationIds[0],
          cancelDate: selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
        });
        break;
      case ActionValueEnum.UPDATE_LOCATION_EXEMPT_SFDC_PUSHING:
        createMutationPayload({
          locationId: selectedLocationIds[0],
          exemptSfdcPushing: locationExemptFromSfdcPushing,
        });
        break;
      case ActionValueEnum.UPDATE_USER_ROLE:
        createMutationPayload({
          userId: userSelectorId,
          role: userSelectorRole,
        });
        break;
      case ActionValueEnum.UPDATE_RELATIONSHIP_BILLING_REQUIRED:
        createMutationPayload({
          relationshipId: relationshipSelectorId,
          billingRequired: relationshipBillingRequired,
        });
        break;
      default:
        console.error("Unknown action type:", actionType);
    }

    clearAllFields();
    handleConfirmDialogClose();
  };

  const handleTextFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value, id } = e.target;
      setTextFieldState(id, value);
    },
    [setTextFieldState],
  );

  const validateActions = () => {
    const errorMessage = "Please fill out all fields.";

    switch (actionType) {
      case ActionValueEnum.DELETE_LOCATION:
      case ActionValueEnum.OFFBOARD_FACILITIES:
      case ActionValueEnum.DISCONNECT_FMS_FROM_LOCATION:
        if (selectedLocationIds.length === 0 || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_LIVE_DATE:
      case ActionValueEnum.UPDATE_LOCATION_CANCEL_DATE:
        if (selectedLocationIds.length === 0 || !selectedDate || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_LOCATION_SERVICE_ID:
        if (selectedLocationIds.length === 0 || !textFieldState.serviceId || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_ACCOUNT_CREDENTIALS:
        if (!textFieldState.accountId || !credentials || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_LOCATION_EXEMPT_SFDC_PUSHING:
        if (!selectedLocationIds?.length) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_USER_ROLE:
        if (!userSelectorId || !userSelectorRole || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return false;
        }
        break;
      case ActionValueEnum.UPDATE_RELATIONSHIP_BILLING_REQUIRED:
        if (!relationshipSelectorId || !textFieldState.note) {
          enqueueSnackbar(errorMessage, { variant: "error" });
          return;
        }
        break;
    }

    return true; // return true if no validation errors
  };

  const handleSubmitClick = useCallback(() => {
    // Validate before proceeding
    if (!validateActions()) {
      return; // Exit if validation fails
    }
    setConfirmDialogOpen(true);
  }, [validateActions, setConfirmDialogOpen]);

  const handleUpdateActionChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setActionType(value);
    setSelectedLocationIds([]);
    setRelationshipSelectorId("");
  };

  return {
    handleTextFieldChange,
    handleSubmitClick,
    validateActions,
    handleUpdateActionChange,
    handleConfirmAction,
    handleConfirmDialogClose,
    addAdminRequestMutation,
  };
};

export default useActionHandlers;
