import { SafeLeaseChip } from "@safelease/components";
import dayjs from "dayjs";

type StatusChipProps = {
  tenantAcceptanceStatus: boolean | undefined | null;
  responseDate: string | undefined;
};

function StatusChip({ tenantAcceptanceStatus, responseDate }: StatusChipProps) {
  if (tenantAcceptanceStatus === null) {
    return (
      <SafeLeaseChip
        label="Awaiting tenant acceptance"
        sx={{
          color: "rgba(3, 30, 48, 1)",
          backgroundColor: "rgba(203, 223, 246, 1)",
        }}
      />
    );
  } else if (tenantAcceptanceStatus === true) {
    // Tenant has accepted the offer
    return (
      <SafeLeaseChip
        label={`Tenant accepted offer - ${dayjs(responseDate).format("MM/DD/YY")}`}
        sx={{
          color: "rgba(37, 155, 36, 1)",
          backgroundColor: "rgba(37, 155, 36, 0.1)",
        }}
      />
    );
  } else {
    return (
      <SafeLeaseChip
        label={`Tenant rejected offer - ${dayjs(responseDate).format("MM/DD/YY")}`}
        sx={{
          color: "rgba(233, 100, 95, 1)",
          backgroundColor: "rgba(233, 100, 95, 0.1)",
        }}
      />
    );
  }
}

export { StatusChip };
