import { Stack, Theme, Typography } from "@mui/material";
import { AdjusterNote_ClaimsDbModel } from "@safelease/service-utilities";
import { SafeLeaseAvatarWithText } from "@safelease/components";
import dayjs from "dayjs";

type NoteProps = {
  data: AdjusterNote_ClaimsDbModel;
};

function Note({ data }: NoteProps) {
  return (
    <Stack spacing={1.5}>
      <SafeLeaseAvatarWithText
        text={`${data.cognitoUserDetail?.userFirstName} ${data.cognitoUserDetail?.userLastName}`}
        formattedDate={dayjs(data.createdAt).format("MM/DD/YY")}
      />
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: 500,
          color: (theme: Theme) => theme.palette.grey.A200,
          whiteSpace: "pre-wrap", // make sure new lines are displayed
        }}
      >
        {data.note}
      </Typography>
    </Stack>
  );
}

export { Note };
