import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { ClaimStatusChip } from "../../../../../components/ServiceUIComponents/ClaimsService/ClaimStatusChip";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { ClaimsNavigatorTableRow } from "@safelease/service-utilities";
import dayjs from "dayjs";

export const columnDefs: GridColDef<ClaimsNavigatorTableRow>[] = [
  {
    field: "tenantName",
    headerName: "Tenant",
    flex: 1,
    valueGetter: (params) => {
      return params.row.claimantNameOverride ? `${params.row.claimantNameOverride} (${params.value})` : params.value;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "submittedOn",
    headerName: "Submitted Date",
    flex: 1,
    valueFormatter: (params) => (params.value ? dayjs(params.value).format("MM-DD-YY HH:mm A") : null),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => <ClaimStatusChip status={params.value as ClaimStatus} />,
  },
  {
    field: "closedAt",
    headerName: "Closed Date",
    flex: 1,
    valueFormatter: (params) => (params.value ? dayjs(params.value).format("MM-DD-YY") : null),
  },
  {
    field: "updatedAt",
    headerName: "Last Modified",
    flex: 1,
    valueFormatter: (params) => dayjs(params.value).format("MM-DD-YY HH:mm A"),
  },
  {
    field: "causeOfLoss",
    headerName: "Cause of loss",
    flex: 1,
  },
  {
    field: "coverage",
    headerName: "Coverage",
    flex: 1,
    valueFormatter: (params) => (params.value ? formatCurrency(params.value / 100) : null),
  },
  {
    field: "claimedLoss",
    headerName: "Claimed loss",
    flex: 1,
    valueFormatter: (params) => formatCurrency(params.value / 100),
  },
  {
    field: "adjusterValue",
    headerName: "Adjuster value",
    flex: 1,
    valueFormatter: (params) => (params.value ? formatCurrency(params.value / 100) : null),
  },
  {
    field: "facilityAddress",
    headerName: "Facility",
    flex: 1,
  },
  {
    field: "relationshipName",
    headerName: "Partner",
    flex: 1,
  },
  {
    field: "lossDate",
    headerName: "Date of loss",
    flex: 1,
    valueFormatter: (params) => (params.value ? dayjs(params.value).format("MM-DD-YY") : null),
  },
];
