import { useParams } from "react-router";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useQuery } from "@tanstack/react-query";
import { FormQuestionDef_ClaimsDbModel } from "@safelease/service-utilities";
import { Box, CircularProgress } from "@mui/material";
import { QuestionDisplay } from "./QuestionDisplay";

type FormQuestionsSectionProps = {};

function FormQuestionsSection({}: FormQuestionsSectionProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const formQuestionDefsQuery = useQuery<FormQuestionDef_ClaimsDbModel[]>({
    queryKey: ["form-question-defs", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getFormQuestionDefs(claimId);
      return response.data;
    },
  });

  /**
   * This query returns a list of form question def ids that have been requested by the adjuster but have not been answered by the tenant
   * We use this list to display a different UI for form questions that have already been requested
   */
  const pendingFormQuestionTenantActionsQuery = useQuery({
    queryKey: ["pending-form-question-tenant-actions", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getPendingFormQuestionDefRequestIdsForTenantActions(claimId);
      return response.data;
    },
  });

  const refetchQueries = async () => {
    await formQuestionDefsQuery.refetch();
    await pendingFormQuestionTenantActionsQuery.refetch();
  };

  if (formQuestionDefsQuery.isFetching || pendingFormQuestionTenantActionsQuery.isFetching)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {formQuestionDefsQuery.data?.map((formQuestionDef) => (
        <QuestionDisplay
          key={formQuestionDef.id}
          formQuestionDef={formQuestionDef}
          pendingTenantActionsFormQuestionDefIds={pendingFormQuestionTenantActionsQuery.data || []}
          refetchQueries={refetchQueries}
        />
      ))}
    </>
  );
}

export { FormQuestionsSection };
