import { useQuery } from "@tanstack/react-query";
import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { SafeleaseApi } from "../../utils/apiInstances/SafeleaseApiInstance";
import { UnitBreakdownSankey } from "./UnitBreakdownSankey";
import { UnitBreakdownDataGrid } from "./UnitBreakdownDataGrid";
import { SafeLeaseCard } from "@safelease/components";
import { useState } from "react";

export interface UnitBreakdownData {
  allUnits: number;
  occupied: number;
  unoccupied: number;
  insurable: number;
  notInsurable: number;
  enrolledInSafeLeasePolicy: number;
  notEnrolled: number;
  enrolledInPrivatePolicy: number;
  eligibleForAutoProtect: number;
  eligibleForRollover: number;
  other: number;
}

interface UnitBreakdownApiResponse {
  data: UnitBreakdownData;
}

export function UnitBreakdownReport() {
  const [highlightKey, setHighlightKey] = useState<string>();

  const unitBreakdownQuery = useQuery<UnitBreakdownApiResponse>({
    queryKey: ["unit-breakdown-data"],
    queryFn: () => SafeleaseApi.unitStatusSummaryReport(),
  });

  if (unitBreakdownQuery.isLoading)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", minHeight: 200 }}>
        <CircularProgress size={50} />
        <Typography>Calculating unit statuses, this may take a few moments...</Typography>
      </Box>
    );
  if (unitBreakdownQuery.isError) return <Alert severity="error">Something went wrong.</Alert>;
  if (!unitBreakdownQuery?.data?.data) return <Alert severity="error">Something went wrong.</Alert>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SafeLeaseCard sx={{ p: 2 }}>
          <UnitBreakdownSankey data={unitBreakdownQuery.data.data} highlightKey={highlightKey} />
        </SafeLeaseCard>
      </Grid>
      <Grid item xs={12}>
        <SafeLeaseCard sx={{ p: 2 }}>
          <Typography fontWeight="bold">Unit Status Breakdown</Typography>
          <UnitBreakdownDataGrid data={unitBreakdownQuery.data.data} setHighlightKey={setHighlightKey} />
        </SafeLeaseCard>
      </Grid>
    </Grid>
  );
}
