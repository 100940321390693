import { Divider, Stack } from "@mui/material";
import { DrawerHeader } from "../sharedComponents/DrawerHeader";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import dayjs from "dayjs";
import { FormQuestionsSection } from "./FormQuestionsSection";
import { ClaimDocumentDisplaySection } from "./ClaimDocumentDisplaySection";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { TypeSpecificAttachmentSection } from "./TypeSpecificAttachmentSection";

type ClaimFormDetailsDrawerProps = {};

function ClaimFormDetailsDrawer({}: ClaimFormDetailsDrawerProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  return (
    <Stack sx={{ width: 700, height: "100%", padding: "16px" }} spacing={2} divider={<Divider />}>
      <DrawerHeader title="Claim submission form" subTitle={`Submitted on ${dayjs(claim?.submittedOn).format("MM/DD/YY")}`} />

      <Stack overflow="auto" spacing={3} divider={<Divider />} padding={1}>
        {/* display the files that every claim submission requires, as of now that is the protection plan addendum and the billing ledger */}
        <ClaimDocumentDisplaySection
          question="Protection plan addendum"
          attachments={
            (claim?.protectionPlanAddendumDocs
              ?.map((doc) => doc.attachment)
              ?.filter((attachment) => attachment !== undefined) as Attachment_ClaimsDbModel[]) || []
          }
        />
        <ClaimDocumentDisplaySection
          question="Billing ledger"
          attachments={
            (claim?.billingLedgerDocs
              ?.map((doc) => doc.attachment)
              ?.filter((attachment) => attachment !== undefined) as Attachment_ClaimsDbModel[]) || []
          }
        />
        {/* display all of the simple text questions that the tenant answered */}
        <FormQuestionsSection />
        {/* nested ClaimDocumentDisplaySection components in TypeSpecificAttachmentSection but wrapped in data normalization logic */}
        <TypeSpecificAttachmentSection allTypeSpecificAttachments={claim?.typeSpecificAttachments || []} />
      </Stack>
    </Stack>
  );
}

export { ClaimFormDetailsDrawer };
