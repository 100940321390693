import { ExpandMore } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { SafeLeaseChip, SafeLeaseMenu, SafeLeaseMenuItem } from "@safelease/components";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { useState } from "react";

type ClaimStatusChipProps = {
  status: ClaimStatus;
  editable?: boolean;
  dropdownOptions?: ClaimStatus[];
  onStatusClicked?: (selectedStatus: ClaimStatus) => Promise<void>;
};

function ClaimStatusChip({ status, editable, dropdownOptions, onStatusClicked }: ClaimStatusChipProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //only ever true if the editable prop is true
  if (isLoading) {
    return <CircularProgress />;
  }

  // Editable claim status chip with dropdown
  if (editable) {
    const handleMenuItemClick = async (claimStatus: ClaimStatus) => {
      if (!onStatusClicked) return;
      setAnchorEl(null); // close dropdown
      setIsLoading(true);
      await onStatusClicked(claimStatus);
      setIsLoading(false);
    };
    return (
      <>
        <SafeLeaseChip
          onClick={(e) => setAnchorEl(e.currentTarget)}
          icon={<ExpandMore />}
          label={statusToStyleAndLabel[status]?.label}
          sx={{
            flexDirection: "row-reverse",
            paddingY: 2,
            ".MuiChip-icon": {
              marginLeft: 0,
              marginRight: 0,
            },
            "&:hover": {
              cursor: "pointer",
              bgcolor: statusToStyleAndLabel[status]?.sx.bgcolor,
              opacity: 0.8,
            },
            ...statusToStyleAndLabel[status]?.sx,
          }}
        />
        <SafeLeaseMenu
          placement="top-start"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
          sx={{ zIndex: 1, width: 300 }}
        >
          {dropdownOptions?.map((option, i) => (
            <SafeLeaseMenuItem
              sx={{
                bgcolor: "#f5f5f5",
                borderTopLeftRadius: i === 0 ? 10 : 0,
                borderTopRightRadius: i === 0 ? 10 : 0,
                borderBottomRightRadius: i === dropdownOptions.length - 1 ? 10 : 0,
                borderBottomLeftRadius: i === dropdownOptions.length - 1 ? 10 : 0,
              }}
              key={option}
              label={statusToStyleAndLabel[option]?.label || ""}
              onClick={() => handleMenuItemClick(option)}
            />
          ))}
        </SafeLeaseMenu>
      </>
    );
  }

  // Regular claim status chip
  return <SafeLeaseChip label={statusToStyleAndLabel[status]?.label} sx={{ paddingY: 2, ...statusToStyleAndLabel[status]?.sx }} />;
}

const statusToStyleAndLabel: {
  [key in ClaimStatus]?: {
    sx: {
      bgcolor: string;
      color: string;
    };
    label: string;
  };
} = {
  [ClaimStatus.NewClaim]: {
    sx: {
      bgcolor: "rgba(39, 121, 251, 0.2)",
      color: "rgba(39, 121, 251, 1)",
    },
    label: "New claim",
  },
  [ClaimStatus.NeedsReview]: {
    sx: {
      bgcolor: "rgba(239, 184, 6, 0.3)",
      color: "rgba(3, 30, 48, 1)",
    },
    label: "Adjuster review",
  },
  [ClaimStatus.NeedsApproval]: {
    sx: {
      bgcolor: "rgba(199, 56, 46, 0.4)",
      color: "rgba(3, 30, 48, 1)",
    },
    label: "Needs approval",
  },
  [ClaimStatus.TenantActionNeeded]: {
    sx: {
      bgcolor: "rgba(203, 223, 246, 1)",
      color: "rgba(3, 30, 48, 1)",
    },
    label: "Tenant move",
  },
  [ClaimStatus.PendingSettlement]: {
    sx: {
      bgcolor: "rgba(110, 130, 169, 0.2)",
      color: "rgba(3, 30, 48, 1)",
    },
    label: "Pending settlement",
  },
  [ClaimStatus.ClosedSettled]: {
    sx: {
      bgcolor: "rgba(37, 155, 36, 0.1)",
      color: "rgba(37, 155, 36, 1)",
    },
    label: "Closed - Settled",
  },
  [ClaimStatus.ClosedDenied]: {
    sx: {
      bgcolor: "rgba(233, 100, 95, 0.2)",
      color: "rgba(233, 100, 95, 1)",
    },
    label: "Closed - Denied",
  },
  [ClaimStatus.ClosedDuplicate]: {
    sx: {
      bgcolor: "rgba(233, 100, 95, 0.2)",
      color: "rgba(233, 100, 95, 1)",
    },
    label: "Closed - Duplicate",
  },
  [ClaimStatus.ClosedIncomplete]: {
    sx: {
      bgcolor: "rgba(233, 100, 95, 0.2)",
      color: "rgba(233, 100, 95, 1)",
    },
    label: "Closed - Incomplete",
  },
  [ClaimStatus.ClosedInactive]: {
    sx: {
      bgcolor: "rgba(233, 100, 95, 0.2)",
      color: "rgba(233, 100, 95, 1)",
    },
    label: "Closed - Inactive",
  },
  [ClaimStatus.ClosedByTenant]: {
    sx: {
      bgcolor: "rgba(233, 100, 95, 0.2)",
      color: "rgba(233, 100, 95, 1)",
    },
    label: "Closed - By Tenant",
  },
};

export { ClaimStatusChip };
