import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SafeLeaseButton, SafeLeaseLetterAvatar } from "@safelease/components";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import dayjs from "dayjs";
import { DocumentTags } from "./DocumentTags";
import { useClaimDocumentViewerModalStore } from "../../useClaimDocumentViewerModalStore";
import { DocumentNotes } from "./DocumentNotes.tsx";
import { ClaimsApi } from "../../../../../../../../../utils/apiInstances/ClaimsApiInstance.ts";
import { useEffect } from "react";
import { DocumentLabels } from "@safelease/service-utilities/enums";

type SelectedDocumentDetailsProps = {};

function SelectedDocumentDetails({}: SelectedDocumentDetailsProps) {
  const { selectedDocument, setSelectedDocument, updateSelectedDocument, documentListRefetch } = useClaimDocumentViewerModalStore(
    (state) => ({
      selectedDocument: state.selectedDocument,
      setSelectedDocument: state.setSelectedDocument,
      updateSelectedDocument: state.updateSelectedDocument,
      documentListRefetch: state.documentListRefetch,
    }),
  );

  const markAsRead = async (documentId: string) => {
    if (selectedDocument?.id !== documentId) return;
    const response = await ClaimsApi.updateDocumentRead(documentId);
    updateSelectedDocument({ ...selectedDocument, attachmentUserViews: response.data });
  };

  const markAsUnread = async () => {
    if (!selectedDocument) return;
    const response = await ClaimsApi.updateDocumentUnread(selectedDocument.id);
    updateSelectedDocument({ ...selectedDocument, attachmentUserViews: response.data });
  };

  const archiveDocument = async () => {
    if (!selectedDocument) return;
    const response = await ClaimsApi.archiveDocument(selectedDocument.id);
    if (response.data.deletedAt) {
      updateSelectedDocument({ ...selectedDocument, deletedAt: response.data.deletedAt, label: DocumentLabels.ARCHIVED });
      setSelectedDocument(selectedDocument);
    }
  };

  const restoreDocument = async () => {
    if (!selectedDocument) return;
    const response = await ClaimsApi.restoreDocument(selectedDocument.id);
    if (!response.data.deletedAt) {
      documentListRefetch();
      setSelectedDocument(selectedDocument);
    }
  };

  useEffect(() => {
    if (!selectedDocument) return;

    const documentId = selectedDocument.id;
    const timeoutId = setTimeout(() => {
      if (selectedDocument?.id === documentId) {
        markAsRead(documentId);
      }
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Stack paddingX={2} height="100%" direction="column" justifyContent="space-between">
      <Stack divider={<Divider />} spacing={2}>
        <Stack direction="row" sx={{ fontSize: 14, fontWeight: 500 }} justifyContent="space-between">
          <Typography>Document details</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <VisibilityOutlinedIcon sx={{ color: "rgba(92, 92, 92, 1)" }} />
            <Typography sx={{ fontSize: 12, color: "rgba(146, 146, 146, 1)" }}>
              {selectedDocument?.attachmentUserViews.length} views
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Typography sx={{ fontSize: 18, fontWeight: 500, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {selectedDocument?.fileName}
            </Typography>
            <Chip label={selectedDocument?.label} sx={{ color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(21, 39, 68, 1)" }} />
          </Stack>
          <Box>
            <SafeLeaseButton
              startIcon={<MarkEmailUnreadOutlinedIcon />}
              color="navy"
              sx={{ height: 28 }}
              variant="outlined"
              onClick={markAsUnread}
            >
              Mark as unread
            </SafeLeaseButton>
          </Box>
          <Stack direction="row" spacing={3} divider={<Divider flexItem orientation="vertical" />}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SafeLeaseLetterAvatar
                sx={{ width: 30, height: 30 }}
                name={`${selectedDocument?.createdByCognitoUserDetail?.userFirstName} ${selectedDocument?.createdByCognitoUserDetail?.userLastName}`}
              />
              <Stack>
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                  {selectedDocument?.createdByCognitoUserDetail?.userFirstName} {selectedDocument?.createdByCognitoUserDetail?.userLastName}
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 500, color: "rgba(146, 146, 146, 1)" }}>Submitted by</Typography>
              </Stack>
            </Stack>
            {selectedDocument?.createdAt && (
              <Stack>
                <Typography sx={{ fontSize: 12, fontWeight: 500 }}>{dayjs(selectedDocument.createdAt).format("MM/DD/YY")}</Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 500, color: "rgba(146, 146, 146, 1)" }}>Date submitted</Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <DocumentTags />
        <DocumentNotes />
      </Stack>
      <Stack>
        {selectedDocument?.deletedAt ? (
          <SafeLeaseButton color="navy" variant="outlined" onClick={restoreDocument}>
            Restore Document
          </SafeLeaseButton>
        ) : (
          <SafeLeaseButton color="navy" variant="outlined" onClick={archiveDocument}>
            Archive Document
          </SafeLeaseButton>
        )}
      </Stack>
    </Stack>
  );
}

export { SelectedDocumentDetails };
