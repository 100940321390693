import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { PolicySelectionModal } from "./PolicySelectionModal";
import { useState } from "react";
import { CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import { ClickableTypography } from "../../../../../../components/ClickableTypography";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { validatePolicyDatesForLossDate } from "../../../../../../utils/helpers/policyDates";
import { Close, Edit, Warning } from "@mui/icons-material";
import dayjs from "dayjs";

type PolicyDetailsProps = {};

const PolicyDetails = ({}: PolicyDetailsProps) => {
  const { claimId } = useParams();
  if (!claimId) return null;

  const [policySelectionModalOpen, setPolicySelectionModalOpen] = useState(false);
  const claim = useSelectedClaimPageStore((state) => state.claim);

  const policyDataForClaimQuery = useQuery({
    queryKey: ["policyData", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getPolicyDataForClaim(claimId);
      return response.data;
    },
  });

  const selectedPolicyCoverage = policyDataForClaimQuery.data?.selectedPolicy?.coverage;
  const isAdjusterManualPolicy = policyDataForClaimQuery.data?.selectedPolicy?.adjusterCreatedPolicy;
  const startDate = policyDataForClaimQuery.data?.selectedPolicy?.startDate;
  const cancelDate = policyDataForClaimQuery.data?.selectedPolicy?.cancelDate;
  const lossDate = claim?.lossDate;
  const unitNameHasBeenConfirmed = claim?.unit?.matchedAllSafeleaseData || claim?.unit?.adjusterManualUnitName;

  const policyDatesValidForLossDate = validatePolicyDatesForLossDate(startDate, cancelDate, lossDate);

  const tooltipTitle = !policyDataForClaimQuery.data?.selectedPolicy
    ? "No policy selected"
    : isAdjusterManualPolicy
      ? "Manually set by adjuster"
      : !policyDatesValidForLossDate
        ? `Policy dates are not valid for loss date. \n start date: ${startDate ? dayjs(startDate).format("MM/DD/YYYY") : "N/A"} \n cancel date: ${
            cancelDate ? dayjs(cancelDate).format("MM/DD/YYYY") : "N/A"
          }`
        : `start date: ${dayjs(startDate).format("MM/DD/YYYY")} cancel date: ${dayjs(cancelDate).format("MM/DD/YYYY")}`;

  if (policyDataForClaimQuery.isLoading) return <CircularProgress size={25} />;

  if (!unitNameHasBeenConfirmed) {
    // If the unit has not been matched to safelease data or manually set by adjuster, we don't want to show the policy details
    return (
      <Tooltip
        title="This unit has not been matched or manually set by the adjuster. Please match or manually set the unit before selecting a policy."
        placement="right"
      >
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography sx={{ fontSize: 14, fontWeight: 600 }}>Policy: N/A</Typography>
          <Close color="error" sx={{ fontSize: 20 }} />
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Stack direction="row" spacing={0.5}>
          <ClickableTypography sx={{ fontSize: 14, fontWeight: 600 }} onClick={() => setPolicySelectionModalOpen(true)}>
            Policy: {selectedPolicyCoverage ? formatCurrency(selectedPolicyCoverage / 100) : "N/A"}
          </ClickableTypography>
          {isAdjusterManualPolicy ? (
            <Edit color="warning" sx={{ fontSize: 20 }} />
          ) : !policyDataForClaimQuery.data?.selectedPolicy ? (
            <Close color="error" sx={{ fontSize: 20 }} />
          ) : !policyDatesValidForLossDate ? (
            <Warning color="warning" sx={{ fontSize: 20 }} />
          ) : (
            <></>
          )}
        </Stack>
      </Tooltip>
      {policySelectionModalOpen && (
        <PolicySelectionModal
          existingSelectedPolicy={policyDataForClaimQuery.data?.selectedPolicy ?? null}
          allPolicies={policyDataForClaimQuery.data?.allPolicies || []}
          setModalOpen={setPolicySelectionModalOpen}
          policyDataForClaimQuery={policyDataForClaimQuery}
        />
      )}
    </>
  );
};

export { PolicyDetails };
