import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import dayjs from "dayjs";

export type PrivatePolicyStatusGroup =
  | "flagged"
  | "accepted"
  | "rejected"
  | "expired"
  | "tenantEnrollOverride"
  | "archived"
  | "pending"
  | null;

export const getCalculatedPrivatePolicyStatus = (
  privatePolicy: SafeleasePrivatePolicy,
): {
  statusGroup: PrivatePolicyStatusGroup;
  isExpired: boolean;
} => {
  const diffInDays = dayjs(privatePolicy.expiration).diff(dayjs(), "day");

  const statusGroup = privatePolicy.archivedAt
    ? "archived"
    : diffInDays < 0
      ? "expired"
      : ["accepted"].includes(privatePolicy.validationStatus)
        ? "accepted"
        : ["rejected", "pendingRejected"].includes(privatePolicy.validationStatus)
          ? "rejected"
          : ["tenantEnrollOverride"].includes(privatePolicy.validationStatus)
            ? "tenantEnrollOverride"
            : ["humanReview"].includes(privatePolicy.validationStatus)
              ? "flagged"
              : ["pending"].includes(privatePolicy.validationStatus)
                ? "pending"
                : null;

  return {
    statusGroup,
    isExpired: diffInDays < 0,
  };
};
