import { InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { DocumentListItem } from "./DocumentListItem.tsx";
import { Document } from "@safelease/service-utilities";
import { useClaimDocumentViewerModalStore } from "../useClaimDocumentViewerModalStore.ts";
import dayjs from "dayjs";

type SearchAndSortDocumentListProps = {};

function SearchAndSortDocumentList({}: SearchAndSortDocumentListProps) {
  const { documentList, selectedDocumentLabel, selectedLineItemId, filteredDocumentList, setFilteredDocumentList } =
    useClaimDocumentViewerModalStore((state) => ({
      documentList: state.documentList,
      selectedDocumentLabel: state.selectedDocumentLabel,
      selectedLineItemId: state.selectedLineItemId,
      filteredDocumentList: state.filteredDocumentList,
      setFilteredDocumentList: state.setFilteredDocumentList,
    }));

  useEffect(() => {
    setFilteredDocumentList();
  }, [documentList, selectedDocumentLabel, selectedLineItemId, setFilteredDocumentList]);

  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState<keyof typeof sortByMap>("dateDesc");
  const [sortByAnchorEl, setSortByAnchorEl] = useState<null | HTMLElement>(null);
  const handleSortByClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortByAnchorEl(event.currentTarget);
  };
  const handleSortByClose = () => {
    setSortByAnchorEl(null);
  };

  const searchInDocument = (doc: Document) => {
    return Object.keys(doc).some((key) => {
      if (key === "s3Path" || key === "url") return false;
      const value = doc[key as keyof Document];
      if (typeof value !== "string" && typeof value !== "number") return false;
      return (
        (typeof value === "string" && value.toLowerCase().includes(search.toLowerCase())) ||
        (typeof value === "number" && value.toString().includes(search))
      );
    });
  };

  const sortByDateAsc = (a: Document, b: Document) => dayjs(b.createdAt).diff(dayjs(a.createdAt));
  const sortByDateDesc = (a: Document, b: Document) => dayjs(a.createdAt).diff(dayjs(b.createdAt));
  const sortByFileNameAsc = (a: Document, b: Document) => a.fileName.localeCompare(b.fileName);
  const sortByFileNameDesc = (a: Document, b: Document) => b.fileName.localeCompare(a.fileName);
  const sortByFileSizeAsc = (a: Document, b: Document) => a.fileSize - b.fileSize;
  const sortByFileSizeDesc = (a: Document, b: Document) => b.fileSize - a.fileSize;
  const sortByViewsAsc = (a: Document, b: Document) => a.attachmentUserViews.length - b.attachmentUserViews.length;
  const sortByViewsDesc = (a: Document, b: Document) => b.attachmentUserViews.length - a.attachmentUserViews.length;

  const sortByMap = {
    dateAsc: {
      func: sortByDateAsc,
      label: "newest to oldest",
    },
    dateDesc: {
      func: sortByDateDesc,
      label: "oldest to newest",
    },
    fileNameAsc: {
      func: sortByFileNameAsc,
      label: "file name (A-Z)",
    },
    fileNameDesc: {
      func: sortByFileNameDesc,
      label: "file name (Z-A)",
    },
    fileSizeAsc: {
      func: sortByFileSizeAsc,
      label: "file size (smallest to largest)",
    },
    fileSizeDesc: {
      func: sortByFileSizeDesc,
      label: "file size (largest to smallest)",
    },
    viewsAsc: {
      func: sortByViewsAsc,
      label: "views (least to most)",
    },
    viewsDesc: {
      func: sortByViewsDesc,
      label: "views (most to least)",
    },
  };

  return (
    <>
      <TextField
        name="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{
          border: 1,
          borderColor: "rgba(235, 239, 247, 1)",
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
          },
          "& .MuiOutlinedInput-input": {
            padding: 1,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Typography
        onClick={handleSortByClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(235, 239, 247, 0.7)",
          },
          fontSize: 14,
          fontWeight: 500,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "fit-content",
        }}
      >
        Sort by {sortByMap[sortBy].label}
        <ArrowDropDownIcon />
      </Typography>
      <Menu anchorEl={sortByAnchorEl} open={Boolean(sortByAnchorEl)} onClose={handleSortByClose}>
        {Object.keys(sortByMap).map((key) => (
          <MenuItem
            key={key}
            onClick={() => {
              setSortBy(key as keyof typeof sortByMap);
              handleSortByClose();
            }}
          >
            {sortByMap[key as keyof typeof sortByMap].label}
          </MenuItem>
        ))}
      </Menu>
      {filteredDocumentList
        .filter((document) => searchInDocument(document))
        .sort(sortByMap[sortBy].func)
        .map((document) => (
          <DocumentListItem key={document.id} document={document} />
        ))}
    </>
  );
}

export { SearchAndSortDocumentList };
