import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { SafeLeaseCard, SafeLeaseChip } from "@safelease/components";
import { useSelectedLegacyClaimData } from "./useSelectedLegacyClaimData";
import { useParams } from "react-router";
import { ClaimStatusChip } from "../../../../components/ServiceUIComponents/ClaimsService/ClaimStatusChip";
import { ClaimStatus, RelationshipProtectionType } from "@safelease/service-utilities/enums";
import dayjs from "dayjs";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import SafeLeaseError from "../../../../components/SafeLeaseError";
import { HandshakeOutlined, LocationOnOutlined, WarehouseOutlined } from "@mui/icons-material";

type HeaderCardProps = {};

function HeaderCard({}: HeaderCardProps) {
  const { legacyClaimId } = useParams<{ legacyClaimId: string }>();
  if (!legacyClaimId) return null;

  const { getLegacyClaimQuery } = useSelectedLegacyClaimData(legacyClaimId);

  if (getLegacyClaimQuery.isLoading) return <Skeleton variant="rounded" height={120} />;
  const legacyClaim = getLegacyClaimQuery.data;
  if (!legacyClaim) return <SafeLeaseError />;

  return (
    <SafeLeaseCard
      sx={{
        padding: "16px",
        overflow: "visible",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="column" spacing={0.5}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>{legacyClaim?.name}</Typography>
            <Typography sx={{ fontSize: 14 }}>{legacyClaim?.email}</Typography>
          </Stack>
          <ClaimStatusChip status={legacyClaim?.status as ClaimStatus} editable={false} />
          <Stack spacing={1.5} direction="row" alignItems={"center"} divider={<Divider orientation="vertical" flexItem />}>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{legacyClaim?.unit}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{legacyClaim?.causeOfLoss}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Date of loss {legacyClaim?.lossDate ? dayjs(legacyClaim.lossDate).format("MM/DD/YY") : "N/A"}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              Limit: {legacyClaim?.limit ? formatCurrency(legacyClaim.limit / 100) : "N/A"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ marginY: 2 }} />
      <Stack direction="row" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <LocationOnOutlined sx={{ fontSize: 22 }} />
          <Typography sx={{ fontSize: 12 }}>
            {legacyClaim.safeleaseLocationDetails.address}, {legacyClaim.safeleaseLocationDetails.city},{" "}
            {legacyClaim.safeleaseLocationDetails.state} {legacyClaim.safeleaseLocationDetails.postalCode}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <HandshakeOutlined sx={{ fontSize: 22 }} />
          <Typography sx={{ fontSize: 12 }}>{legacyClaim.safeleaseLocationDetails.relationshipName}</Typography>
          <SafeLeaseChip
            label={
              legacyClaim.safeleaseLocationDetails.relationshipProtectionType === RelationshipProtectionType.TENANT_INSURANCE
                ? "Tenant Insurance"
                : "Tenant Protection"
            }
            sx={{
              paddingY: 2,
              bgcolor: `${legacyClaim.safeleaseLocationDetails.relationshipProtectionType === RelationshipProtectionType.TENANT_INSURANCE ? "rgba(239, 184, 6, 0.3)" : "rgba(203, 223, 246, 1)"}`,
              color: "rgba(3, 30, 48, 1)",
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <WarehouseOutlined sx={{ fontSize: 22 }} />
          <Typography sx={{ fontSize: 12 }}>{legacyClaim.safeleaseLocationDetails.llcName}</Typography>
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}

export { HeaderCard };
