import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useDataChangeManagerState } from "../useDataChangeManager";
import dayjs from "dayjs";
import { AdminRequestLocation } from "@safelease/service-utilities";

interface LocationDropdownProps {
  locations: AdminRequestLocation[]; // List of all locations fetched from the server
  sx?: any;
  disabled?: boolean;
}

function LocationDropdown({ locations, sx }: LocationDropdownProps) {
  const [options, setOptions] = useState<AdminRequestLocation[]>(locations); // Options shown in the dropdown
  const { actionType, selectedLocationIds, setSelectedLocationIds, setSelectedDate } = useDataChangeManagerState();

  useEffect(() => {
    setOptions(locations); // Update options when locations prop changes
  }, [locations]);

  const handleLocationChange = (_event: React.SyntheticEvent, selectedLocations: AdminRequestLocation[] | null) => {
    if (selectedLocations?.length) {
      // Extract all the selected location IDs
      const selectedIds =
        actionType !== "offboardFacilities"
          ? [selectedLocations[0].id.toString()] // Only allow the first selected location
          : selectedLocations.map((location) => location.id.toString()); // Allow multiple locations

      // Set the selected location IDs in the state
      setSelectedLocationIds(selectedIds);

      // If actionType is 'updateLiveDate', set the liveDate of the last selected location
      const lastSelectedLocationWithLiveDate = selectedLocations.find((location) => location.liveDate && actionType === "updateLiveDate");

      if (lastSelectedLocationWithLiveDate) {
        setSelectedDate(dayjs(lastSelectedLocationWithLiveDate.liveDate));
      }
    } else {
      // If no locations are selected, reset the date and clear selected IDs
      setSelectedDate(null);
      setSelectedLocationIds([]);
    }
  };

  // Filters down options (locations) based on the location id
  const filterOptions = (
    options: AdminRequestLocation[],
    state: { inputValue: string; getOptionLabel: (option: AdminRequestLocation) => string },
  ) => {
    const filteredOptions = options.filter(
      (location) => location.id.toString().includes(state.inputValue), // Check id (as string)
    );
    return filteredOptions;
  };

  return (
    <Autocomplete
      multiple // Enable multiple selections
      options={options} // Filtered list of locations
      getOptionLabel={(option) => `${option.id} - ${option.address}`} // Display name in dropdown
      isOptionEqualToValue={(option, value) => option.id === value.id} // Ensure equality by location ID
      renderInput={(params) => <TextField {...params} sx={sx} label="Select Location" variant="outlined" />}
      noOptionsText="No locations found" // Message when no locations match search
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.id} - {option.address}
        </li>
      )}
      onChange={handleLocationChange}
      getOptionDisabled={(locationObj: AdminRequestLocation) => {
        // If actionType is not 'offboardFacilities', only allow one location to be selected.
        if (actionType !== "offboardFacilities" && selectedLocationIds.length) {
          return locationObj.id !== +selectedLocationIds[0];
        }

        return false;
      }}
      filterOptions={filterOptions}
      value={selectedLocationIds
        .map((id) => locations.find((location) => location.id === +id))
        .filter((location): location is AdminRequestLocation => location !== undefined)}
    />
  );
}

export { LocationDropdown };
