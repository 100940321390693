import { Box, Button, Checkbox, FormControlLabel, Stack, Theme, Typography } from "@mui/material";
import { PrivatePolicyFieldButton } from "./PrivatePolicyFieldButton";
import { Add, AutoAwesome, Edit, Input } from "@mui/icons-material";
import { useState } from "react";
import { ghostButtonNavy } from "../../../utils/styles/mui-overrides";
import { FieldValueSource, SectionType } from "./useComplianceCenter";
import { useFormContext } from "react-hook-form";

interface PrivatePolicyFieldSelectionSectionProps {
  userInputValue: string;
  aiExtractedValue?: string;
  categoryTitle: string;
  sectionId: SectionType;
}

/* A section of the review form that consists of multiple sources of data to select from */
/* a manual override option, and a mark invalid checkbox */
export function PrivatePolicyFieldSelectionSection({
  userInputValue,
  aiExtractedValue,
  categoryTitle,
  sectionId,
}: PrivatePolicyFieldSelectionSectionProps) {
  const { watch, register, setValue } = useFormContext();

  const [displayOverrideField, setDisplayOverrideField] = useState<boolean>(false);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
        <Typography
          variant="body1"
          sx={{
            color: (theme: Theme) => theme.palette.grey[600],
          }}
        >
          {categoryTitle}
        </Typography>
        <FormControlLabel
          label="Mark invalid"
          sx={{
            color: (theme: Theme) => theme.palette.grey[600],
          }}
          {...register(`${sectionId}.invalid`)}
          onChange={(_, checked) => {
            setValue(`${sectionId}.invalid`, Boolean(checked));
            setValue(`${sectionId}.fieldSource`, "invalid");
          }}
          checked={watch(`${sectionId}.invalid`) ?? false}
          control={<Checkbox size="small" />}
        />
      </Stack>
      <Stack direction="column" spacing={1}>
        <PrivatePolicyFieldButton
          fieldValue={userInputValue}
          fieldTitle="Tenant Input"
          fieldSource={FieldValueSource.TENANT_INPUT}
          sectionId={sectionId}
          icon={<Input />}
        />
        {aiExtractedValue && (
          <PrivatePolicyFieldButton
            fieldValue={aiExtractedValue}
            fieldTitle="AI Extracted"
            fieldSource={FieldValueSource.AI_EXTRACTED}
            sectionId={sectionId}
            icon={<AutoAwesome />}
          />
        )}
        {!displayOverrideField && (
          <Button startIcon={<Add />} sx={{ ...ghostButtonNavy, border: "none", height: 36 }} onClick={() => setDisplayOverrideField(true)}>
            Add manual entry
          </Button>
        )}
        {displayOverrideField && (
          <PrivatePolicyFieldButton
            fieldValue={watch(`${sectionId}.manualOverrideValue`)}
            fieldTitle="Manual Entry"
            fieldSource={FieldValueSource.MANUAL_OVERRIDE}
            sectionId={sectionId}
            icon={<Edit />}
            textfield
          />
        )}
      </Stack>
    </Box>
  );
}
