import { SafeLeaseDataGrid } from "@safelease/components";
import { UnitBreakdownData } from ".";
import { GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-premium";
import { categoryColors } from "./categoryColors";
import { Stack, Typography } from "@mui/material";
interface UnitBreakdownDataGridProps {
  data: UnitBreakdownData;
  setHighlightKey: (key: string) => void;
}
const labels: Record<keyof UnitBreakdownData, string> = {
  allUnits: "All Units",
  occupied: "Occupied",
  unoccupied: "Unoccupied",
  insurable: "Insurable",
  notInsurable: "Not Insurable",
  enrolledInSafeLeasePolicy: "Enrolled in SafeLease Policy",
  notEnrolled: "Not Enrolled SafeLease Policy",
  enrolledInPrivatePolicy: "Private Policies",
  eligibleForAutoProtect: "AutoProtect Eligible",
  eligibleForRollover: "Rollover Eligible",
  other: "Other",
};

export function UnitBreakdownDataGrid({ data, setHighlightKey }: UnitBreakdownDataGridProps) {
  const rows = Object.keys(data).map((key, index) => ({
    id: index, // unique ID for each row
    name: labels[key as keyof UnitBreakdownData] || key,
    value: data[key as keyof UnitBreakdownData],
    data: { key },
  }));

  return (
    <SafeLeaseDataGrid
      rows={rows}
      columns={columnDefs}
      slots={{
        toolbar: ExportToolbar,
      }}
      sx={{
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
      }}
      disableRowSelectionOnClick
      onRowClick={(params) => setHighlightKey(params.row.data.key)}
    />
  );
}

const columnDefs: Array<GridColDef> = [
  {
    field: "name",
    headerName: "Category",
    flex: 1,
    renderHeader: () => <b>Category</b>,
    renderCell: (params) => (
      <Stack direction="row" spacing={1} alignItems="center">
        <div style={{ height: 16, width: 16, borderRadius: "50rem", backgroundColor: categoryColors[params.row?.data?.key] }}>&nbsp;</div>
        <Typography variant="body2">{labels[params.value as keyof UnitBreakdownData] ?? params.value}</Typography>
      </Stack>
    ),
  },
  {
    field: "value",
    headerName: "Count",
    flex: 1,
    type: "number",
    renderHeader: () => <b>Unit Count</b>,
    renderCell: (params) => params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), // Format with commas
    valueFormatter: (params) => Number(params.value),
  },
];

// Toolbar with only export options
function ExportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport sx={{ ml: "auto" }} printOptions={{ allPages: true }} />
    </GridToolbarContainer>
  );
}
