import { SafeLeaseCard } from "@safelease/components";
import { MetricsAndActionsCard } from "./MetricsAndActionsCard";
import { Divider, Stack, Theme, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../useSelectedClaimPageStore";
import { LineItemsTable } from "./LineItemsTable";
import { AdditionalDocumentsSection } from "./AdditionalDocumentsSection/AdditionalDocumentsSection";

type BodyCardProps = {};

function BodyCard({}: BodyCardProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  return (
    <SafeLeaseCard
      sx={{
        padding: "16px",
        overflow: "visible",
      }}
    >
      <Stack spacing={2}>
        <MetricsAndActionsCard />
        <Stack sx={{ paddingY: 2 }} spacing={2}>
          <Typography sx={{ fontWeight: 700, color: "rgba(3, 30, 48, 1)", fontSize: 14 }}>How it was discovered?</Typography>
          <Typography
            sx={{
              color: (theme: Theme) => theme.palette.grey.A200,
              fontSize: 14,
            }}
          >
            {claim?.lossDescription}
          </Typography>
        </Stack>
        <Divider />
        <LineItemsTable />
        <Divider />
        <AdditionalDocumentsSection />
      </Stack>
    </SafeLeaseCard>
  );
}

export { BodyCard };
