import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import { DrawerHeader } from "../sharedComponents/DrawerHeader";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { AdHocRequestList } from "./AdHocRequestList";
import { NewRequestButtonSection } from "./NewRequestButtonSection";
import NewRequestModal from "./NewRequestModal/NewRequestModal";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useAdHocRequestsDrawerStore } from "./useAdHocRequestsDrawerStore";

type AdHocRequestsDrawerProps = {};

function AdHocRequestsDrawer({}: AdHocRequestsDrawerProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const setRefetchAdHocRequests = useAdHocRequestsDrawerStore((state) => state.setRefetchAdHocRequests);

  const adHocTenantActionsQuery = useQuery({
    queryKey: ["getAdHocTenantActions", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getAllAdHocTenantActions(claimId);
      return response.data;
    },
  });

  /**
   * This useEffect will set the refetch function for the ad-hoc requests query in the store
   * This gives us the ability to refetch the ad-hoc requests from any component
   */
  useEffect(() => {
    if (adHocTenantActionsQuery.status === "success") {
      setRefetchAdHocRequests(adHocTenantActionsQuery.refetch);
    }
  }, [adHocTenantActionsQuery.refetch, adHocTenantActionsQuery.status, setRefetchAdHocRequests]);

  if (adHocTenantActionsQuery.isError) {
    enqueueSnackbar("error fetching ad-hoc requests", { variant: "error" });
  }

  const pendingRequestsCount = adHocTenantActionsQuery.data?.filter((action) => !action.completed).length; // count of tenant actions that are not completed

  return (
    <>
      <Stack sx={{ width: 700, height: "100%", padding: "16px" }} spacing={2} divider={<Divider />}>
        <DrawerHeader title="Ad-hoc requests" subTitle={`${pendingRequestsCount} active request(s)`} />
        <Box sx={{ flex: 1, overflow: "auto" }}>
          {adHocTenantActionsQuery.isLoading ? (
            <CircularProgress />
          ) : (
            <AdHocRequestList adHocRequestList={adHocTenantActionsQuery.data || []} />
          )}
        </Box>
        <NewRequestButtonSection />
      </Stack>
      {/* modal that will open when the adjuster clicks the new request button */}
      <NewRequestModal />
    </>
  );
}

export { AdHocRequestsDrawer };
