import { rgbToHex } from "@mui/material";
import { amber, blue, deepOrange, deepPurple, green, grey, orange, red } from "@mui/material/colors";

export const categoryColors: Record<string, string> = {
  allUnits: rgbToHex(blue[400]),
  occupied: rgbToHex(green[300]),
  unoccupied: rgbToHex(grey[700]),
  insurable: rgbToHex(green[600]),
  notInsurable: rgbToHex(red[500]),
  enrolledInSafeLeasePolicy: rgbToHex(green[800]),
  notEnrolled: rgbToHex(amber[500]),
  enrolledInPrivatePolicy: rgbToHex(amber[600]),
  eligibleForAutoProtect: rgbToHex(orange[500]),
  eligibleForRollover: rgbToHex(deepOrange[500]),
  other: rgbToHex(deepPurple[800]),
};
