import { BaseActionView } from "./BaseActionView";
import { LocationDropdown } from "../LocationDropDown";
import { useDataChangeManager } from "../../useDataChangeManager";
import { Grid } from "@mui/material";
import { StyledTextField } from "../DataChangeManagerStyledComponents";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";

interface DisconnectFmsFromLocationViewProps {}

function DisconnectFmsFromLocationView({}: DisconnectFmsFromLocationViewProps) {
  const { getAllLocationsQuery, selectedLocationIds, relationshipSelectorId } = useDataChangeManager();

  const location = getAllLocationsQuery.data?.find((location) => location.id === +selectedLocationIds[0]) || null;

  return (
    <BaseActionView actionTitle="Disconnect Fms From Location">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {location && (
          <StyledTextField
            id="accountId"
            label="Account ID"
            variant="outlined"
            disabled={true}
            value={location.accountId || ""}
            InputLabelProps={{
              shrink: true, // Force the label to always shrink (stay at the top)
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {location && (
          <StyledTextField
            id="serviceId"
            label="Service ID"
            variant="outlined"
            disabled={true}
            value={location.serviceId || ""}
            InputLabelProps={{
              shrink: true, // Force the label to always shrink (stay at the top)
            }}
          />
        )}
      </Grid>
    </BaseActionView>
  );
}

export { DisconnectFmsFromLocationView };
