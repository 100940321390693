import { SafeLeaseButton } from "@safelease/components";
import safeleaseLogo from "../../../../../../../assets/safelease-logo.png";
import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { SolicitFeedbackConfirmationModal } from "./SolicitFeedbackConfirmationModal";

type SolicitFeedbackButtonProps = {
  disabled?: boolean;
};

function SolicitFeedbackButton({ disabled = false }: SolicitFeedbackButtonProps) {
  const [solicitFeedbackConfirmantionModalOpen, setSolicitFeedbackConfirmantionModalOpen] = useState(false);

  return (
    <Tooltip title={disabled ? "This tenant has already had feedback solicited." : ""}>
      <Box>
        <SafeLeaseButton
          onClick={() => setSolicitFeedbackConfirmantionModalOpen(true)}
          disabled={disabled}
          startIcon={<img src={safeleaseLogo} alt="SafeLease Logo" style={{ height: "1rem", width: "auto", objectFit: "contain" }} />}
          size="small"
          variant="outlined"
        >
          Solicit Feedback
        </SafeLeaseButton>
        {solicitFeedbackConfirmantionModalOpen && (
          <SolicitFeedbackConfirmationModal setModalOpen={setSolicitFeedbackConfirmantionModalOpen} />
        )}
      </Box>
    </Tooltip>
  );
}

export { SolicitFeedbackButton };
