import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { primaryColorButton } from "../../../utils/styles/mui-overrides";

interface JobsErrorCenterMetricCardProps {
  value: number | string;
  label: string;
  showButton?: boolean;
}

export default function JobsErrorCenterMetricCard({ value, label, showButton }: JobsErrorCenterMetricCardProps) {
  return (
    <Card elevation={0} sx={{ border: `1px solid #EBEFF7`, height: "100%" }}>
      <CardContent>
        <Typography color="textSecondary">{label}</Typography>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 600 }}>
          {value}
        </Typography>
      </CardContent>
      {showButton && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              ...primaryColorButton,
            }}
            size="small"
          >
            View
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
