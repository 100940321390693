import { Box, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { CloudSync } from "@mui/icons-material";
import { ProofOfPurchaseFile_ClaimsDbModel } from "@safelease/service-utilities";
import { ImageViewer } from "../../../../../../components/ImageViewer";

type FileDisplaySectionProps = {
  filesToDisplay: ProofOfPurchaseFile_ClaimsDbModel[];
  sectionHeader: string;
  onReUploadClick: () => void;
};

function FileDisplaySection({ filesToDisplay, sectionHeader, onReUploadClick }: FileDisplaySectionProps) {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: (theme: Theme) => theme.palette.divider,
        borderRadius: 1,
        p: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
        <Typography>{sectionHeader}</Typography>
        <SafeLeaseButton variant="contained" color="navy" startIcon={<CloudSync />} onClick={onReUploadClick}>
          Request Re-Upload
        </SafeLeaseButton>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} paddingY={1} overflow="auto" sx={{ width: "100%" }}>
        {filesToDisplay?.map((file: ProofOfPurchaseFile_ClaimsDbModel) => (
          <Box key={file.id}>
            <ImageViewer
              fileName={file.attachment!.fileName!}
              file={file.attachment?.url as string}
              fileSize={file.attachment?.fileSize ?? null}
              archived={!!file.attachment?.deletedAt}
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default FileDisplaySection;
