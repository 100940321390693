import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import SafeLeaseTextField from "../../components/SafeLeaseTextField/SafeLeaseTextField";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { SafeLeaseButton } from "@safelease/components";
import { useSignInPageStore } from "./ForgotPasswordPage/useForgotPasswordPageStore";
import { signIn } from "aws-amplify/auth";
import { useAuthStore } from "../../hooks/useAuthStore";

export type SignInFormValues = {
  email: string;
  password: string;
};

const validationSchema = z.object({
  email: z.string().min(1, "Email is required.").email(),
  password: z.string().min(1, "Password is required."), //TODO: Add more password validation
});

interface SignInFormProps {}

export function SignInForm({}: SignInFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setPage } = useSignInPageStore();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const setUserRequiresNewPassword = useAuthStore((state) => state.setUserRequiresNewPassword);

  const { handleSubmit, control, formState } = useForm<SignInFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  const onLoginButtonClicked: SubmitHandler<{
    email: string;
    password: string;
  }> = async (data: any) => {
    setIsLoading(true);
    try {
      const { nextStep } = await signIn({
        username: data.email,
        password: data.password,
      });
      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        setUserRequiresNewPassword(true);
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onLoginButtonClicked)}>
      <Stack direction="column" spacing={4}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="600" fontSize="14px">
              Email
            </Typography>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.error.main }}>
              {formState.errors.email?.message}
            </Typography>
          </Stack>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <SafeLeaseTextField
                error={!!formState.errors.email?.message}
                placeholder="user@safelease.com"
                sx={{
                  width: "100%",
                  mt: 0.5,
                }}
                InputProps={{
                  sx: {
                    "& .MuiInputBase-input": {
                      borderRadius: 2,
                      border: "1px solid transparent",
                      fontSize: 14,
                      width: "100%",
                      p: 1,
                      transition: theme.transitions.create(["border-color", "box-shadow"]),
                      //   // Use the system font instead of the default Roboto font.
                      "&:focus": {
                        backgroundColor: "white",
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                      },
                    },
                  },
                }}
                {...field}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontWeight="600" fontSize="14px">
              Password
            </Typography>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.error.main }}>
              {formState.errors.password?.message}
            </Typography>
          </Stack>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <SafeLeaseTextField
                error={!!formState.errors.password?.message}
                placeholder="************"
                sx={{
                  width: "100%",
                  mt: 0.5,
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={togglePasswordVisibility}
                      size="small"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: 5,
                        transform: "translateY(-50%)",
                      }}
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                  sx: {
                    "& .MuiInputBase-input": {
                      borderRadius: 2,
                      border: "1px solid transparent",
                      fontSize: 14,
                      width: "100%",
                      p: 1,
                      pr: 6,
                      transition: theme.transitions.create(["border-color", "box-shadow"]),
                      //   // Use the system font instead of the default Roboto font.
                      "&:focus": {
                        backgroundColor: "white",
                        borderColor: "#80bdff",
                        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
                      },
                    },
                  },
                }}
                type={isPasswordVisible ? "text" : "password"}
                {...field}
              />
            )}
          />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Typography
            onClick={() => setPage("forgotPassword")}
            style={{
              cursor: "pointer",
              color: theme.palette.text.primary,
              fontSize: theme.typography.caption.fontSize,
            }}
          >
            Forgot password?
          </Typography>
        </Stack>
        <SafeLeaseButton
          loading={isLoading}
          variant="contained"
          type="submit"
          color="primary"
          sx={{ width: "100%", fontSize: theme.typography.body2.fontSize }}
          disableElevation
        >
          Sign in
        </SafeLeaseButton>
      </Stack>
    </form>
  );
}
