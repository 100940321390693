import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { SendOfferConfirmationModal } from "./SendOfferConfirmationModal";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";

type SendOfferButtonProps = {
  disabled: boolean;
};

/**
 * This component houses the Send offer button and its corresponding confirmation modal which openes when the button is clicked
 * This button should be enabled when the current claim has adjuster values for all of the line items.
 * disabled from the current claim having any "closed" status ("Closed - Settled", "Closed - Denied", "Closed-Abandoned")
 */
function SendOfferButton({ disabled }: SendOfferButtonProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [sendOfferModalOpen, setSendOfferModalOpen] = useState<boolean>(false);
  const claim = useSelectedClaimPageStore((state) => state.claim);

  const policyDataForClaimQuery = useQuery({
    queryKey: ["policyData", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getPolicyDataForClaim(claimId);
      return response.data;
    },
  });

  // boolean value that indicates if the claim has any outstanding offers that the tenant has not responded to
  // if it does we should not allow the adjuster to send another offer until the tenant has responded to the current one
  // this will help ensure there is only ever one offer pending at a time
  const hasPendingOffer =
    claim?.settlementOffers?.length !== undefined &&
    claim.settlementOffers.length > 0 &&
    claim.settlementOffers.some((offer) => !offer.responseDate);

  const unitNameHasBeenConfirmed = claim?.unit?.matchedAllSafeleaseData || claim?.unit?.adjusterManualUnitName;

  const policyHasNotBeenSelected = !policyDataForClaimQuery.data?.selectedPolicy;

  const tooltipText = hasPendingOffer
    ? "There is already a pending settlement offer that has been sent to the tenant."
    : !unitNameHasBeenConfirmed
      ? "The unit has not been matched or manually set by the adjuster. Please match or manually set the unit before sending an offer."
      : policyHasNotBeenSelected
        ? "No policy has been selected for this claim. Please select a policy before sending an offer."
        : "";

  if (policyDataForClaimQuery.isLoading) return <CircularProgress size={25} />;

  return (
    <Tooltip title={tooltipText}>
      <Box>
        <SafeLeaseButton
          onClick={() => setSendOfferModalOpen(true)}
          disabled={disabled || hasPendingOffer || !unitNameHasBeenConfirmed || policyHasNotBeenSelected}
          size="small"
          disableElevation
          variant="contained"
        >
          Send offer
        </SafeLeaseButton>
        <SendOfferConfirmationModal setModalOpen={setSendOfferModalOpen} modalOpen={sendOfferModalOpen} />
      </Box>
    </Tooltip>
  );
}

export { SendOfferButton };
