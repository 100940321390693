import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

type StyledTextFieldForUnitSelectionModalProps = {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  startIcon?: React.ReactNode;
};

function StyledTextFieldForUnitSelectionModal({
  value,
  setValue,
  disabled = false,
  placeholder = "",
  startIcon,
}: StyledTextFieldForUnitSelectionModalProps) {
  return (
    <TextField
      value={value}
      onChange={(e) => setValue(e.target.value)}
      disabled={disabled}
      fullWidth
      placeholder={placeholder}
      size="small"
      sx={{
        "& .MuiInputBase-root": {
          color: "black", // Custom text color
          padding: "1px 12px", // custom padding making it appear less tall
          fontSize: 15,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#1E2A38", // Custom border color when focused
          },
        },
      }}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
}

export { StyledTextFieldForUnitSelectionModal };
