import { useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";

import { muiRedIconButton, muiGreenIconButton, muiFaintIconButton } from "../styles/mui-overrides";

const editableField = (
  value: string,
  // tableInfo: {row: TableRow, queryResult: QueryResult},
  tableInfo: { row: any; queryResult: any },
  renderOptions: {
    callback: (newValue: any, tableInfo: any) => Promise<any>;
    isLink?: boolean;
    nonEditingDisplayParser?: (value: string, tableInfo: { row: any; queryResult: any }) => JSX.Element;
    editingDisplayParser?: (value: string, tableInfo: { row: any; queryResult: any }) => string;
    validators?: {
      validate: (value: string) => boolean;
      errorMessage: string;
    }[];
    nonAdminsCannotEdit: boolean;
  },
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [pendingValue, setPendingValue] = useState(
    renderOptions.editingDisplayParser ? renderOptions.editingDisplayParser(value, tableInfo) : value,
  );
  const isValid = (pendingValue: string): boolean => {
    console.log({ pendingValue });
    if (renderOptions.validators) {
      return renderOptions.validators.every((validator) => validator.validate(pendingValue));
    }
    return true;
  };
  const validationErrors = (pendingValue: string): string[] => {
    return (
      renderOptions.validators?.filter((validator) => !validator.validate(pendingValue)).map((validator) => validator.errorMessage) || []
    );
  };

  if (renderOptions.nonAdminsCannotEdit) {
    return renderOptions.nonEditingDisplayParser ? renderOptions.nonEditingDisplayParser(value, tableInfo) : value;
  }

  return (
    <>
      <div className="tw-flex tw-items-center">
        {!isEditing && (
          <>
            <div>{renderOptions.nonEditingDisplayParser ? renderOptions.nonEditingDisplayParser(value, tableInfo) : value}</div>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
            >
              <EditIcon sx={muiFaintIconButton} />
            </IconButton>
          </>
        )}
        {isEditing && (
          <>
            <textarea
              autoFocus
              defaultValue={value}
              onClick={(e) => e.stopPropagation()}
              value={pendingValue}
              onChange={(e) => setPendingValue(e.target.value)}
            />
            <IconButton
              disabled={!isValid(pendingValue)}
              onClick={async (e) => {
                e.stopPropagation();
                await renderOptions.callback(pendingValue, tableInfo);
                setIsEditing(false);
              }}
            >
              <CheckCircleIcon sx={muiGreenIconButton({ isActive: isValid(pendingValue) })} />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(false);
              }}
            >
              <CloseIcon sx={muiRedIconButton} />
            </IconButton>
          </>
        )}
      </div>
      {isEditing && !isValid(pendingValue) && validationErrors(pendingValue).length > 0 && (
        <div className="tw-text-red-500">
          {validationErrors(pendingValue).map((e) => (
            <span>{e}</span>
          ))}
        </div>
      )}
    </>
  );
};

export { editableField };
