import { Box, IconButton, Stack, Theme, Typography } from "@mui/material";
import { Document } from "@safelease/service-utilities";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ImagePreview } from "../../../../../../../../components/ImageViewer/ImagePreview";
import { useClaimDocumentViewerModalStore } from "../useClaimDocumentViewerModalStore";
import prettyBytes from "pretty-bytes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance.ts";
import { DocumentLabels } from "@safelease/service-utilities/enums";
import { EditDocumentDisplayNameModal } from "./EditDocumentDisplayNameModal.tsx";

type DocumentListItemProps = {
  document: Document;
};

function DocumentListItem({ document }: DocumentListItemProps) {
  const { selectedDocument, setSelectedDocument, updateSelectedDocument, documentList, setDocumentList, documentListRefetch } =
    useClaimDocumentViewerModalStore((state) => ({
      selectedDocument: state.selectedDocument,
      setSelectedDocument: state.setSelectedDocument,
      updateSelectedDocument: state.updateSelectedDocument,
      documentList: state.documentList,
      setDocumentList: state.setDocumentList,
      documentListRefetch: state.documentListRefetch,
    }));

  const [editDocumentDisplayNameModalOpen, setEditDocumentDisplayNameModalOpen] = useState<boolean>(false);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const markAsRead = async () => {
    handleMenuClose();
    const response = await ClaimsApi.updateDocumentRead(document.id);
    if (selectedDocument?.id === document.id) {
      updateSelectedDocument({ ...selectedDocument, attachmentUserViews: response.data });
    } else {
      const updatedDocumentList = documentList.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, attachmentUserViews: response.data };
        }
        return doc;
      });
      setDocumentList(updatedDocumentList);
    }
  };

  const markAsUnread = async () => {
    handleMenuClose();
    const response = await ClaimsApi.updateDocumentUnread(document.id);
    if (selectedDocument?.id === document.id) {
      updateSelectedDocument({ ...selectedDocument, attachmentUserViews: response.data });
    } else {
      const updatedDocumentList = documentList.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, attachmentUserViews: response.data };
        }
        return doc;
      });
      setDocumentList(updatedDocumentList);
    }
  };

  const archiveDocument = async () => {
    handleMenuClose();
    const response = await ClaimsApi.archiveDocument(document.id);
    if (response.data.deletedAt) {
      if (selectedDocument?.id === document.id) {
        updateSelectedDocument({
          ...selectedDocument,
          deletedAt: response.data.deletedAt,
          label: DocumentLabels.ARCHIVED,
        });
        setSelectedDocument(selectedDocument);
      } else {
        const updatedDocumentList = documentList.map((doc) => {
          if (doc.id === document.id) {
            return { ...doc, deletedAt: response.data.deletedAt, label: DocumentLabels.ARCHIVED };
          }
          return doc;
        });
        setDocumentList(updatedDocumentList);
      }
    }
  };

  const restoreDocument = async () => {
    handleMenuClose();
    const response = await ClaimsApi.restoreDocument(document.id);
    if (!response.data.deletedAt) {
      documentListRefetch();
    }
  };

  const editDisplayName = () => {
    handleMenuClose();
    setEditDocumentDisplayNameModalOpen(true);
  };

  const updateDocumentDisplayName = (displayName: string) => {
    if (selectedDocument?.id === document.id) {
      updateSelectedDocument({ ...selectedDocument, fileName: displayName });
    } else {
      const updatedDocumentList = documentList.map((doc) => {
        if (doc.id === document.id) {
          return { ...doc, fileName: displayName };
        }
        return doc;
      });
      setDocumentList(updatedDocumentList);
    }
  };

  const isDocumentUnread = document.attachmentUserViews.filter((view) => !view.dismissedAt).length === 0;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      padding={1}
      sx={{
        border: 1,
        borderColor: "rgba(235, 239, 247, 1)",
        borderRadius: 2,
        "&:hover": {
          backgroundColor: "rgba(235, 239, 247, 0.7)",
        },
        ...(selectedDocument?.id === document.id && {
          backgroundColor: "rgba(235, 239, 247, 0.7)",
          border: 1,
          borderColor: "rgba(39, 121, 251, 1)",
        }),
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        onClick={() => setSelectedDocument(document)}
        sx={{ cursor: "pointer", flex: 1 }}
      >
        <ImagePreview
          file={document.url}
          fileName={document.fileName}
          fileSize={document.fileSize}
          archived={!!document.deletedAt}
          hideOverlay
          width={40}
          height={40}
        />
        <Stack>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: selectedDocument ? 170 : 530,
            }}
          >
            {document.fileName}
          </Typography>
          <Typography sx={{ fontSize: 12, fontWeight: 500, color: (theme: Theme) => theme.palette.grey.A200 }}>
            Size: {prettyBytes(document.fileSize ?? 0)}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        {isDocumentUnread && <Box component="span" sx={{ backgroundColor: "primary.main", width: 8, height: 8, borderRadius: "50%" }} />}
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={isDocumentUnread ? markAsRead : markAsUnread}>Mark as {!isDocumentUnread && "un"}read</MenuItem>
          <MenuItem onClick={document.deletedAt ? restoreDocument : archiveDocument}>{document.deletedAt ? "Restore" : "Archive"}</MenuItem>
          <MenuItem onClick={editDisplayName}>Edit display name</MenuItem>
        </Menu>
      </Stack>
      {editDocumentDisplayNameModalOpen && (
        <EditDocumentDisplayNameModal
          document={document}
          setOpen={setEditDocumentDisplayNameModalOpen}
          setDocumentDisplayName={updateDocumentDisplayName}
        />
      )}
    </Stack>
  );
}

export { DocumentListItem };
