import { Box, Stack } from "@mui/material";
import { GooglePlacesAutocomplete, PlaceType } from "../../../../../../../../components/GooglePlacesAutoComplete";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { SafeleaseLocationMatchData } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { AxiosResponse } from "axios";

type AddressInputProps = {
  onSearchButtonClickCallback: (
    apiCall: Promise<AxiosResponse<SafeleaseLocationMatchData, any>>,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
};

function AddressInput({ onSearchButtonClickCallback }: AddressInputProps) {
  const [facilityLocationUserInput, setFacilityLocationUserInput] = useState<PlaceType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearchButtonClick = async () => {
    if (!facilityLocationUserInput) return; // have this explicit if to suppress TS error, should always be true because we disable the button that calls this function if it's not
    await onSearchButtonClickCallback(ClaimsApi.getSafeleaseLocationForGooglePlaceId(facilityLocationUserInput.place_id), setIsLoading);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Box sx={{ width: "100%" }}>
        <GooglePlacesAutocomplete
          placeholder="Enter facility address"
          textFieldSx={{
            "& .MuiInputBase-root": {
              color: "black", // Custom text color
              padding: "1px 12px", // custom padding making it appear less tall
              fontSize: 15,
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#1E2A38", // Custom border color when focused
              },
            },
          }}
          value={facilityLocationUserInput}
          setValue={setFacilityLocationUserInput}
        />
      </Box>
      <SafeLeaseButton
        loading={isLoading}
        disabled={!facilityLocationUserInput}
        onClick={onSearchButtonClick}
        color="navy"
        variant="contained"
      >
        Search
      </SafeLeaseButton>
    </Stack>
  );
}

export { AddressInput };
