import { ExpandMore } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { EmailFacilityDropdownValues, generateMailToLinkForEmailToFacility } from "./helperFunctions";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { useAuthStore } from "../../../../../../hooks/useAuthStore";
import dayjs from "dayjs";

type EmailFacilityDropdownProps = {};

function EmailFacilityDropdown({}: EmailFacilityDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const currentUserAttributes = useAuthStore((state) => state.userAttributes);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value: EmailFacilityDropdownValues) => {
    const tenantName =
      claim?.claimantNameOverride ?? `${claim?.unit?.cognitoUserDetail?.userFirstName} ${claim?.unit?.cognitoUserDetail?.userLastName}`;
    const unitNumber = claim?.unit?.name ?? "";
    const facilityName = claim?.unit?.safeleaseLocationDetail?.locationName ?? "";
    const facilityAddress = `${claim?.unit?.safeleaseLocationDetail?.address}, ${claim?.unit?.safeleaseLocationDetail?.city}, ${claim?.unit?.safeleaseLocationDetail?.state} ${claim?.unit?.safeleaseLocationDetail?.postalCode}`;
    const causeOfLoss = claim?.claimType?.name ?? "";
    const dateOfLoss = claim?.lossDate ? dayjs(claim.lossDate).format("MM/DD/YYYY") : "";
    const mailToLink = generateMailToLinkForEmailToFacility(value, {
      tenantName,
      adjusterName: `${currentUserAttributes?.given_name} ${currentUserAttributes?.family_name}`,
      unitNumber,
      facilityName,
      facilityAddress,
      causeOfLoss,
      dateOfLoss,
    });
    window.open(mailToLink, "_blank");
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropdownOptions = [
    { label: "Burglary claim information email", value: EmailFacilityDropdownValues.BURGLARY },
    { label: "Burglary follow up email", value: EmailFacilityDropdownValues.BURGLARY_FOLLOW_UP },
    { label: "Water damage claim information email", value: EmailFacilityDropdownValues.WATER_DAMAGE },
    { label: "Generic blank ad hoc email", value: EmailFacilityDropdownValues.GENERIC_BLANK_AD_HOC },
  ];

  return (
    <>
      <SafeLeaseButton color="navy" onClick={handleButtonClick} endIcon={<ExpandMore />} variant="outlined" size="small">
        Email Facility
      </SafeLeaseButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {dropdownOptions.map((option) => (
          <MenuItem key={option.value} onClick={() => handleMenuItemClick(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export { EmailFacilityDropdown };
