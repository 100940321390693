import { Stack, Tooltip } from "@mui/material";
import { useUnitSelectionModalStore } from "./UnitSelectionModal/useUnitSelectionModalStore";
import { ClickableTypography } from "../../../../../../components/ClickableTypography";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { Check, Close, Edit } from "@mui/icons-material";
import { UnitSelectionModal } from "./UnitSelectionModal";

type UnitDetailsProps = {};

function UnitDetails({}: UnitDetailsProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const { unitSelectionModalOpen, setUnitSelectionModalOpen } = useUnitSelectionModalStore((state) => ({
    unitSelectionModalOpen: state.unitSelectionModalOpen,
    setUnitSelectionModalOpen: state.setUnitSelectionModalOpen,
  }));
  return (
    <Stack direction="row" spacing={0.5}>
      <ClickableTypography onClick={() => setUnitSelectionModalOpen(true)} sx={{ fontSize: 14, fontWeight: 600 }}>
        {claim?.unit?.name}
      </ClickableTypography>
      <Tooltip
        title={
          claim?.unit?.matchedAllSafeleaseData
            ? "matched safelease data"
            : claim?.unit?.adjusterManualUnitName
              ? "Manually set by adjuster"
              : "Not matched to safelease data"
        }
      >
        {claim?.unit?.matchedAllSafeleaseData ? (
          <Check color="success" sx={{ fontSize: 20 }} />
        ) : claim?.unit?.adjusterManualUnitName ? (
          <Edit color="warning" sx={{ fontSize: 20 }} />
        ) : (
          <Close color="error" sx={{ fontSize: 20 }} />
        )}
      </Tooltip>
      {/* Modal for adjusters to manually lookup and match the tenant to their unit whenever we were unsuccessful in matching during the claim creation */}
      {unitSelectionModalOpen && <UnitSelectionModal />}
    </Stack>
  );
}

export { UnitDetails };
