import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { ControlPanel } from "../../../../../../../../components/ImageViewer/ControlPanel";
import { SafeLeaseCard } from "@safelease/components";
import { useClaimDocumentViewerModalStore } from "../useClaimDocumentViewerModalStore.ts";
import { EditDocumentDisplayNameModal } from "./EditDocumentDisplayNameModal.tsx";

type SelectedDocumentDisplayProps = {
  file: string;
  fileName: string;
};

function SelectedDocumentDisplay({ file, fileName }: SelectedDocumentDisplayProps) {
  const { selectedDocument, updateSelectedDocument } = useClaimDocumentViewerModalStore((state) => ({
    selectedDocument: state.selectedDocument,
    updateSelectedDocument: state.updateSelectedDocument,
  }));

  const [editDocumentDisplayNameModalOpen, setEditDocumentDisplayNameModalOpen] = useState<boolean>(false);

  const fileExtension = file?.split(".")?.pop()?.split("?")[0] ?? "";
  const isPDF = fileExtension.toLowerCase() === "pdf";

  const isVideo = ["mp4", "mov", "webm", "ogv"].includes(fileExtension.toLowerCase());
  const videoMimeTypes = {
    mp4: "video/mp4",
    mov: "video/mp4",
    webm: "video/webm",
    ogv: "video/ogg",
  };
  const videoMimeType = videoMimeTypes[fileExtension.toLowerCase() as keyof typeof videoMimeTypes];

  // When a document loads successfully, set the number of pages and stop loading
  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  // When a document fails to load, stop loading
  const onDocumentLoadFailure = () => {
    setIsLoading(false);
    setLoadFailed(true);
  };

  const updateDocumentDisplayName = (displayName: string) => {
    if (!selectedDocument) return;
    updateSelectedDocument({ ...selectedDocument, fileName: displayName });
  };

  const [scale, setScale] = useState<number>(1.0);
  const [rotation, setRotation] = useState<number>(0);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(isPDF);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);
  return (
    <Box
      sx={{
        px: 2,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography sx={{ fontWeight: 500 }}>{fileName}</Typography>
        <IconButton onClick={() => setEditDocumentDisplayNameModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </Box>
      <ControlPanel
        scale={scale}
        setScale={setScale}
        rotation={rotation}
        setRotation={setRotation}
        numPages={numPages ?? 1}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        file={file}
      />
      <SafeLeaseCard
        sx={{
          overflow: "auto",
          flex: 1,
          width: "100%",
          display: "flex",
        }}
      >
        {/* Loading state */}
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}

        {/* Display non-PDF */}
        {file && !isPDF && (
          <Box sx={{ flex: 1, overflow: "auto" }}>
            <img
              src={file}
              style={{
                rotate: `${rotation}deg`,
                scale: `${scale}`,
                height: "99%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        )}

        {/* Display video */}
        {file && isVideo && (
          <video
            key={file}
            style={{
              height: "100%",
              width: "100%",
            }}
            controls
            muted
          >
            <source src={file} type={videoMimeType} />
            Your browser does not support showing the video.
          </video>
        )}

        {/* Display PDF */}
        {file && isPDF && !loadFailed && (
          <Box
            sx={{
              transform: `rotate(${rotation}deg)`,
              display: "flex",
              justifyContent: "center",
              flex: 1,
              overflow: "auto",
            }}
          >
            <Document file={file} onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)} onLoadError={onDocumentLoadFailure}>
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          </Box>
        )}
      </SafeLeaseCard>
      {selectedDocument && editDocumentDisplayNameModalOpen && (
        <EditDocumentDisplayNameModal
          document={selectedDocument}
          setOpen={setEditDocumentDisplayNameModalOpen}
          setDocumentDisplayName={updateDocumentDisplayName}
        />
      )}
    </Box>
  );
}

export { SelectedDocumentDisplay };
