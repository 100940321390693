import { Box, Card, CircularProgress, Modal, Stack, TextField, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useParams } from "react-router";
import { useState } from "react";

type RequestUploadModalProps = {
  initialRequestMessage: string;
  onSendButtonClick: (requestMessage: string) => Promise<void>;
  setOpen: (open: boolean) => void;
  requestPending: boolean;
};

function RequestUploadModal({ initialRequestMessage, onSendButtonClick, setOpen, requestPending }: RequestUploadModalProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const [requestMessage, setRequestMessage] = useState(initialRequestMessage);

  return (
    <Modal
      open={true}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Stack padding={2} width={500} spacing={2}>
          {requestPending ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Additional documentation request</Typography>
              <TextField value={requestMessage} onChange={(e) => setRequestMessage(e.target.value)} multiline rows={10} />
              <Stack direction="row" spacing={2}>
                <SafeLeaseButton onClick={() => setOpen(false)} color="navy" sx={{ flex: 1 }} variant="outlined">
                  Cancel
                </SafeLeaseButton>
                <SafeLeaseButton onClick={() => onSendButtonClick(requestMessage)} sx={{ flex: 1 }} variant="contained">
                  Send
                </SafeLeaseButton>
              </Stack>
            </>
          )}
        </Stack>
      </Card>
    </Modal>
  );
}

export { RequestUploadModal };
