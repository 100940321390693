import { Box, styled } from "@mui/material";
import { SafeLeaseAppBar } from "./SafeLeaseAppBar";
import { NotificationDrawer } from "./NotificationDrawer";
import { NavigationDrawer } from "./NavigationDrawer";
import { useAppLayout } from "../hooks/useAppLayout";
import { Outlet } from "react-router";

const navigationDrawerWidth = 300;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "isNavigationDrawerOpen",
})<{
  open?: boolean;
  isNavigationDrawerOpen?: boolean;
}>(({ theme, isNavigationDrawerOpen }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  height: `calc(100vh - 65px)`, // 65px is the height of the AppBar and theme.spacing(2) is the padding for the top and bottom
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isNavigationDrawerOpen ? navigationDrawerWidth : 0,
  ...(isNavigationDrawerOpen && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  backgroundColor: "rgba(39, 121, 245, 0.03)", // Background color inside the app
  /**
   * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
   * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
   * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
   * proper interaction with the underlying content.
   */
  position: "relative",
}));

export function AppLayout() {
  const { isNavigationDrawerOpen, toggleNavigationDrawer, isNotificationsDrawerOpen, toggleNotificationsDrawer } = useAppLayout();

  return (
    <Box sx={{ width: "100vw" }}>
      <SafeLeaseAppBar />
      <NavigationDrawer open={isNavigationDrawerOpen} handleClose={toggleNavigationDrawer} />
      <Main isNavigationDrawerOpen={isNavigationDrawerOpen}>
        <Outlet />
      </Main>
      <NotificationDrawer open={isNotificationsDrawerOpen} handleClose={toggleNotificationsDrawer} />
    </Box>
  );
}
