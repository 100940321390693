import { useEffect } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseActionView } from "./BaseActionView";
import { useDataChangeManager } from "../../useDataChangeManager";
import { UserRoles } from "../config";

interface UpdateUserRoleViewProps {}

function UpdateUserRoleView({}: UpdateUserRoleViewProps) {
  const { getAllCoreUsersQuery, userSelectorId, setUserSelectorId, userSelectorRole, setUserSelectorRole } = useDataChangeManager();

  useEffect(() => {
    getAllCoreUsersQuery.refetch();
  }, []);

  const handleUserSelectorChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setUserSelectorId(value);
  };

  const handleRoleSelectorChange = (e: SelectChangeEvent) => {
    const userRole = e.target.value as UserRoles;
    setUserSelectorRole(userRole);
  };

  const uppercaseFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1);
  };

  return (
    <BaseActionView actionTitle="Update User Role">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl variant="outlined">
          <InputLabel id="user-selector-label">Select User</InputLabel>
          <Select
            label="Select User"
            labelId="user-selector-label"
            value={userSelectorId}
            onChange={handleUserSelectorChange}
            sx={{ minWidth: "250px", width: "50vw" }}
          >
            {getAllCoreUsersQuery.data?.edges?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl variant="outlined">
          <InputLabel id="user-role-selector-label">Select User Role</InputLabel>
          <Select
            label="Select User Role"
            labelId="user-role-selector-label"
            value={userSelectorRole}
            onChange={handleRoleSelectorChange}
            sx={{ minWidth: "250px", width: "50vw" }}
            disabled={!userSelectorId}
          >
            {Object.values(UserRoles).map((role: UserRoles) => (
              <MenuItem key={role} value={role}>
                {uppercaseFirstLetter(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </BaseActionView>
  );
}

export { UpdateUserRoleView };
