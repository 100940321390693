import { Avatar, AvatarProps, Box, BoxProps, ButtonBase, Stack, StackProps, Theme, Typography } from "@mui/material";
import { forwardRef } from "react";
import { formatDateAsTimeAgo } from "../../utils/helpers/formatDateAsTimeAgo";
import { Dayjs } from "dayjs";
import { createSlots } from "../../utils/helpers/createSlots";
import { useNavigate } from "react-router";

/* Base notification component */
export const NotificationBase = forwardRef<HTMLDivElement, BoxProps & { redirectLink: string }>(
  ({ sx, style, children, redirectLink, ...props }, ref) => {
    const navigate = useNavigate();

    const slots = createSlots(children, {
      NotificationAvatar,
      NotificationHeader,
      NotificationContent,
    });

    return (
      <ButtonBase>
        <Box
          ref={ref}
          sx={{
            p: 2,
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.divider,
            width: "100%",
            "&:hover": {
              bgcolor: (theme) => theme.palette.grey[100],
            },
            ...sx,
          }}
          style={{ ...style }}
          onClick={() => (redirectLink ? navigate(redirectLink) : null)}
          {...props}
        >
          <Stack direction="row" spacing={2}>
            {!!slots.NotificationAvatar && slots.NotificationAvatar}
            <Stack direction="column" spacing={2} sx={{ flexGrow: 1 }}>
              {!!slots.NotificationHeader && slots.NotificationHeader}
              {!!slots.NotificationContent && slots.NotificationContent}
            </Stack>
          </Stack>
        </Box>
      </ButtonBase>
    );
  },
);
NotificationBase.displayName = "Notification";

/* Notification header containing title, description, and an avatar/icon */
type NotificationHeaderProps = StackProps & {
  title: any;
  timestamp: Date | Dayjs;
};
export const NotificationHeader = forwardRef<HTMLDivElement, NotificationHeaderProps>(({ title, timestamp }, ref) => (
  <Stack direction="column" alignItems="flex-start" ref={ref}>
    <Typography>{title}</Typography>
    <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>
      {formatDateAsTimeAgo(timestamp)}
    </Typography>
  </Stack>
));
NotificationHeader.displayName = "NotificationHeader";

/* Body of the notification, can contain just about anything */
export const NotificationContent = forwardRef<HTMLDivElement, BoxProps>(({ sx: _sx, ...props }, ref) => (
  <Box
    ref={ref}
    sx={{
      p: 2,
      border: "1px solid",
      borderColor: (theme: Theme) => theme.palette.divider,
      borderRadius: 1,
      flexGrow: 1,
    }}
    {...props}
  />
));
NotificationContent.displayName = "NotificationContent";

export const NotificationAvatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => <Avatar {...props} ref={ref} />);
NotificationAvatar.displayName = "NotificationAvatar";

// export const NotificationFooter = forwardRef<
//   HTMLDivElement,
//   React.HTMLAttributes<HTMLDivElement>
// >(({ className, ...props }, ref) => (
//   <div
//     ref={ref}
//     className={cn("flex items-center p-6 pt-0", className)}
//     {...props}
//   />
// ));
// CardFooter.displayName = "CardFooter";
