import { Box, Card, Checkbox, CircularProgress, FormControlLabel, Modal, Stack, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { FileUploadDropzone } from "../../../../../../components/FileUploadDropzone";
import axios from "axios";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";

type UploadAdditionalDocumentsModalProps = {
  setUploadFileModalOpen: (open: boolean) => void;
};

function UploadAdditionalDocumentsModal({ setUploadFileModalOpen }: UploadAdditionalDocumentsModalProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  // We need to refetch the claim that is being used to display data outside of the Modal so that the newly uploaded files are displayed
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const [submissionInProgress, setSubmissionInProgress] = useState<boolean>(false);
  const [createEmailedFacilityNote, setCreateEmailedFacilityNote] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<{ file: File; previewUrl: string }[]>([]);

  const handleNewFiles = (files: File[]) => {
    setUploadedFiles((currentFiles) => {
      const allFiles = [...currentFiles];
      for (const file of files) {
        allFiles.push({
          file,
          previewUrl: URL.createObjectURL(file),
        });
      }
      return allFiles;
    });
  };

  const onSubmit = async () => {
    setSubmissionInProgress(true);
    try {
      for (const file of uploadedFiles) {
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.file.name, file.file.type);
        const { attachment, presignedUrl } = response.data;
        await axios.put(presignedUrl, file.file, {
          headers: {
            "Content-Type": file.file.type,
          },
        });
        await ClaimsApi.createAdditionalDocument({
          attachmentId: attachment.id,
          claimId,
          createEmailedFacilityNote,
        });
      }
      await refetchClaim();
      setSubmissionInProgress(false);
      enqueueSnackbar("Files uploaded successfully", {
        variant: "success",
      });
      if (createEmailedFacilityNote) {
        enqueueSnackbar("Emailed facility note created successfully", {
          variant: "success",
        });
      }
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error uploading files, please try again.", {
        variant: "error",
      });
      setSubmissionInProgress(false);
    }
  };

  const handleClose = () => {
    // This is to avoid memory leaks when we set the uploadedFiles to an empty array
    for (const file of uploadedFiles) {
      if (file.previewUrl) {
        URL.revokeObjectURL(file.previewUrl);
      }
    }
    setUploadedFiles([]);
    setUploadFileModalOpen(false);
  };

  return (
    <Modal
      onClose={handleClose}
      open={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxWidth: "70%",
          minWidth: "500px",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Stack padding={3} spacing={2}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              color: (theme: Theme) => theme.palette.grey.A200,
            }}
          >
            Upload additional documents
          </Typography>
          {submissionInProgress ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Stack>
              <FileUploadDropzone handleNewFiles={handleNewFiles} uploadedFiles={uploadedFiles} onSubmit={onSubmit} />
              <FormControlLabel
                control={<Checkbox checked={createEmailedFacilityNote} onChange={(e) => setCreateEmailedFacilityNote(e.target.checked)} />}
                label="Create emailed facility note"
              />
            </Stack>
          )}
        </Stack>
      </Card>
    </Modal>
  );
}

export { UploadAdditionalDocumentsModal };
