import { Box, CircularProgress } from "@mui/material";
import { SafeLeaseButton, SafeLeaseMenu, SafeLeaseMenuItem } from "@safelease/components";
import { useState } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { enqueueSnackbar } from "notistack";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useTenantCommunicationDrawerStore } from "../../DetailsDrawer/TenantCommunicationDrawer/useTenantCommunicationDrawerStore";
import { RequestUploadModal } from "./RequestUploadModal";
import { InitialRequestMessageMetadata, getInitialRequestMessageForTenantActionType } from "./helpers";
import { useClaimSpecificDocumentSelectorMenuItems } from "../../../../../../hooks/claims/useClaimSpecificDocumentSelectorMenuItems";
import { useParams } from "react-router";

export interface AdditionalMetadata {
  typeSpecificAttachmentDefId?: string;
  lineItemId?: string;
}

type RequestDocumentationButtonProps = {};

function RequestDocumentationButton({}: RequestDocumentationButtonProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [requestUploadModalOpen, setRequestUploadModalOpen] = useState(false);
  const [tenantActionType, setTenantActionType] = useState<TenantActionTypes | null>(null);
  const [initialRequestMessage, setInitialRequestMessage] = useState<string>("");
  const [additionalMetadata, setAdditionalMetadata] = useState<AdditionalMetadata>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [requestPending, setRequestPending] = useState(false);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const refetchMessages = useTenantCommunicationDrawerStore((state) => state.refetchMessages);

  const { documentSelectorMenuItems, loading } = useClaimSpecificDocumentSelectorMenuItems(claimId);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // set the correct state depending on which button was clicked to pre populate the request message and open the request upload modal
  const handleItemClick = (params: {
    type: TenantActionTypes;
    additionalMetadata?: AdditionalMetadata;
    initialRequestMessageMetadata?: InitialRequestMessageMetadata;
  }) => {
    setTenantActionType(params.type);
    setInitialRequestMessage(getInitialRequestMessageForTenantActionType(params.type, params.initialRequestMessageMetadata));
    setAdditionalMetadata(params.additionalMetadata ?? {}); // billing ledger and plan addendum don't have additional metadata
    setRequestUploadModalOpen(true);
    handleClose();
  };

  // function to run when the send button is clicked in the request upload modal
  const onSendButtonClick = async (requestMessage: string) => {
    if (!tenantActionType) return;
    setRequestPending(true);
    const metadata = {
      [tenantActionType]: {
        requestMessage,
        ...additionalMetadata,
      },
    };
    try {
      //Create the Tenant Action for the given type based on which button was clicked
      const response = await ClaimsApi.createTenantAction({
        type: tenantActionType,
        claimId: claimId,
        metadata,
      });
      const tenantActionId = response.data.id;

      //Create the claim message for the adjuster to request the document
      await ClaimsApi.createClaimMessage({
        claimId: claimId,
        tenantActionId,
      });

      // refresh the list of claim messages to show the new message
      refetchMessages();
      // refetch the claim becuase the status of the claim can change if a claim goes from having 0 Tenant Actions to having 1 or more
      // this will update the UI to show the new status of the claim
      refetchClaim();
    } catch (error) {
      enqueueSnackbar("Error requesting document, please try again.", {
        variant: "error",
      });
    } finally {
      setRequestPending(false);
      setRequestUploadModalOpen(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <SafeLeaseButton onClick={handleClick} startIcon={<AddBoxOutlinedIcon />} variant="text" color="navy">
        Request document
      </SafeLeaseButton>
      <SafeLeaseMenu placement="top-start" open={Boolean(anchorEl)} anchorEl={anchorEl} handleClose={handleClose} sx={{ zIndex: 1000 }}>
        <SafeLeaseMenuItem
          label="Plan addendum"
          onClick={() => handleItemClick({ type: TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM })}
        />
        <SafeLeaseMenuItem label="Billing ledger" onClick={() => handleItemClick({ type: TenantActionTypes.UPLOAD_BILLING_LEDGER })} />
        {documentSelectorMenuItems?.typeSpecificAttachmentDefs?.map((def) => (
          <SafeLeaseMenuItem
            label={def.name}
            key={def.id}
            onClick={() =>
              handleItemClick({
                type: TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT,
                additionalMetadata: { typeSpecificAttachmentDefId: def.id },
                initialRequestMessageMetadata: {
                  [TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]: {
                    label: def.label,
                    description: def.description,
                  },
                },
              })
            }
          />
        ))}
        <SafeLeaseMenuItem label="Receipts">
          {documentSelectorMenuItems?.lineItems?.map((lineItem) => (
            <SafeLeaseMenuItem
              label={`${lineItem.name}`}
              key={lineItem.id}
              onClick={() =>
                handleItemClick({ type: TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES, additionalMetadata: { lineItemId: lineItem.id } })
              }
            />
          ))}
        </SafeLeaseMenuItem>
        <SafeLeaseMenuItem label="Proof of ownership">
          {documentSelectorMenuItems?.lineItems?.map((lineItem) => (
            <SafeLeaseMenuItem
              label={`${lineItem.name}`}
              key={lineItem.id}
              onClick={() =>
                handleItemClick({
                  type: TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES,
                  additionalMetadata: { lineItemId: lineItem.id },
                })
              }
            />
          ))}
        </SafeLeaseMenuItem>
      </SafeLeaseMenu>
      {requestUploadModalOpen && (
        <RequestUploadModal
          setOpen={setRequestUploadModalOpen}
          initialRequestMessage={initialRequestMessage}
          onSendButtonClick={onSendButtonClick}
          requestPending={requestPending}
        />
      )}
    </Box>
  );
}

export { RequestDocumentationButton };
