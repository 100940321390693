import { Box, Card, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Document } from "@safelease/service-utilities";
import CloseIcon from "@mui/icons-material/Close";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance.ts";

type EditDocumentDisplayNameModalProps = {
  document: Document;
  setOpen: (open: boolean) => void;
  setDocumentDisplayName: (displayName: string) => void;
};

function EditDocumentDisplayNameModal({ document, setOpen, setDocumentDisplayName }: EditDocumentDisplayNameModalProps) {
  const [text, setText] = useState<string>(document.fileName);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!text) return;

    setIsLoading(true);
    const response = await ClaimsApi.updateDocumentFileName(document.id, { fileName: text });
    setDocumentDisplayName(response.data.fileName);
    setIsLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={true}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ padding: 2, width: 350, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          <Stack direction="row" justifyContent="space-between" paddingBottom={1}>
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Edit Display Name</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon sx={{ color: "black" }} />
            </IconButton>
          </Stack>
          <Stack spacing={1}>
            <TextField
              value={text}
              onChange={(e) => setText(e.target.value.slice(0, 255))}
              inputProps={{ maxLength: 255 }}
              helperText={`${text.length}/255 characters`}
              fullWidth
            />
            <SafeLeaseButton loading={isLoading} variant="contained" onClick={handleSubmit} sx={{ width: "100%" }}>
              Submit
            </SafeLeaseButton>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
}

export { EditDocumentDisplayNameModal };
