import { create } from "zustand";

type NewRequestModalStore = {
  newRequestModalOpen: boolean;
  setNewRequestModalOpen: (open: boolean) => void;
};

export const useNewRequestModalStore = create<NewRequestModalStore>()((set) => ({
  newRequestModalOpen: false,
  setNewRequestModalOpen: (open) => {
    set({ newRequestModalOpen: open });
  },
}));
