import { GridColDef } from "@mui/x-data-grid-premium";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { TenantInsurancePayment } from "@safelease/service-utilities";

export const columnDefs: GridColDef<Partial<TenantInsurancePayment>>[] = [
  {
    field: "unitName",
    headerName: "Unit",
    flex: 1,
  },
  {
    field: "tenantName",
    headerName: "Tenant",
    flex: 1,
  },
  {
    field: "collected",
    headerName: "Collected",
    flex: 1,
    valueFormatter: ({ value }) => (value ? formatCurrency(value / 100) : "N/A"),
  },
  {
    field: "date",
    headerName: "Payment Date",
    flex: 1,
  },
  {
    field: "coverage",
    headerName: "Coverage",
    flex: 1,
    valueFormatter: ({ value }) => (value ? formatCurrency(value / 100) : "N/A"),
  },
  {
    field: "paidThrough",
    headerName: "Paid through",
    flex: 1,
  },
];
