import { Stack } from "@mui/material";
import { NotificationGenerator } from "../SafeLeaseNotifications/NotificationGenerator";

interface NotificationListProps {
  notifications: SLNotification[];
}

export function NotificationList({ notifications }: NotificationListProps) {
  return (
    <Stack direction="column">
      {notifications.map((notification) => (
        <NotificationGenerator notification={notification} />
      ))}
    </Stack>
  );
}
