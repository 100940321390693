import { Box, Divider, Drawer, DrawerProps, Grid, IconButton, Typography } from "@mui/material";
import { NotificationList } from "./NotificationList";
import { KeyboardArrowRight } from "@mui/icons-material";
import { useAppLayout } from "../../hooks/useAppLayout";

interface NotificationsDrawerProps extends DrawerProps {
  open: boolean;
  handleClose: () => void;
}

export function NotificationDrawer({ open, handleClose }: NotificationsDrawerProps) {
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleClose}
      hideBackdrop
      PaperProps={{
        sx: {
          borderLeft: "1px solid",
          borderColor: (theme) => theme.palette.divider,
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
        },
      }}
    >
      <NotificationDrawerContent />
    </Drawer>
  );
}

export function NotificationDrawerContent() {
  // TODO: implement
  // const notifications = useNotifications();

  const { toggleNotificationsDrawer } = useAppLayout();

  return (
    <Box sx={{ width: 440 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="h6" fontWeight="bold" sx={{ flexGrow: 1 }}>
              Notifications
            </Typography>
            <IconButton onClick={toggleNotificationsDrawer}>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {/* <SafeLeaseTabs /> */}
      </Grid>
      <Grid item xs={12}>
        <NotificationList notifications={[]} />
      </Grid>
    </Box>
  );
}
