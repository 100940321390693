// UI - libs
import { Replay as ReplayIcon, Check as CheckIcon, Warning as WarningIcon } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";

// Data
import { JobErrorCenterApi } from "../../../utils/apiInstances/JobErrorCenterApiInstance";
import { NavigateFunction } from "react-router";
import { JobError } from "@safelease/service-utilities/types/Jobs/JobResponses";

interface JobErrorActionsParams {
  jobId: string;
  jobError: JobError;
  fetchJobErrors: () => void;
  navigate: NavigateFunction;
}

export default function getJobErrorActions({ jobId, jobError, navigate, fetchJobErrors }: JobErrorActionsParams) {
  const handleRetry = () => {
    navigate(`/error_center/retry/${jobId}`);
  };

  const markAsComplete = async () => {
    if (!window.confirm("Are you sure you want to mark this job as complete?")) return;
    await JobErrorCenterApi.completeJob(jobId);
    await fetchJobErrors();
  };

  const handleOverrideSafetyMechanism = async () => {
    if (!window.confirm(`Are you sure you want to rerun this job and override the safety mechanism?\n${jobError.errorNotes}`)) return;
    await JobErrorCenterApi.overrideSafeties(jobId);
    await fetchJobErrors();
  };

  const menuItems = [];

  // Always display retry job
  menuItems.push(<GridActionsCellItem showInMenu icon={<ReplayIcon />} label="Retry Job" onClick={handleRetry} />);

  if (jobError.method === "setup") {
    menuItems.push(
      <GridActionsCellItem
        showInMenu
        icon={<CheckIcon />}
        label="Mark as Complete"
        onClick={markAsComplete}
        sx={{
          color: "darkorange",
          "&:hover": { backgroundColor: "darkorange", color: "white" },
        }}
      />,
    );
  }

  if (jobError.method && ["autoEnroll", "rollover"].includes(jobError.method)) {
    menuItems.push(
      <GridActionsCellItem
        showInMenu
        icon={<WarningIcon />}
        label="Override Safety Mechanism"
        onClick={handleOverrideSafetyMechanism}
        sx={{
          color: "red",
          "&:hover": { backgroundColor: "red", color: "white" },
        }}
      />,
    );
  }

  return menuItems;
}
