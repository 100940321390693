import { create } from "zustand";

export const useAppLayout = create<{
  /* Control the navigation drawer */
  isNavigationDrawerOpen: boolean;
  toggleNavigationDrawer: () => void;

  /* Control the notifications drawer */
  isNotificationsDrawerOpen: boolean;
  toggleNotificationsDrawer: () => void;
}>((set) => ({
  /* Control the navigation drawer */
  isNavigationDrawerOpen: false,
  toggleNavigationDrawer: () => set((state) => ({ isNavigationDrawerOpen: !state.isNavigationDrawerOpen })),

  /* Control the notifications drawer */
  isNotificationsDrawerOpen: false,
  toggleNotificationsDrawer: () =>
    set((state) => ({
      isNotificationsDrawerOpen: !state.isNotificationsDrawerOpen,
    })),
}));
