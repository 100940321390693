import React, { ForwardRefExoticComponent, ReactNode } from "react";

interface Slots {
  [key: string]: ForwardRefExoticComponent<any>;
}

/* Fills in component slots for compound components */
/* This creates a new object with the keys being the display names of the components, and the values being the components themselves */
/* This is useful for compound components, where we want to render specificly named children in a specific order */
export function createSlots(children: ReactNode, slots: Slots) {
  const result: { [key: string]: any } = {};

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;

    // @ts-expect-error - Child type is ambiguous, passed child is not
    if (slots[child.type.displayName]) {
      // If we've already encountered an element of this type, ignore this one and return the first
      // @ts-expect-error - Child type is ambiguous, passed child is not
      if (result[child.type.displayName]) return;

      // @ts-expect-error - Child type is ambiguous, passed child is not
      result[child.type.displayName] = child;
    }
  });

  return result;
}
