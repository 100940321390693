import { BaseActionView } from "./BaseActionView";
import { Grid } from "@mui/material";
import { LocationDropdown } from "../LocationDropDown";
import { useDataChangeManager } from "../../useDataChangeManager";
import { StyledTextField } from "../DataChangeManagerStyledComponents";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";
import useActionHandlers from "../../useActionHandlers";

interface UpdateLocationServiceIdViewProps {}

function UpdateLocationServiceIdView({}: UpdateLocationServiceIdViewProps) {
  const { getAllLocationsQuery, textFieldState, relationshipSelectorId } = useDataChangeManager();
  const { handleTextFieldChange } = useActionHandlers();

  return (
    <BaseActionView actionTitle="Update Location Service ID">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTextField
          id="serviceId"
          label="Service ID"
          variant="outlined"
          onChange={handleTextFieldChange}
          value={textFieldState.serviceId}
        />
      </Grid>
    </BaseActionView>
  );
}

export { UpdateLocationServiceIdView };
