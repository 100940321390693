import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
//eslint-disable-next-line
import _ from "lodash";

export const generateBaseTextForOfferMessage = (claim: Claim_ClaimsDbModel) => {
  const tenantName =
    claim?.claimantNameOverride ?? `${claim?.unit?.cognitoUserDetail?.userFirstName} ${claim?.unit?.cognitoUserDetail?.userLastName}`;
  const offerAmount = claim.adjusterValue;
  const formatttedOfferAmount = formatCurrency(offerAmount / 100);
  // This is the amount being offered before the deductible is applied. As of now the default deductible is $100.00
  const formattedPreDeductibleOfferAmount = formatCurrency((offerAmount + 10000) / 100);
  // Is the amount being offered the same as the coverage limit?
  const isLimitClaim = offerAmount === claim.unit?.selectedPolicyMatchData?.coverage;

  if (claim.claimType?.name === "Rodent" && offerAmount === 50000) {
    return `Hello ${tenantName},\n\nI have finished reviewing your claim file and genuinely appreciate your patience throughout the claims process.\n\nWe have confirmed your payment history and appreciate your cooperation in sending us support for your claimed loss amount. After considering the information we have received and thoroughly reviewing your claim file, we can offer you the rodent protection plan sub-limit of $500.00 in satisfaction of your claim.\n\nThe protection plan has a limitation for damages caused by rodents/vermin.  Please review your Protection Plan Addendum for the plan language shown below.\n\n5.        Exclusions\nLosses and damages not covered under this Protection Plan and Addendum, for which Tenant understands and agrees Owner bears no liability, and which Owner shall not pay (“Exclusions”), include the following:\n\nAny loss in excess of $500 arising directly or indirectly out of damage or activity by moths, insects, rodents or vermin;\n\nKindly inform us if you agree to receive the payment of $500.00 in settlement of your claim. Once confirmed, I will send you a settlement form via Formstack, through which we will obtain your payment address.\n\nWe appreciate your understanding and look forward to resolving this matter soon.`;
  }

  if (claim.claimType?.name === "Mold" && offerAmount === 50000) {
    return `Hello ${tenantName},\n\nI have finished reviewing your claim file and genuinely appreciate your patience throughout the claims process.\n\nWe have confirmed your payment history and appreciate your cooperation in sending us support for your claimed loss amount. After considering the information we have received and thoroughly reviewing your claim file, we can offer you the mold protection plan sub-limit of $500.00 in satisfaction of your claim.\n\nThe protection plan has a limitation for damages caused by mold.  Please review your Protection Plan Addendum for the plan language shown below.\n\n5.        Exclusions\nLosses and damages not covered under this Protection Plan and Addendum, for which Tenant understands and agrees Owner bears no liability, and which Owner shall not pay (“Exclusions”), include the following:\n\nAny loss in excess of $500 arising directly or indirectly out of presence, growth, proliferation, spread or any activity of mold, mildew, fungus, wet or dry rot or bacteria;\n\nKindly inform us if you agree to receive the payment of $500.00 in settlement of your claim. Once confirmed, I will send you a settlement form via Formstack, through which we will obtain your payment address.\n\nWe appreciate your understanding and look forward to resolving this matter soon.`;
  }

  if (isLimitClaim) {
    return `Hello ${tenantName},\n\nI have finished reviewing your claim file and genuinely appreciate your patience throughout the claims process.\n\nWe have confirmed your payment history and appreciate your cooperation in sending us support for your claimed loss amount. After considering the information we have received and thoroughly reviewing your claim file, we can offer you the protection plan limit of ${formatttedOfferAmount} in satisfaction of your claim.\n\nKindly inform us if you agree to receive the payment of ${formatttedOfferAmount} in settlement of your claim. Once confirmed, I will send you a settlement form via Formstack, through which we will obtain your payment address.\n\nWe appreciate your understanding and look forward to resolving this matter soon.`;
  }

  // default offer message
  return `Hello ${tenantName},\n\nI have finished reviewing your claim file and genuinely appreciate your patience throughout the claims process.\n\nPlease review the attached estimate which has been prepared based on the information and documentation you supplied for the replacement of items that are substantially the same as the items you claimed.\n\nWe have confirmed your payment history and appreciate your cooperation in sending us support for your claimed loss amount. After considering the information we have received and thoroughly reviewing your claim file, we can offer you ${formattedPreDeductibleOfferAmount}, less the $100.00 protection plan deductible, in satisfaction of your claim.\n\n Kindly inform us if you agree to receive the payment of ${formatttedOfferAmount} in settlement of your claim. Once confirmed, I will send you a settlement form via Formstack, through which we will obtain your payment address.\n\n We appreciate your understanding and look forward to resolving this matter soon.`;
};
