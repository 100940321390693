import { Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { ActiveClaimsSummarizedMetricsCard } from "./ActiveClaimsSummarizedMetricsCard";
import { ClosedClaimsSummarizedMetricsCard } from "./ClosedClaimsSummarizedMetricsCard";
import { ActiveClaimsTable } from "./ClaimsNavigatorTables/ActiveClaimsTable";
import { ClosedClaimsTable } from "./ClaimsNavigatorTables/ClosedClaimsTable";
import { useClaimsNavigatorStore } from "./useClaimsNavigatorStore";

type ClaimsNavigatorProps = {};

function ClaimsNavigator({}: ClaimsNavigatorProps) {
  const { tabValue, setTabValue } = useClaimsNavigatorStore((state) => ({ tabValue: state.tabValue, setTabValue: state.setTabValue }));

  const handleTabValueChange = (_: React.SyntheticEvent, newValue: "active" | "closed") => {
    setTabValue(newValue);
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flex: 1,
        overflow: "auto",
      }}
      spacing={2}
    >
      <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Claims Manager</Typography>
      <Divider />
      {tabValue === "active" ? <ActiveClaimsSummarizedMetricsCard /> : <ClosedClaimsSummarizedMetricsCard />}

      <Stack sx={{ overflow: "auto", flex: 1 }} spacing={1}>
        <Tabs
          value={tabValue}
          onChange={handleTabValueChange}
          sx={{
            ".MuiTabs-indicator": {
              backgroundColor: "#635BFF", // Custom color for the indicator
            },
          }}
        >
          <Tab
            value="active"
            label="Active claims"
            sx={{
              "&.Mui-selected": {
                color: "#635BFF", // Custom color when tab is selected
              },
            }}
          />
          <Tab
            value="closed"
            label="Closed Claims"
            sx={{
              "&.Mui-selected": {
                color: "#635BFF", // Custom color when tab is selected
              },
            }}
          />
        </Tabs>
        <SafeLeaseCard sx={{ flex: 1, overflow: "auto" }}>
          {tabValue === "active" ? <ActiveClaimsTable /> : <ClosedClaimsTable />}
        </SafeLeaseCard>
      </Stack>
    </Stack>
  );
}

export { ClaimsNavigator };
