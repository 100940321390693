import { create } from "zustand";
import { SafeleaseApi } from "../../utils/apiInstances/SafeleaseApiInstance";
import { JobErrorCenterApi } from "../../utils/apiInstances/JobErrorCenterApiInstance";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AddAdminRequest } from "@safelease/service-utilities";
import { Dayjs } from "dayjs";
import { UserRoles } from "./pages/config";

export enum AlertSeverityEnum {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

interface DataChangeManagerState {
  actionType: string;
  setActionType: (actionType: string) => void;

  confirmDialogOpen: boolean;
  setConfirmDialogOpen: (confirmDialogOpen: boolean) => void;

  textFieldState: {
    locationId: string;
    note: string;
    accountId: string;
    serviceId: string;
  };
  setTextFieldState: (name: string, value: string) => void;

  selectedDate: Dayjs | null;
  setSelectedDate: (selectedDate: Dayjs | null) => void;

  selectedLocationIds: string[];
  setSelectedLocationIds: (selectedLocationIds: string[]) => void;

  relationshipSelectorId: string;
  setRelationshipSelectorId: (relationshipSelectorId: string) => void;

  credentials: { [key: string]: string };
  setCredential: (key: string, value: string) => void;
  initializeCredentials: (initialValues: { [key: string]: string }) => void; // Initialize credentials from initial api response

  userSelectorId: string;
  setUserSelectorId: (userSelectorId: string) => void;

  userSelectorRole: UserRoles;
  setUserSelectorRole: (userSelectorRole: UserRoles) => void;

  locationExemptFromSfdcPushing: boolean;
  setLocationExemptFromSfdcPushing: (locationExemptFromSfdcPushing: boolean) => void;

  relationshipBillingRequired: boolean;
  setRelationshipBillingRequired: (relationshipBillingRequired: boolean) => void;

  clearAllFields: () => void;
}

export const useDataChangeManagerState = create<DataChangeManagerState>()((set) => ({
  actionType: "none",
  setActionType: (actionType: string) => set({ actionType }),

  confirmDialogOpen: false,
  setConfirmDialogOpen: (confirmDialogOpen: boolean) => set({ confirmDialogOpen }),

  textFieldState: {
    locationId: "",
    note: "",
    accountId: "",
    serviceId: "",
  },
  setTextFieldState: (name: string, value: string) =>
    set((state) => ({
      textFieldState: {
        ...state.textFieldState,
        [name]: value,
      },
    })),
  selectedDate: null,
  setSelectedDate: (selectedDate: Dayjs | null) => set({ selectedDate }),

  selectedLocationIds: [],
  setSelectedLocationIds: (selectedLocationIds: string[]) => set({ selectedLocationIds }),

  relationshipSelectorId: "",
  setRelationshipSelectorId: (relationshipSelectorId: string) => set({ relationshipSelectorId }),

  credentials: {},
  setCredential: (name: string, value: string) => set((state) => ({ credentials: { ...state.credentials, [name]: value } })),
  // Method to initialize credentials
  initializeCredentials: (initialValues: { [key: string]: string }) => set({ credentials: initialValues }), // Set credentials to initial values

  userSelectorId: "",
  setUserSelectorId: (userSelectorId: string) => set({ userSelectorId }),

  userSelectorRole: "" as UserRoles,
  setUserSelectorRole: (role: UserRoles) => set({ userSelectorRole: role }),

  locationExemptFromSfdcPushing: false,
  setLocationExemptFromSfdcPushing: (locationExemptFromSfdcPushing: boolean) => set({ locationExemptFromSfdcPushing }),

  relationshipBillingRequired: false,
  setRelationshipBillingRequired: (relationshipBillingRequired: boolean) => set({ relationshipBillingRequired }),

  clearAllFields: () => {
    set({
      textFieldState: {
        locationId: "",
        note: "",
        accountId: "",
        serviceId: "",
      },
      selectedDate: null,
      selectedLocationIds: [],
      relationshipSelectorId: "",
      credentials: {},
      userSelectorId: "",
      userSelectorRole: "" as UserRoles,
    });
  },
}));

export const useDataChangeManager = () => {
  const {
    actionType,
    setActionType,
    confirmDialogOpen,
    setConfirmDialogOpen,
    textFieldState,
    setTextFieldState,
    selectedDate,
    setSelectedDate,
    selectedLocationIds,
    setSelectedLocationIds,
    relationshipSelectorId,
    setRelationshipSelectorId,
    setCredential,
    initializeCredentials,
    credentials,
    clearAllFields,
    userSelectorId,
    setUserSelectorId,
    userSelectorRole,
    setUserSelectorRole,
    locationExemptFromSfdcPushing,
    setLocationExemptFromSfdcPushing,
    relationshipBillingRequired,
    setRelationshipBillingRequired,
  } = useDataChangeManagerState();

  /** START Mutations **/
  const addAdminRequestMutation = useMutation({
    mutationFn: (addAdminRequestDto: AddAdminRequest) => SafeleaseApi.addAdminRequest(addAdminRequestDto),
  });

  const approveAdminRequestMutation = useMutation({
    mutationFn: ({ requestId }: { requestId: string }) => SafeleaseApi.approveAdminRequest({ requestId }),
  });

  const rejectAdminRequestMutation = useMutation({
    mutationFn: ({ requestId }: { requestId: string }) => SafeleaseApi.rejectAdminRequest({ requestId }),
  });
  /** END Mutations **/

  /** START Queries **/
  const getAllLocationsQuery = useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      const response = await SafeleaseApi.getAllLocations();
      return response.data; // Adjust based on your actual response structure
    },
  });

  const getAdminRequestsByPageQuery = ({ page, limit }: { page: string; limit: string }) =>
    useQuery({
      queryKey: ["adminRequests", { page, limit }], // Include page and limit in the query key
      queryFn: async () => {
        const response = await SafeleaseApi.getAdminRequestsByPage({ page, limit });
        return response.data;
      },
    });

  // Fetches all the relationships to populate the relationship dropdown
  const getAllRelationshipsQuery = useQuery({
    queryKey: ["relationships"],
    queryFn: async () => {
      const response = await SafeleaseApi.getAllRelationships();
      return response;
    },
  });

  const getAccountForLocationQuery = useQuery({
    queryKey: ["account", selectedLocationIds],
    queryFn: async ({ queryKey }) => {
      const [, locationId] = queryKey; // Destructure queryKey to extract locationId
      const response = await SafeleaseApi.getAccountForLocation({ locationId: locationId[0] });
      return response.data;
    },
    enabled: false,
  });

  const getAllCoreUsersQuery = useQuery({
    queryKey: ["coreUsers"],
    queryFn: async () => {
      const payload = {
        // relationshipId is hardcoded because we only care about safelease core users
        relationshipId: 271,
        offset: 0,
        order: "id",
        limit: 1000,
      };

      const response = await JobErrorCenterApi.getUsers(payload);
      return response.data;
    },
    enabled: false,
  });
  /** END Queries **/

  return {
    getAllLocationsQuery,
    getAllRelationshipsQuery,
    getAdminRequestsByPageQuery,
    getAccountForLocationQuery,
    getAllCoreUsersQuery,
    addAdminRequestMutation,
    approveAdminRequestMutation,
    rejectAdminRequestMutation,
    actionType,
    confirmDialogOpen,
    textFieldState,
    selectedDate,
    selectedLocationIds,
    relationshipSelectorId,
    credentials,
    userSelectorId,
    userSelectorRole,
    locationExemptFromSfdcPushing,
    relationshipBillingRequired,
    setActionType,
    setConfirmDialogOpen,
    setTextFieldState,
    setSelectedDate,
    setSelectedLocationIds,
    setRelationshipSelectorId,
    setCredential,
    initializeCredentials,
    clearAllFields,
    setLocationExemptFromSfdcPushing,
    setUserSelectorId,
    setUserSelectorRole,
    setRelationshipBillingRequired,
  };
};
