import { Stack, Theme, Typography } from "@mui/material";
import { PrivatePolicyDetailsSection } from "./PrivatePolicyDetailsSection";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";

interface PrivatePolicyRejectionCriteriaProps {
  privatePolicy: SafeleasePrivatePolicy;
}

export const PrivatePolicyRejectionCriteria: React.FC<PrivatePolicyRejectionCriteriaProps> = ({ privatePolicy }) => {
  const getRejectionReason = () => {
    switch (privatePolicy.rejectionReason) {
      case "invalidDeclarationPage":
        return "Invalid Declaration Page";
      case "invalidPolicyNumber":
        return "Invalid Policy Number";
      case "invalidExpiration":
        return "Invalid Expiration";
      case "invalidTenantName":
        return "Invalid Tenant Name";
      default:
        return "Unknown Reason";
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        flex: 2,
        mb: 4,
      }}
    >
      <Typography variant="body1" sx={{ color: (theme: Theme) => theme.palette.grey[600] }}>
        Private Policy Rejection Criteria
      </Typography>
      <Stack
        direction="column"
        sx={{
          p: 2,
          border: "1px solid",
          borderColor: (theme: Theme) => theme.palette.divider,
          boxShadow: "0 0 20px rgba(0,0,0,0.05)",
          borderRadius: 2,
        }}
        spacing={1}
      >
        <PrivatePolicyDetailsSection title="Rejected Reason" value={getRejectionReason()} />
      </Stack>
    </Stack>
  );
};
