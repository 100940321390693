import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { amber, red, grey } from "@mui/material/colors";
import { Box, Tooltip } from "@mui/material";

// Job error priorities. see `job.js > async triagingStatus()` for more info
export enum Priority {
  Medium = "Medium",
  Critical = "Critical",
}

interface PriorityIconProps {
  priority: Priority;
}

const PriorityIconGenerator: React.FC<PriorityIconProps> = ({ priority }) => {
  switch (priority) {
    case Priority.Critical:
      return <RemoveCircleIcon sx={{ color: red[400] }} />;
    case Priority.Medium:
      return <DragHandleIcon sx={{ color: amber[400] }} />;
    default:
      return <HorizontalRuleIcon sx={{ color: grey[400] }} />;
  }
};

export default function PriorityIcon({ priority }: PriorityIconProps) {
  return (
    <Tooltip title={priority} arrow>
      <Box>
        <PriorityIconGenerator priority={priority} />
      </Box>
    </Tooltip>
  );
}
