import { Divider, Stack } from "@mui/material";
import { GetAdHocTenantActionsResponse } from "@safelease/service-utilities";
import { AdHocRequest } from "./AdHocRequest";

type AdHocRequestListProps = {
  adHocRequestList: GetAdHocTenantActionsResponse[]; // Ad hoc requests are just tenant actions with the type AD_HOC_REQUEST
};

function AdHocRequestList({ adHocRequestList }: AdHocRequestListProps) {
  return (
    <Stack
      sx={{
        overflow: "auto",
        padding: "10px",
      }}
      spacing={5}
      divider={<Divider />}
    >
      {adHocRequestList.map((adHocRequest) => (
        <AdHocRequest key={adHocRequest.id} adHocRequest={adHocRequest} />
      ))}
    </Stack>
  );
}

export { AdHocRequestList };
