import { Box, Divider, Stack } from "@mui/material";
import { useClaimDocumentViewerModalStore } from "../useClaimDocumentViewerModalStore";
import { SelectedDocumentDisplay } from "./SelectedDocumentDisplay";
import { SelectedDocumentDetails } from "./SelectedDocumentDetails";
import { SearchAndSortDocumentList } from "./SearchAndSortDocumentList.tsx";

type DocumentNavigatorProps = {};

function DocumentNavigator({}: DocumentNavigatorProps) {
  const { selectedDocument } = useClaimDocumentViewerModalStore((state) => ({
    selectedDocument: state.selectedDocument,
  }));

  return (
    <Stack flex={1} direction="row" padding={1} paddingTop={2} overflow="auto" divider={<Divider orientation="vertical" />}>
      {selectedDocument && (
        <>
          <Box sx={{ width: 450, overflow: "auto" }}>
            <SelectedDocumentDetails key={selectedDocument.id} />
          </Box>
          <Box sx={{ flex: 1, overflow: "auto" }}>
            <SelectedDocumentDisplay key={selectedDocument.id} file={selectedDocument.url} fileName={selectedDocument.fileName} />
          </Box>
        </>
      )}
      <Stack overflow="auto" spacing={1} padding={1} width={selectedDocument ? 300 : 680}>
        <SearchAndSortDocumentList />
      </Stack>
    </Stack>
  );
}

export { DocumentNavigator };
