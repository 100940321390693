import React, { useEffect, useState } from "react";
import { JobErrorCenterApi } from "../../../utils/apiInstances/JobErrorCenterApiInstance";
import { Button } from "@mui/material";
import Error from "../../../components/SafeLeaseError";
import { SafeLeaseLinearProgress as Loader } from "../../../components/SafeLeaseLinearProgress";
import { useMatch } from "react-router-dom";
import { Job } from "@safelease/service-utilities/types/Jobs/JobResponses";

interface RetryJobProps {}
const RetryJob: React.FC<RetryJobProps> = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [job, setJob] = useState<Job | null>(null);
  const match = useMatch("/error_center/retry/:jobId");
  let jobId: string | null | undefined = null;

  if (match) {
    jobId = match.params.jobId;
  }

  const getJob = async () => {
    if (jobId) {
      setLoading(true);
      try {
        const fetchedJob = await JobErrorCenterApi.getJob(jobId);
        setJob(fetchedJob.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getJob();
  }, [jobId]);

  if (loading) return <Loader />;
  if (err) return <Error />;

  const retry = async function () {
    if (jobId) {
      setLoading(true);
      try {
        await JobErrorCenterApi.retryJob(jobId);
        await getJob();
        setLoading(false);
      } catch (err) {
        setErr(true);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {job && (
        <>
          <div>Job: {job.method}</div>
          <div>Status: {job.status}</div>
          <div>Retries: {job.failureCount}</div>
          {job.status == 2 && (
            <Button variant="contained" onClick={retry}>
              Retry
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default RetryJob;
