import { Box, Divider, Drawer, IconButton, List, Toolbar } from "@mui/material";
import { NavigationDrawerMenuItem } from "./NavigationDrawerMenuItem";
import {
  DescriptionOutlined,
  Grading,
  KeyboardArrowLeft,
  Troubleshoot,
  ManageAccountsOutlined,
  GroupsOutlined,
  Update,
  LocationOnRounded,
} from "@mui/icons-material";
import safeleaseLogo from "../../assets/safelease-logo.png";
import { useAuthStore } from "../../hooks/useAuthStore.ts";

interface NavigationDrawerProps {
  open: boolean;
  handleClose: () => void;
}

export function NavigationDrawer({ open = true, handleClose }: NavigationDrawerProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={open}
      onClose={handleClose}
      hideBackdrop
      sx={{
        overflowX: "hidden",
        whiteSpace: "nowrap",
        boxSizing: "border-box",
      }}
      PaperProps={{
        sx: {
          boxShadow: "none",
          borderRight: "1px solid",
          borderColor: (theme) => theme.palette.divider,
        },
      }}
    >
      <Box sx={{ width: 300 }}>
        <Toolbar sx={{ pr: 0 }}>
          <Box sx={{ py: 2, flexGrow: 1, alignContent: "center", display: "flex", justifyContent: "center" }}>
            <img src={safeleaseLogo} alt="Ops Hub Logo" style={{ height: "1.7rem", width: "auto", objectFit: "contain" }} />
          </Box>
          {open && (
            <IconButton onClick={handleClose}>
              <KeyboardArrowLeft />
            </IconButton>
          )}
        </Toolbar>
        <Divider />
        <List>
          {userAttributes?.isClaimsEnabled === "true" && (
            <NavigationDrawerMenuItem path={"/claims"} label={"Claims"} icon={<DescriptionOutlined />} />
          )}
          {userAttributes?.isComplianceEnabled === "true" && (
            <NavigationDrawerMenuItem path={"/compliance"} label={"Compliance"} icon={<Grading />} />
          )}
          {userAttributes?.isJobCenterEnabled === "true" && (
            <NavigationDrawerMenuItem path={"/error_center"} label={"Job Error Center"} icon={<Troubleshoot />} />
          )}
          {userAttributes?.isTenantUserAdmin === "true" && (
            <NavigationDrawerMenuItem path={"/tenant-user-management"} label={"Tenant User Management"} icon={<GroupsOutlined />} />
          )}
          {userAttributes?.isUserSettingsAdmin === "true" && (
            <NavigationDrawerMenuItem
              path={"/internal-user-management"}
              label={"Internal User Management"}
              icon={<ManageAccountsOutlined />}
            />
          )}
          {userAttributes?.isAdminReqsEnabled === "true" && (
            <NavigationDrawerMenuItem path="/change-manager" label="Data Change Manager" icon={<Update />} />
          )}
          {userAttributes?.isProspectsEnabled === "true" && (
            <NavigationDrawerMenuItem path="/prospects" label="Prospects" icon={<LocationOnRounded />} />
          )}
        </List>
      </Box>
    </Drawer>
  );
}
