import { CircularProgress, Drawer } from "@mui/material";
import { AdjusterNotesDrawer } from "./AdjusterNotesDrawer";
import { LegacyClaimDetailsDrawerType, useLegacyClaimDetailsDrawerStore } from "./useLegacyClaimDetailsDrawer";
import { TenantCommunicationDrawer } from "./TenantCommunicationDrawer";
import { ClaimFormDrawer } from "./ClaimFormDrawer";
import SafeLeaseError from "../../../../../../components/SafeLeaseError";
import { useSelectedLegacyClaimData } from "../../useSelectedLegacyClaimData";
import { useParams } from "react-router";
import { LegacyClaim } from "@safelease/service-utilities";
/**
 * Controller component for rendering the correct drawer component based on the current drawer type from the store
 */

type LegacyClaimDetailsDrawerProps = {};

function LegacyClaimDetailsDrawer({}: LegacyClaimDetailsDrawerProps) {
  const { legacyClaimId } = useParams<{ legacyClaimId: string }>();
  if (!legacyClaimId) return null;

  const { getLegacyClaimQuery } = useSelectedLegacyClaimData(legacyClaimId);

  const { drawerOpen, currentDrawerType, closeDrawer } = useLegacyClaimDetailsDrawerStore((state) => ({
    drawerOpen: state.drawerOpen,
    currentDrawerType: state.currentDrawerType,
    closeDrawer: state.closeDrawer,
  }));

  const renderComponentForCurrentDrawerType = (legacyClaim: LegacyClaim) => {
    switch (currentDrawerType) {
      case LegacyClaimDetailsDrawerType.NOTES:
        return <AdjusterNotesDrawer legacyClaim={legacyClaim} />;
      case LegacyClaimDetailsDrawerType.CLAIM_FORM_DETAILS:
        return <ClaimFormDrawer legacyClaim={legacyClaim} />;
      case LegacyClaimDetailsDrawerType.TENANT_MESSAGING:
        return <TenantCommunicationDrawer />;
    }
  };

  return (
    <Drawer open={drawerOpen} onClose={closeDrawer} anchor="right">
      {getLegacyClaimQuery.isLoading ? (
        <CircularProgress />
      ) : getLegacyClaimQuery.error || !getLegacyClaimQuery.data ? (
        <SafeLeaseError />
      ) : (
        renderComponentForCurrentDrawerType(getLegacyClaimQuery.data)
      )}
    </Drawer>
  );
}

export { LegacyClaimDetailsDrawer };
