import { Stack, Tooltip, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { PaymentsOutlined, Warning } from "@mui/icons-material";
import { ClickableTypography } from "../../../../../../components/ClickableTypography";
import { useState } from "react";
import { PaymentHistoryModal } from "./PaymentHistoryModal/PaymentHistoryModal";

type PaymentHistoryProps = {};

function PaymentHistory({}: PaymentHistoryProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const paymentStatusAvailable = claim?.unit?.matchedAllSafeleaseData === true && claim.unit.safeleaseLeaseId !== null;
  const [paymentHistoryModalOpen, setPaymentHistoryModalOpen] = useState(false);

  if (!paymentStatusAvailable) {
    return (
      <Tooltip title="This unit has not been matched or is missing a corresponding safelease Lease ID" placement="right">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Warning color="warning" sx={{ fontSize: 20 }} />
          <Typography sx={{ fontSize: 12 }}>Payment history</Typography>
        </Stack>
      </Tooltip>
    );
  }

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <PaymentsOutlined sx={{ fontSize: 22 }} />
      <ClickableTypography onClick={() => setPaymentHistoryModalOpen(true)} sx={{ fontSize: 12 }}>
        Payment history
      </ClickableTypography>
      {paymentHistoryModalOpen && <PaymentHistoryModal setModalOpen={setPaymentHistoryModalOpen} />}
    </Stack>
  );
}

export { PaymentHistory };
