import { BaseActionView } from "./BaseActionView";
import { Grid } from "@mui/material";
import { LocationDropdown } from "../LocationDropDown";
import { useDataChangeManager } from "../../useDataChangeManager";
import { SafeLeaseDatePicker } from "@safelease/components";
import { Dayjs } from "dayjs";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";
interface UpdateLiveDateViewProps {
  dateType: "liveDate" | "cancelDate";
}

// This component is a child of DataChangeManager and is used to update the live date or cancel date of a location
function UpdateLocationDateView({ dateType }: UpdateLiveDateViewProps) {
  const { getAllLocationsQuery, selectedDate, relationshipSelectorId, setSelectedDate } = useDataChangeManager();

  const handleDateChange = (newDate: Dayjs | null) => {
    setSelectedDate(newDate);
  };

  const dateLabel = dateType === "liveDate" ? "Live Date" : "Cancel Date"; // Label based on the dateType

  return (
    <BaseActionView actionTitle={`Update Location ${dateLabel}`}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <SafeLeaseDatePicker
          sx={{ minWidth: "250px", width: "50vw", marginTop: "20px" }}
          onChange={handleDateChange}
          value={selectedDate}
        />
      </Grid>
    </BaseActionView>
  );
}

export { UpdateLocationDateView };
