import { SafeLeaseButton, SafeLeaseMenu, SafeLeaseMenuItem } from "@safelease/components";
import { useState } from "react";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";
import { enqueueSnackbar } from "notistack";
import { ExpandMore } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { CircularProgress, Theme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

type DenyClaimButtonProps = {
  disabled: boolean;
};

/**
 * This component houses the Deny offer button and its corresponding confirmation modal which openes when the button is clicked
 * disabled from the current claim having any "closed" status ("Closed - Settled", "Closed - Denied", "Closed-Abandoned")
 */

function DenyClaimButton({ disabled }: DenyClaimButtonProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const handleMenuItemClick = async (claimStatus: ClaimStatus) => {
    closeDropdown();
    setRequestPending(true);
    try {
      //Create the Tenant Action for the given type based on which button was clicked
      await ClaimsApi.updateClaim(claimId, {
        status: claimStatus,
      });

      // this will update the UI to show the new status of the claim
      refetchClaim();
    } catch (error) {
      enqueueSnackbar("Error denying the claim, please try again.", {
        variant: "error",
      });
    }
    setRequestPending(false);
  };

  const closeDropdown = () => {
    setAnchorEl(null);
  };

  if (requestPending) {
    return <CircularProgress />;
  }

  return (
    <>
      <SafeLeaseButton
        endIcon={<ExpandMore />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="small"
        variant="contained"
        disabled={disabled}
        sx={{
          bgcolor: red[500],
          color: "white",
          textTransform: "none",
          fontSize: "14px",
          "&:hover": {
            bgcolor: red[600],
          },
          "&:disabled": {
            bgcolor: (theme: Theme) => theme.palette.grey[100],
            color: (theme: Theme) => theme.palette.grey[500],
          },
        }}
      >
        Claim disposition
      </SafeLeaseButton>
      <SafeLeaseMenu
        placement="top-start"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={closeDropdown}
        sx={{ zIndex: 1, width: 300 }}
      >
        <SafeLeaseMenuItem icon={<DoNotDisturbIcon />} label="Denial" onClick={() => handleMenuItemClick(ClaimStatus.ClosedDenied)} />
        <SafeLeaseMenuItem icon={<ContentCopyIcon />} label="Duplicate" onClick={() => handleMenuItemClick(ClaimStatus.ClosedDuplicate)} />
        <SafeLeaseMenuItem icon={<HelpCenterIcon />} label="Incomplete" onClick={() => handleMenuItemClick(ClaimStatus.ClosedIncomplete)} />
        <SafeLeaseMenuItem
          icon={<CancelScheduleSendIcon />}
          label="Inactive"
          onClick={() => handleMenuItemClick(ClaimStatus.ClosedInactive)}
        />
      </SafeLeaseMenu>
    </>
  );
}

export { DenyClaimButton };
