import { Stack, Typography } from "@mui/material";
import { AssignmentTwoTone } from "@mui/icons-material";
import { NotificationBase, NotificationAvatar, NotificationContent, NotificationHeader } from ".";

interface ProcessCompletedNotificationProps {
  process: Process;

  /* Where should clicking this link lead to */
  redirectLink?: string;
}

export function ProcessCompletedNotification({ process, redirectLink = "" }: ProcessCompletedNotificationProps) {
  return (
    <NotificationBase redirectLink={redirectLink}>
      <NotificationAvatar />
      <NotificationHeader
        title={
          <>
            <span style={{ fontWeight: "bold" }}>{process.assignedBy.name}</span> completed a process
          </>
        }
        timestamp={process.completedAt!}
      />
      <NotificationContent>
        <Stack direction="row" spacing={1} alignItems="center">
          <AssignmentTwoTone color="primary" sx={{ fontSize: "32px" }} />
          <Stack direction="column" sx={{ textAlign: "left" }}>
            <Typography variant="subtitle2">{process.name}</Typography>
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[500] }}>
              {process.description}
            </Typography>
          </Stack>
        </Stack>
      </NotificationContent>
    </NotificationBase>
  );
}
