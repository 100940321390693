import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { SendSettlementConfirmationModal } from "./SendSettlementConfirmationModal";

type SendSettlementButtonProps = {
  disabled: boolean;
};

/**
 * This component houses the Send settlement button and its corresponding confirmation modal which openes when the button is clicked
 * This button should be enabled when the current claim has a settlement offer that the tenant has accepted from the prior flow.
 * disabled from the current claim having any "closed" status ("Closed - Settled", "Closed - Denied", "Closed-Abandoned")
 */

function SendSettlementButton({ disabled }: SendSettlementButtonProps) {
  const [sendSettlementModalOpen, setSendSettlementModalOpen] = useState<boolean>(false);
  return (
    <>
      <SafeLeaseButton
        onClick={() => setSendSettlementModalOpen(true)}
        disabled={disabled}
        size="small"
        disableElevation
        variant="contained"
      >
        Send settlement
      </SafeLeaseButton>
      {sendSettlementModalOpen && <SendSettlementConfirmationModal setModalOpen={setSendSettlementModalOpen} />}
    </>
  );
}

export { SendSettlementButton };
