import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { ChevronLeft } from "@mui/icons-material";
import { StyledTextFieldForUnitSelectionModal } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/StyledTextFieldForUnitSelectionModal";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";
import { captureException } from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";

type IncompleteDataSubmissionStepProps = {};

/**
 * This component is for updating the unit without fully matching
 * If the adjuster selects the button to submit with incomplete data
 * reason for using this is either due to the location being an unsupported FMS or for any other reason that we may not have lease/policy data for that tenant/unit in the core db
 */
function IncompleteDataSubmissionStep({}: IncompleteDataSubmissionStepProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [unitName, setUnitName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedLocation, setCurrentStep, setProgress, setUnitSelectionModalOpen } = useUnitSelectionModalStore((state) => ({
    selectedLocation: state.selectedLocation,
    setCurrentStep: state.setCurrentStep,
    setProgress: state.setProgress,
    setUnitSelectionModalOpen: state.setUnitSelectionModalOpen,
  }));

  const queryClient = useQueryClient();

  const { claim, refetchClaim } = useSelectedClaimPageStore((state) => ({ claim: state.claim, refetchClaim: state.refetchClaim }));
  const currentUnitId = claim?.unitId;

  const onSubmitButtonClicked = async () => {
    setIsLoading(true);

    try {
      if (!selectedLocation) throw new Error("Missing selected location data");
      if (!currentUnitId) throw new Error("Missing unit id for selected claim");
      await ClaimsApi.updateSafeleaseMatchDataForUnit(currentUnitId, {
        name: unitName.trim(),
        matchedAllSafeleaseData: false,
        adjusterManualUnitName: true,
        safeleaseMatchData: {
          safeleaseLocationDetails: selectedLocation,
        },
      });
      queryClient.invalidateQueries({ queryKey: ["policyData", claimId] });
      refetchClaim();
      setUnitSelectionModalOpen(false);
    } catch (error) {
      captureException(error);
      enqueueSnackbar("There was an error updating the claim unit. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={3} width="100%">
      <Stack spacing={0.5}>
        <Box>
          <SafeLeaseButton
            sx={{ color: "grey.A200" }}
            onClick={() => {
              setCurrentStep(UnitSelectionModalStep.TENANT_NAME_LOOKUP);
              setProgress(50);
            }}
            startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
          >
            Go back
          </SafeLeaseButton>
        </Box>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
          By submitting this, we will still be able to correctly match the location that was selected. However there will be missing data
          for the tenants protection plan coverage
        </Typography>
      </Stack>
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
          Location name: <strong>{selectedLocation?.locationName}</strong>
        </Typography>
        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
          Location address:{" "}
          <strong>{`${selectedLocation?.address} ${selectedLocation?.city}, ${selectedLocation?.state} ${selectedLocation?.postalCode}`}</strong>
        </Typography>
        <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
          Partner: <strong>{selectedLocation?.relationshipName}</strong>
        </Typography>
      </Stack>
      <Stack alignItems="center" spacing={1}>
        <StyledTextFieldForUnitSelectionModal value={unitName} setValue={setUnitName} placeholder="Enter unit name" />
        <Box>
          <SafeLeaseButton onClick={onSubmitButtonClicked} color="navy" variant="contained" disabled={!unitName.trim()} loading={isLoading}>
            Submit
          </SafeLeaseButton>
        </Box>
      </Stack>
    </Stack>
  );
}

export { IncompleteDataSubmissionStep };
