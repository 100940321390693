import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { UploadMessage } from "./UploadMessage";
import { SettlementOfferMessage } from "./SettlementOfferMessage/SettlementOfferMessage";
import { FormQuestionMessage } from "./FormQuestionMessage";
import { AdHocRequestMessage } from "./AdHocRequestMessage";

type TenantActionMessageProps = {
  data: GetClaimMessageResponse;
};

function TenantActionMessage({ data }: TenantActionMessageProps) {
  if (data.tenantAction?.type === TenantActionTypes.UPLOAD_BILLING_LEDGER) {
    return (
      <UploadMessage
        data={data}
        requestMessage={data.tenantAction.metadata?.[TenantActionTypes.UPLOAD_BILLING_LEDGER]?.requestMessage || ""}
      />
    );
  } else if (data.tenantAction?.type === TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM) {
    return (
      <UploadMessage
        data={data}
        requestMessage={data.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM]?.requestMessage || ""}
      />
    );
  } else if (data.tenantAction?.type === TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES) {
    return (
      <UploadMessage
        data={data}
        requestMessage={data.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES]?.requestMessage || ""}
      />
    );
  } else if (data.tenantAction?.type === TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES) {
    return (
      <UploadMessage
        data={data}
        requestMessage={data.tenantAction.metadata?.[TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES]?.requestMessage || ""}
      />
    );
  } else if (data.tenantAction?.type === TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT) {
    return (
      <UploadMessage
        data={data}
        requestMessage={data.tenantAction.metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]?.requestMessage || ""}
      />
    );
  } else if (data.tenantAction?.type === TenantActionTypes.SETTLEMENT_OFFER) {
    return <SettlementOfferMessage data={data} />;
  } else if (data.tenantAction?.type === TenantActionTypes.FORM_QUESTION_ANSWER) {
    return <FormQuestionMessage data={data} />;
  } else if (data.tenantAction?.type === TenantActionTypes.AD_HOC_REQUEST) {
    return <AdHocRequestMessage data={data} />;
  }
  // Should never reach here
  return <></>;
}

export { TenantActionMessage };
