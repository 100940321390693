import { create } from "zustand";

export enum ClaimDetailsDrawerType {
  NOTES = "notes",
  TENANT_MESSAGING = "tenantMessaging",
  CLAIM_FORM_DETAILS = "claimFormDetails",
  LINE_ITEM_DETAILS = "lineItemDetails",
  AD_HOC_REQUESTS = "adHocRequests",
}

type ClaimDetailsDrawerStore = {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  currentDrawerType: ClaimDetailsDrawerType | null;
  setCurrentDrawerType: (drawerType: ClaimDetailsDrawerType | null) => void;
  selectedLineItemId: string | null; // This is used to store the selected line item id when the line item drawer is open. Should be reset to null when the drawer is closed.
  setSelectedLineItemId: (lineItemId: string) => void; // This is only used to set the selected line item id  when the line item drawer is open with the View details button on the claimed items table. Should be reset to null when the drawer is closed.
  focusAdjusterValueInput: boolean;
  setFocusAdjusterValueInput: (focus: boolean) => void;
  closeDrawer: () => void; // close the drawer and reset the store state to a default initial state.
};

export const useClaimDetailsDrawerStore = create<ClaimDetailsDrawerStore>()((set) => ({
  drawerOpen: false,
  setDrawerOpen: (open) => {
    set({ drawerOpen: open });
  },
  currentDrawerType: null,
  setCurrentDrawerType: (drawerType) => {
    set({ currentDrawerType: drawerType });
  },
  selectedLineItemId: null,
  setSelectedLineItemId: (selectedLineItemId) => {
    set({ selectedLineItemId });
  },
  focusAdjusterValueInput: false,
  setFocusAdjusterValueInput: (focus) => {
    set({ focusAdjusterValueInput: focus });
  },
  closeDrawer: () => {
    set({
      drawerOpen: false,
      currentDrawerType: null,
      selectedLineItemId: null,
      focusAdjusterValueInput: false,
    });
  },
}));
