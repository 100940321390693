import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
  // Badge,
  useTheme,
} from "@mui/material";
// import { Notifications } from "@mui/icons-material";
import { useAppLayout } from "../../hooks/useAppLayout";
import OpsHubLogo from "../../assets/ops-hub-logo.png";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router";

export function SafeLeaseAppBar() {
  const {
    isNavigationDrawerOpen,
    toggleNavigationDrawer,
    // toggleNotificationsDrawer,
  } = useAppLayout();
  const theme = useTheme();
  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    await signOut();
    navigate("/"); // need to make sure to go back to the login page or else we will show the 404 page not found when the user logs out from any page other than the home page
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex", height: "65px" }}>
      <AppBar
        position="static"
        sx={{
          width: `calc(100% - ${isNavigationDrawerOpen ? 300 : 0}px)`,
          marginLeft: `${isNavigationDrawerOpen ? 300 : 0}px`,

          // Make the animation follow the drawer slide in/out timing and bezier curve
          transition: theme.transitions.create(["margin", "width"], {
            easing: isNavigationDrawerOpen ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
            duration: isNavigationDrawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          {!isNavigationDrawerOpen && (
            <IconButton size="large" edge="start" aria-label="menu" sx={{ mr: 0.5 }} onClick={toggleNavigationDrawer}>
              <MenuIcon />
            </IconButton>
          )}
          <img
            onClick={() => navigate("/")}
            src={OpsHubLogo}
            alt="Ops Hub Logo"
            style={{ height: "1.7rem", width: "auto", objectFit: "contain", cursor: "pointer" }}
          />
          <Box sx={{ flexGrow: 1 }} />
          {/* Commenting out until we develop a proper notification system */}
          {/* <IconButton
            size="large"
            aria-label="show 17 new notifications"
            onClick={toggleNotificationsDrawer}
          >
            <Badge badgeContent={17} color="error">
              <Notifications />
            </Badge>
          </IconButton> */}
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
