import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleConfirmAction: () => void;
  actionLabel: string;
}

function ConfirmationDialog({ open, handleClose, handleConfirmAction, actionLabel }: ConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm {actionLabel}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to submit a request to perform action "{actionLabel}". <br />
          Please confirm that you want to proceed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* Close the dialog on cancel action click */}
        <Button onClick={handleClose} color="warning" startIcon={<HighlightOffIcon />} variant="contained">
          Cancel Action
        </Button>
        <Button onClick={handleConfirmAction} color="primary" startIcon={<CheckCircleIcon />} variant="contained">
          Confirm Action
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ConfirmationDialog };
