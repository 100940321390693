import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import { DrawerHeader } from "../sharedComponents/DrawerHeader";
import { NotesList } from "./NotesList";
import { useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useState } from "react";
import { TextFieldWithSubmissionButtons } from "../sharedComponents/TextFieldWithSubmissionButtons";
import { useParams } from "react-router";

type AdjusterNotesDrawerProps = {};

function AdjusterNotesDrawer({}: AdjusterNotesDrawerProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [sendingNote, setSendingNote] = useState<boolean>(false);

  const notesQuery = useQuery({
    queryKey: ["getNotes", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getAdjusterNotesForClaim(claimId);
      return response.data;
    },
  });

  const handleAddNote = async (note: string) => {
    setSendingNote(true);
    try {
      await ClaimsApi.createAdjusterNote({
        claimId,
        note: note,
      });
      notesQuery.refetch();
    } catch (error) {
      enqueueSnackbar("Error adding note, please try again.", {
        variant: "error",
      });
    }
    setSendingNote(false);
  };

  return (
    <Stack sx={{ width: 600, height: "100%", padding: "16px" }} spacing={2}>
      {notesQuery.isLoading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : notesQuery.error ? (
        <>error...</>
      ) : (
        <>
          <DrawerHeader title="Adjuster notes" subTitle={`${notesQuery.data.length} notes`} />
          <Divider />
          <NotesList list={notesQuery.data} />
          <TextFieldWithSubmissionButtons buttonLabel="Add note" loading={sendingNote} onSubmit={(note) => handleAddNote(note)} />
        </>
      )}
    </Stack>
  );
}

export { AdjusterNotesDrawer };
