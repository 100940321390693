import { useState, useEffect, BaseSyntheticEvent } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
} from "@mui/material";
import { SafeLeaseLetterAvatar as LetterAvatar } from "@safelease/components";
import { JobErrorCenterApi } from "../../../../utils/apiInstances/JobErrorCenterApiInstance";
import { GetUsersResponse, User } from "@safelease/service-utilities/types/Users/UserResponses";
import { AssigneeOption } from "../../../../types/JobErrorCenter/AssigneeOption";
import { useAuthStore } from "../../../../hooks/useAuthStore";

interface AssignmentFieldProps {
  assignee: any;
  onReassign: (assignee: any) => void;
  loading: boolean;
}

/* Avatar with a dropdown to select a user in the SafeLease relationship */
export function AssignmentField({ assignee, onReassign, loading = false }: AssignmentFieldProps) {
  const userAttributes = useAuthStore((state) => state.userAttributes);
  /* Keep track of the current selection in the menu */
  const [selected, setSelected] = useState<object | null>(null);
  const [users, setUsers] = useState<any>([]);
  const [availableOptions, setAvailableOptions] = useState<any>([]);
  /* Keep track of the search value */
  const [inputValue, setInputValue] = useState<string>(assignee?.name ?? "");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (users.edges?.length) {
      const availOptions = buildAvailableOptions(users);
      setAvailableOptions([...availOptions, { id: null, name: "Unassigned" }]);
    }
  }, [users, inputValue]);

  useEffect(() => {
    if (assignee) {
      setSelected({
        name: assignee.name,
        id: assignee.id,
      });
    }
  }, [assignee]);

  const fetchUsers = async () => {
    const payload = {
      relationshipId: 271,
      offset: 0,
      order: "id",
      limit: 50,
    };
    const response = await JobErrorCenterApi.getUsers(payload);
    setUsers(response.data);
  };

  const buildAvailableOptions = (users: GetUsersResponse) => {
    return users.edges
      .filter((user: User) => {
        if (inputValue !== "" && selected === null) {
          /* If the search string matches part of the name */
          const nameContainsPartOfInputValue = user.name.toLowerCase().includes(inputValue.toLowerCase().trim());
          if (nameContainsPartOfInputValue) {
            return true;
          } else {
            return false;
          }
        }

        return user.email !== userAttributes?.email;
      })
      .map((user: User) => {
        return {
          id: user.id,
          name: user.name,
        };
      });
  };

  /* Picking a person */
  const handleSelect = (value: AssigneeOption) => {
    setSelected(value);
    handleClose();
    onReassign(value);
  };

  const handleOpenMenu = (event: BaseSyntheticEvent) => {
    handleStopPropagation(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopPropagation = (event: BaseSyntheticEvent) => {
    event?.stopPropagation();
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu} disabled={loading}>
        {loading ? <CircularProgress size={40} /> : <LetterAvatar name={assignee?.name || "Unassigned"} />}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClick={handleStopPropagation}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ p: 0.5, width: 240 }}>
          <Autocomplete
            isOptionEqualToValue={(option, value) => {
              return option.name == value.name;
            }}
            options={availableOptions}
            sx={{ width: 216 }}
            value={selected as AssigneeOption | null}
            getOptionLabel={(option: AssigneeOption) => {
              return assignee?.name?.length > 0 ? option.name : "Unassigned";
            }}
            inputValue={inputValue}
            clearOnBlur={false}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            onFocus={() => {
              setInputValue("");
              setSelected(null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                sx={{ m: 1 }}
                InputProps={{
                  sx: {
                    px: 1,
                  },
                  startAdornment:
                    selected === null ? (
                      <Avatar sx={{ mr: 1, height: 30, width: 30, fontSize: "14px" }} />
                    ) : (
                      <LetterAvatar name={assignee?.name || "Unassigned"} sx={{ height: 30, width: 30, mr: 1, fontSize: "14px" }} />
                    ),
                }}
              />
            )}
          />
        </Box>
        <Divider sx={{ color: "#152744" }} />
        <Box
          sx={{
            height: Math.min(availableOptions.length * 40 + 20, 220),
            overflow: "scroll",
          }}
        >
          {availableOptions.map((user: AssigneeOption) => (
            <MenuItem
              key={user.id}
              sx={{
                borderLeft: "2px solid transparent",
                height: 40,
                "&:hover": {
                  borderLeft: "2px solid rgba(40, 121, 251, 1)",
                },
              }}
              onClick={() => handleSelect(user)}
            >
              <ListItemIcon>
                <LetterAvatar name={user?.name || "Unassigned"} sx={{ mr: 1, height: 32, width: 32, fontSize: "14px" }} />
              </ListItemIcon>
              <ListItemText primary={user.name ? user.name : "something"} />
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
}
