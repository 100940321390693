import { Document } from "@safelease/service-utilities";
import { DocumentLabels } from "@safelease/service-utilities/enums";
import { create } from "zustand";

type ClaimDocumentViewerModalStore = {
  documentList: Document[];
  setDocumentList: (documentList: Document[]) => void;
  // Selected value for the document type dropdown. This is used to filter the documents list by label
  selectedDocumentLabel: string;
  setSelectedDocumentLabel: (documentLabels: string) => void;
  // Selected value for the line item name/id dropdown. This will be set to the id of the desired lineItem. This is used to filter the documents list by line item when the Claim items label is selected
  selectedLineItemId: string;
  setSelectedLineItemId: (lineItemSubLabels: string) => void;
  selectedDocument: Document | null;
  setSelectedDocument: (document: Document | null) => void;
  updateSelectedDocument: (document: Document) => void;
  setDocumentListRefetch: (documentListRefetch: () => void) => void;
  documentListRefetch: () => void;
  filteredDocumentList: Document[];
  setFilteredDocumentList: () => void;
};

export const useClaimDocumentViewerModalStore = create<ClaimDocumentViewerModalStore>()((set, get) => ({
  documentList: [],
  setDocumentList: (documentList) => {
    set({ documentList });
  },
  selectedDocumentLabel: "",
  setSelectedDocumentLabel: (selectedDocumentLabel) => {
    set({ selectedDocumentLabel });
  },
  selectedLineItemId: "",
  setSelectedLineItemId: (selectedLineItemId) => {
    set({ selectedLineItemId });
  },
  selectedDocument: null,
  setSelectedDocument: (selectedDocument) => {
    if (get().selectedDocument?.id === selectedDocument?.id) {
      selectedDocument = null;
    }
    set({ selectedDocument });
  },
  updateSelectedDocument: (updatedDocument) => {
    const documentList = get().documentList.map((document) => {
      if (document.id === updatedDocument.id) {
        return updatedDocument;
      }
      return document;
    });
    if (get().selectedDocument?.id === updatedDocument.id) {
      set({ documentList, selectedDocument: updatedDocument });
    } else {
      set({ documentList });
    }
  },
  setDocumentListRefetch: (documentListRefetch) => {
    set({ documentListRefetch });
  },
  documentListRefetch: () => {},
  filteredDocumentList: [],
  setFilteredDocumentList: () => {
    // filter the document list based on the selected label and line item id
    const selectedDocumentLabel = get().selectedDocumentLabel;
    const selectedLineItemId = get().selectedLineItemId;
    const documentList = get().documentList;
    let filteredDocumentList: Document[];
    if (!selectedDocumentLabel) {
      filteredDocumentList = documentList.filter((document) => document.label !== DocumentLabels.ARCHIVED);
    } else if (selectedDocumentLabel === DocumentLabels.CLAIM_ITEMS) {
      if (!selectedLineItemId) {
        filteredDocumentList = documentList.filter((document) => document.label === selectedDocumentLabel);
      } else {
        filteredDocumentList = documentList.filter((document) => document.lineItemData?.id === selectedLineItemId);
      }
    } else {
      filteredDocumentList = documentList.filter((document) => document.label === selectedDocumentLabel);
    }
    set({ filteredDocumentList });

    // if the selected document is not in the filtered list, clear the selected document
    if (get().selectedDocument && !filteredDocumentList.some((document) => document.id === get().selectedDocument?.id)) {
      set({ selectedDocument: null });
    }
  },
}));
