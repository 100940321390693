import { Card, Modal, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";
import { useState } from "react";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { useSelectedClaimPageStore } from "../../../useSelectedClaimPageStore";

type SolicitFeedbackConfirmationModalProps = {
  setModalOpen: (modalOpen: boolean) => void;
};

function SolicitFeedbackConfirmationModal({ setModalOpen }: SolicitFeedbackConfirmationModalProps) {
  const { claimId } = useParams();
  if (!claimId) return null;

  const [apiRequestLoading, setApiRequestLoading] = useState(false);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const handleClose = () => {
    setModalOpen(false);
  };

  const onSolicitFeedbackButtonClicked = async () => {
    setApiRequestLoading(true);
    try {
      await ClaimsApi.solicitClaimantFeedback(claimId);
      refetchClaim();
      enqueueSnackbar("Feedback solicited successfully", { variant: "success" });
      handleClose();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      enqueueSnackbar("Failed to solicit feedback", { variant: "error" });
    } finally {
      setApiRequestLoading(false);
    }
  };

  return (
    <Modal open={true} onClose={handleClose} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <Typography>Are you sure you would like to solicit feedback from this tenant?</Typography>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <SafeLeaseButton onClick={handleClose} color="navy" size="small" variant="outlined">
              Cancel
            </SafeLeaseButton>
            <SafeLeaseButton loading={apiRequestLoading} onClick={onSolicitFeedbackButtonClicked} size="small" variant="contained">
              Solicit feedback
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { SolicitFeedbackConfirmationModal };
