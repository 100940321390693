import dayjs, { Dayjs } from "dayjs";

export function formatDateAsTimeAgo(date: Date | Dayjs) {
  const now = dayjs();
  const diff = now.diff(date, "second"); // Get the difference in seconds

  if (diff < 60) {
    return `${diff}s ago`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes}m ago`;
  } else if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours}h ago`;
  } else if (diff < 604800) {
    const days = Math.floor(diff / 86400);
    return `${days}d ago`;
  } else if (diff < 2419200) {
    // 4 weeks
    const weeks = Math.floor(diff / 604800);
    return `${weeks}w ago`;
  } else {
    return ">1mo ago";
  }
}
