import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { ChevronLeft, Search } from "@mui/icons-material";
import { StyledTextFieldForUnitSelectionModal } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/StyledTextFieldForUnitSelectionModal";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { captureException } from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { BorderedCheckbox } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/BorderedCheckbox";
import { OrDividingLine } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/OrDividingLine";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";

type TenantNameLookupStepProps = {};

function TenantNameLookupStep({}: TenantNameLookupStepProps) {
  const [defaultNameSelected, setDefaultNameSelected] = useState<boolean>(false);
  const [userProvidedName, setUserProvidedName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noUnitsFoundErrorMessage, setNoUnitsFoundErrorMessage] = useState<string>("");
  const { setCurrentStep, selectedLocation, setLeaseMatchingData, setProgress } = useUnitSelectionModalStore((state) => ({
    setCurrentStep: state.setCurrentStep,
    selectedLocation: state.selectedLocation,
    setLeaseMatchingData: state.setLeaseMatchingData,
    setProgress: state.setProgress,
  }));
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const defaultTenantName = `${claim?.unit?.cognitoUserDetail?.userFirstName} ${claim?.unit?.cognitoUserDetail?.userLastName}`;
  const disableContinueButton = !defaultNameSelected && !userProvidedName.trim();

  const onContinue = async () => {
    setIsLoading(true);
    const tenantName = defaultNameSelected ? defaultTenantName : userProvidedName;
    try {
      if (!selectedLocation) throw new Error("Missing selected location data");
      const response = await ClaimsApi.getSafeleaseLeaseMatchingDataForLocationViaTenantName({
        locationId: selectedLocation.locationId,
        tenantName: tenantName,
      });
      if (response.data.length === 0) {
        setNoUnitsFoundErrorMessage(
          `We couldn't find any units with '${tenantName}'. Please make sure you provide the name that was used in the FMS. If this is a tenant who belongs to an unsupported FMS or you would like to update the unit without fully matching please click the button below.`,
        );
      } else {
        setLeaseMatchingData(response.data);
        setCurrentStep(UnitSelectionModalStep.UNIT_SELECTION);
        setProgress(75);
      }
    } catch (error: any) {
      captureException(error);
      enqueueSnackbar("There was an error searching for your unit. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack width="100%" spacing={3}>
      <Stack spacing={0.5}>
        <Box>
          <SafeLeaseButton
            sx={{ color: "grey.A200" }}
            onClick={() => {
              setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
            }}
            startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
          >
            Go back
          </SafeLeaseButton>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{"Let's find the unit via the tenants name"}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: (theme) => theme.palette.grey.A200 }}>
          {"Enter the tenants name as it appears on the FMS"}
        </Typography>
      </Stack>
      <BorderedCheckbox value={defaultNameSelected} setValue={setDefaultNameSelected} label={defaultTenantName} />
      <OrDividingLine />
      <StyledTextFieldForUnitSelectionModal
        value={userProvidedName}
        setValue={setUserProvidedName}
        disabled={defaultNameSelected}
        placeholder="Enter name"
        startIcon={<Search />}
      />
      {noUnitsFoundErrorMessage && (
        <Stack spacing={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 500, color: "error.main" }}>{noUnitsFoundErrorMessage}</Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <SafeLeaseButton
              onClick={() => {
                setCurrentStep(UnitSelectionModalStep.INCOMPLETE_DATA_SUBMISSION);
                setProgress(75);
              }}
              variant="outlined"
              color="navy"
              size="small"
            >
              Update with incomplete data
            </SafeLeaseButton>
          </Box>
        </Stack>
      )}
      <Tooltip title={disableContinueButton ? "Please select/provide a name to continue" : ""} placement="top">
        <Box>
          <SafeLeaseButton
            onClick={onContinue}
            loading={isLoading}
            disabled={disableContinueButton}
            color="navy"
            variant="contained"
            fullWidth
          >
            Continue
          </SafeLeaseButton>
        </Box>
      </Tooltip>
    </Stack>
  );
}

export { TenantNameLookupStep };
