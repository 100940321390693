import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { CheckBox, DisabledByDefaultRounded } from "@mui/icons-material";
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid-premium";

const booleanFieldRenderCell = (params: GridRenderCellParams<UserType, any, any, GridTreeNodeWithRender>) => {
  return params.value === "true" ? <CheckBox sx={{ color: "green.main" }} /> : <DisabledByDefaultRounded color="error" />;
};

export const columnDefs: GridColDef<UserType>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    valueGetter: (params) => {
      const firstName = params.row.Attributes?.find((attr) => attr.Name === "given_name")?.Value ?? "";
      const lastName = params.row.Attributes?.find((attr) => attr.Name === "family_name")?.Value ?? "";
      return `${firstName} ${lastName}`;
    },
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "email")?.Value ?? "";
    },
  },
  {
    field: "userStatus",
    headerName: "User status",
    flex: 1,
    valueGetter: (params) => {
      return params.row.UserStatus;
    },
  },
  {
    field: "isDebuggingEnabled",
    headerName: "Session debugging enabled",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isDebuggingEnabled")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "needsPasswordReset",
    headerName: "Needs password reset",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:needsPasswordReset")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
  {
    field: "isEmailVerified",
    headerName: "Email verified",
    flex: 1,
    valueGetter: (params) => {
      return params.row.Attributes?.find((attr) => attr.Name === "custom:isEmailVerified")?.Value ?? "";
    },
    renderCell: booleanFieldRenderCell,
  },
];
