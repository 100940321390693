import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { SafeLeaseTextField } from "@safelease/components";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { ClickableTypography } from "../../../../../../components/ClickableTypography";

type AdjusterValueMetricProps = {};

function AdjusterValueMetric({}: AdjusterValueMetricProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const adjusterValueInputRef = useRef<HTMLInputElement | null>(null);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [editAdjusterValue, setEditAdjusterValue] = useState<boolean>(false);
  const [adjusterValue, setAdjusterValue] = useState<string>("");

  useEffect(() => {
    // Focus the adjuster value text field if the focusAdjusterValueInput is true
    if (adjusterValueInputRef.current !== null && editAdjusterValue) adjusterValueInputRef.current.focus();
  }, [editAdjusterValue]);

  const onEnter = async (adjusterValue: string) => {
    if (!claim) return; // mainly here for typescript. should never occur
    if (!adjusterValue) {
      // if the adjuster value is empty, we should not update the value but instead treat it as a cancel
      setEditAdjusterValue(false);
      return;
    }
    setRequestPending(true);
    const adjusterValueInPennies = Number(adjusterValue.replace(/\D/g, ""));
    try {
      await ClaimsApi.updateClaim(claim.id as string, {
        adjusterValue: adjusterValueInPennies,
      });
      setEditAdjusterValue(false);
      await refetchClaim();
    } catch (error) {
      enqueueSnackbar("Failed to update adjuster value", {
        variant: "error",
      });
    }
    setRequestPending(false);
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      // submit the update claims request
      onEnter(adjusterValue);
      setAdjusterValue("");
    }
  };

  if (requestPending) {
    return <CircularProgress />;
  }

  return (
    <Box>
      {!editAdjusterValue ? (
        <>
          <ClickableTypography onClick={() => setEditAdjusterValue(true)}>
            {formatCurrency((claim?.adjusterValue || 0) / 100)}
          </ClickableTypography>
          <Typography
            sx={{
              color: (theme: Theme) => theme.palette.grey.A200,
              fontSize: 12,
            }}
          >
            Adjuster value
          </Typography>
        </>
      ) : (
        <NumericFormat
          allowNegative={false}
          fixedDecimalScale
          thousandSeparator=","
          prefix="$"
          decimalScale={2}
          fullWidth
          sx={{ backgroundColor: "white", borderRadius: "5px" }}
          customInput={SafeLeaseTextField}
          value={adjusterValue}
          onChange={(e) => setAdjusterValue(e.target.value)}
          onKeyDown={handleKeyDown}
          inputRef={adjusterValueInputRef}
        />
      )}
    </Box>
  );
}

export { AdjusterValueMetric };
