import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";

export const useSelectedLegacyClaimData = (legacyClaimId: string) => {
  const getLegacyClaimQuery = useQuery({
    queryKey: ["legacyClaim", legacyClaimId],
    queryFn: async () => {
      const response = await ClaimsApi.getLegacyClaim(legacyClaimId);
      return response.data;
    },
  });

  return {
    getLegacyClaimQuery,
  };
};
