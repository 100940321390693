import { useParams } from "react-router";
import { useSelectedLegacyClaimData } from "../useSelectedLegacyClaimData";
import { Divider, Skeleton, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { AttachmentsSection } from "./AttachmentsSection";
import SafeLeaseError from "../../../../../components/SafeLeaseError";
import { MetricsAndActionsCard } from "./MetricsAndActionsCard";
import { LegacyClaimDetailsDrawer } from "./LegacyClaimDetailsDrawer";

type BodyCardProps = {};

function BodyCard({}: BodyCardProps) {
  const { legacyClaimId } = useParams<{ legacyClaimId: string }>();
  if (!legacyClaimId) return null;

  const { getLegacyClaimQuery } = useSelectedLegacyClaimData(legacyClaimId);

  if (getLegacyClaimQuery.isLoading) return <Skeleton variant="rounded" sx={{ flex: 1 }} />;
  const legacyClaim = getLegacyClaimQuery.data;
  if (!legacyClaim) return <SafeLeaseError />;

  return (
    <SafeLeaseCard
      sx={{
        padding: "16px",
        overflow: "visible",
      }}
    >
      <Stack spacing={2}>
        <MetricsAndActionsCard legacyClaim={legacyClaim} />
        <Stack>
          <Typography sx={{ fontWeight: 700, color: "rgba(3, 30, 48, 1)", fontSize: 14 }}>Damage description</Typography>
          <Typography
            sx={{
              color: (theme: Theme) => theme.palette.grey.A200,
              fontSize: 14,
            }}
          >
            {legacyClaim?.damageDescription}
          </Typography>
        </Stack>
        <Divider />
        <AttachmentsSection legacyClaim={legacyClaim} />
        <LegacyClaimDetailsDrawer />
      </Stack>
    </SafeLeaseCard>
  );
}

export { BodyCard };
