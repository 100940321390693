import { Box, Stack, Typography } from "@mui/material";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { StatusChip } from "./StatusChip";

type SettlementOfferMessageProps = {
  data: GetClaimMessageResponse;
};

function SettlementOfferMessage({ data }: SettlementOfferMessageProps) {
  return (
    <Stack spacing={1}>
      <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
        {formatCurrency(Number(data.tenantAction?.settlementOffer?.amount) / 100)}
      </Typography>
      <Box>
        <StatusChip
          tenantAcceptanceStatus={data.tenantAction?.settlementOffer?.tenantAccepted}
          responseDate={data.tenantAction?.settlementOffer?.responseDate}
        />
      </Box>
      <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap" }}>{data.tenantAction?.settlementOffer?.message}</Typography>
    </Stack>
  );
}

export { SettlementOfferMessage };
