import { Box, LinearProgress, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { LicenseInfo } from "@mui/x-license-pro";
import { theme } from "@safelease/components";
import { NonAuthenticatedRouter, AuthenticatedRouter } from "./utils/routes";
import { useEffect, useState } from "react";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { UserAttributes, useAuthStore } from "./hooks/useAuthStore";
import * as Sentry from "@sentry/react";

LicenseInfo.setLicenseKey(
  "ac9cf4c13c4831708ffd10b7b09fcf55Tz05NjkxNixFPTE3NTYzMDM0MDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const queryClient = new QueryClient();
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);

  useEffect(() => {
    const setAuthStatus = async () => {
      try {
        const { username } = await getCurrentUser(); // throws an error if not authenticated
        setIsAuthenticated(!!username);
        const userAttributes = await fetchUserAttributes();
        const sanitizedUserAttributes: any = {};
        for (const [key, val] of Object.entries(userAttributes)) {
          // any custom attributes are prefixed with "custom:" so we remove that prefix
          sanitizedUserAttributes[key.startsWith("custom:") ? key.substring(7) : key] = val as string;
        }
        setUserAttributes(sanitizedUserAttributes as UserAttributes);
        Sentry.setUser(sanitizedUserAttributes);
      } catch (error) {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    const hubListenerCancelToken = Hub.listen("auth", (data) => {
      if (data.payload.event === "signedIn" || data.payload.event === "signedOut") {
        if (data.payload.event === "signedOut") {
          setIsLoading(true);
        }
        setAuthStatus();
      }
    });

    setAuthStatus();
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ width: "100vw", height: "100vh" }}>
        <LinearProgress />
      </Box>
    );
  }
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }} autoHideDuration={1500}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {isAuthenticated && <AuthenticatedRouter />}
          {!isAuthenticated && <NonAuthenticatedRouter />}
        </ThemeProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
