import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, ListItemButton, ListItemIcon, ListItemText, SxProps, Theme } from "@mui/material";
import { ReactElement, ReactNode, cloneElement, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavigationItem } from "./navigationDrawerContent";

interface NavigationDrawerMenuItemProps {
  path: string;
  label: string;
  icon?: ReactNode | null;
  children?: NavigationItem[];
  sx?: SxProps;
}

export function NavigationDrawerMenuItem({ path = "", label, icon, children = [], sx = {} }: NavigationDrawerMenuItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<boolean>(false);

  const isActiveRoute = location.pathname === path;

  return (
    <>
      <ListItemButton
        onClick={() => {
          if (children.length > 0) {
            setExpanded((prev) => !prev);
          } else {
            navigate(path);
          }
        }}
        sx={{
          mx: 1,
          my: 0.5,
          borderRadius: 2,
          color: "rgb(99, 115, 129)",
          "&:hover": {
            bgcolor: "rgba(145, 158, 171, 0.08)", // gray hover if not active
          },
          ...(isActiveRoute && {
            color: "rgb(7, 141, 238)",
            bgcolor: "rgba(7, 141, 238, 0.08)",
            "&:hover": {
              bgcolor: "rgba(7, 141, 238, 0.16)", // blue hover if active
            },
          }),
          ...sx,
        }}
      >
        <ListItemIcon sx={{ minWidth: 0, width: 40 }}>
          {icon &&
            cloneElement(icon as ReactElement, {
              sx: { ...(isActiveRoute && { color: "rgb(7, 141, 238)" }) },
            })}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: {
              fontSize: (theme: Theme) => theme.typography.body2.fontSize,
              fontWeight: 500,
            },
          }}
        />
        {children.length > 0 ? expanded ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      {children.length > 0 && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children.map(({ path: childPath, label, children: _children, ...route }, index) => (
            <NavigationDrawerMenuItem sx={{ pl: 6 }} path={childPath} label={label ?? ""} key={index} {...route} />
          ))}
        </Collapse>
      )}
    </>
  );
}
