import { Autocomplete, AutocompleteChangeReason, CircularProgress, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useClaimDocumentViewerModalStore } from "../../useClaimDocumentViewerModalStore";

type DocumentTagsProps = {};

interface TagOption {
  inputValue?: string;
  name: string;
  id?: string;
}

const filter = createFilterOptions<TagOption>();

function DocumentTags({}: DocumentTagsProps) {
  const { selectedDocument, updateSelectedDocument } = useClaimDocumentViewerModalStore((state) => ({
    selectedDocument: state.selectedDocument,
    updateSelectedDocument: state.updateSelectedDocument,
  }));
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [tagValues, setTagValues] = useState<TagOption[]>(
    selectedDocument?.tags.map((tag) => ({
      name: tag.name,
      id: tag.attachmentTagTypeId,
    })) || [],
  );

  const existingDocumentTagsQuery = useQuery({
    queryKey: ["getDocumentTags"],
    queryFn: async () => {
      const response = await ClaimsApi.getDocumentTags();
      return response.data;
    },
  });

  const options: TagOption[] =
    existingDocumentTagsQuery.data?.map((tag) => ({
      name: tag.name,
      id: tag.id,
    })) || [];

  const handleChange = async (_: React.SyntheticEvent<Element, Event>, newValue: TagOption[], reason: AutocompleteChangeReason) => {
    if (!selectedDocument?.id) return;
    setRequestPending(true);
    if (reason === "selectOption") {
      const newestTag = newValue[newValue.length - 1]; // there will always be a new option as the last element in the new value array
      if (newestTag.inputValue) {
        // This is a new tag that was just created.
        try {
          const response = await ClaimsApi.addDocumentTag(selectedDocument?.id, { tag: newestTag.inputValue });
          updateSelectedDocument({
            ...selectedDocument,
            tags: response.data,
          });
          setTagValues(
            response.data.map((tag) => ({
              name: tag.name,
              id: tag.attachmentTagTypeId,
            })),
          );
          await existingDocumentTagsQuery.refetch(); // need to refresh the options so that this newly created tag will show up in the list
        } catch (err) {
          enqueueSnackbar("Error adding tag", { variant: "error" });
        }
      } else {
        // This is an existing tag that was selected
        try {
          const response = await ClaimsApi.addDocumentTag(selectedDocument?.id, { attachmentTagTypeId: newestTag.id });
          updateSelectedDocument({
            ...selectedDocument,
            tags: response.data,
          });
          setTagValues(
            response.data.map((tag) => ({
              name: tag.name,
              id: tag.attachmentTagTypeId,
            })),
          );
        } catch (err) {
          enqueueSnackbar("Error adding tag", { variant: "error" });
        }
      }
    } else if (reason === "removeOption") {
      const deletedTags = tagValues.filter((tag) => !newValue.find((val) => val.id === tag.id)); // This list contains tags that were removed with the most recent action. This should only contain one value.
      for (const tag of deletedTags) {
        try {
          const response = await ClaimsApi.removeDocumentTag(selectedDocument?.id, { attachmentTagTypeId: tag.id! });
          updateSelectedDocument({
            ...selectedDocument,
            tags: response.data,
          });
          setTagValues(
            response.data.map((tag) => ({
              name: tag.name,
              id: tag.attachmentTagTypeId,
            })),
          );
        } catch (err) {
          enqueueSnackbar("Error removing tag", { variant: "error" });
        }
      }
    } else if (reason === "clear") {
      // This simply clears out all the tags so we must delete all of them
      for (const tag of tagValues) {
        try {
          const response = await ClaimsApi.removeDocumentTag(selectedDocument?.id, { attachmentTagTypeId: tag.id! });
          updateSelectedDocument({
            ...selectedDocument,
            tags: response.data,
          });
          setTagValues(
            response.data.map((tag) => ({
              name: tag.name,
              id: tag.attachmentTagTypeId,
            })),
          );
        } catch (err) {
          enqueueSnackbar("Error removing tags", { variant: "error" });
        }
      }
    }
    setRequestPending(false);
  };

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Tags</Typography>
      {existingDocumentTagsQuery.isLoading || requestPending ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          value={tagValues}
          multiple
          options={options}
          onChange={handleChange}
          getOptionLabel={(option) => {
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          renderInput={(params) => <TextField {...params} />}
          filterOptions={(options, params) => {
            const filtered = filter(options, params).filter((option) => !tagValues.some((tag) => tag.id === option.id));

            if (
              params.inputValue.trim() !== "" &&
              !options.some((option) => option.name.toLowerCase() === params.inputValue.trim().toLowerCase())
            ) {
              filtered.push({
                inputValue: params.inputValue.trim(),
                name: `Create new tag "${params.inputValue.trim()}"`,
              });
            }

            return filtered;
          }}
        />
      )}
    </Stack>
  );
}

export { DocumentTags };
