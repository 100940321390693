import { Box, Card, CircularProgress, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentTypeActionsHeader } from "./DocumentTypeActionsHeader";
import { DocumentNavigator } from "./DocumentNavigator";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useEffect } from "react";
import { useClaimDocumentViewerModalStore } from "./useClaimDocumentViewerModalStore";

type ClaimDocumentViewerModalProps = {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
};

function ClaimDocumentViewerModal({ modalOpen, setModalOpen }: ClaimDocumentViewerModalProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const { setDocumentList, selectedDocument, setDocumentListRefetch } = useClaimDocumentViewerModalStore((state) => ({
    setDocumentList: state.setDocumentList,
    selectedDocument: state.selectedDocument,
    setDocumentListRefetch: state.setDocumentListRefetch,
  }));

  const claimDocumentsQuery = useQuery({
    queryKey: [`getClaimDocuments`, claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimDocuments(claimId);
      return response.data;
    },
  });

  useEffect(() => {
    if (claimDocumentsQuery.status === "success") {
      setDocumentList(claimDocumentsQuery.data);
    }
  }, [claimDocumentsQuery.data, claimDocumentsQuery.status, setDocumentList]);

  useEffect(() => {
    setDocumentListRefetch(claimDocumentsQuery.refetch);
  }, [claimDocumentsQuery.refetch]);

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ padding: 2, width: selectedDocument ? "98vw" : undefined, height: "95%", display: "flex", flexDirection: "column" }}>
        {claimDocumentsQuery.isLoading || claimDocumentsQuery.isRefetching ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        ) : claimDocumentsQuery.error ? (
          <>error retrieving claim documents</>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: "auto",
            }}
          >
            {/* Header with close button */}
            <Stack direction="row" justifyContent="space-between" paddingBottom={1}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Documents</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Stack>
            <DocumentTypeActionsHeader />
            <DocumentNavigator />
          </Box>
        )}
      </Card>
    </Modal>
  );
}

export { ClaimDocumentViewerModal };
