import { useEffect, useState } from "react";
import { ClaimsApi } from "../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { LineItem_ClaimsDbModel, TypeSpecificAttachmentDef_ClaimsDbModel } from "@safelease/service-utilities";

export interface DocumentSelectorMenuItems {
  typeSpecificAttachmentDefs: TypeSpecificAttachmentDef_ClaimsDbModel[];
  lineItems: LineItem_ClaimsDbModel[];
}
// This hook fetches the document types that are dynamic to a specific claim
function useClaimSpecificDocumentSelectorMenuItems(claimId: string) {
  const [documentSelectorMenuItems, setDocumentSelectorMenuItems] = useState<DocumentSelectorMenuItems | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const createDocumentSelectorMenuItems = async () => {
      setLoading(true);
      try {
        const typeSpecificAttachmentDefsResponse = await ClaimsApi.getTypeSpecificAttachmentDefs(claimId);
        const typeSpecificAttachmentDefs = typeSpecificAttachmentDefsResponse.data;
        const lineItemsResponse = await ClaimsApi.getLineItemsForClaim(claimId);
        const lineItems = lineItemsResponse.data;
        setDocumentSelectorMenuItems({
          typeSpecificAttachmentDefs,
          lineItems,
        });
      } catch (error) {
        enqueueSnackbar("Error fetching document types, please try again.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    createDocumentSelectorMenuItems();
  }, [claimId]);

  return { documentSelectorMenuItems, loading };
}

export { useClaimSpecificDocumentSelectorMenuItems };
