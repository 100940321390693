import { Box, Stack } from "@mui/material";
import { SafeLeaseBreadcrumbs } from "@safelease/components";
import { useNavigate } from "react-router";
import { SignUpForm } from "./SignUpForm";

type CreateNewUserProps = {};

function CreateNewUser({}: CreateNewUserProps) {
  const navigate = useNavigate();
  return (
    <Stack sx={{ flex: 1 }} spacing={5}>
      <SafeLeaseBreadcrumbs
        navigate={navigate}
        path={[
          { part: "Home", href: "/" },
          { part: "User management", href: "/internal-user-management" },
          { part: "Create new user", href: "/internal-user-management/create-new-user" },
        ]}
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%", maxWidth: 1000 }}>
          <SignUpForm />
        </Box>
      </Box>
    </Stack>
  );
}

export { CreateNewUser };
