// UI - libs
import { Button, IconButton, List, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useSnackbar } from "notistack";

// UI - internal
import { primaryColorButton } from "../../../../utils/styles/mui-overrides";

// Hooks & State
import { useJobsErrorCenterState } from "../../useJobsErrorCenterStore";
import LoadingButton from "@mui/lab/LoadingButton";
import JobDetailsDrawerListItem from "./JobDetailsDrawerListItem";
import { SubmitHandler, useForm } from "react-hook-form";

// Data
import { JobErrorCenterApi } from "../../../../utils/apiInstances/JobErrorCenterApiInstance";

import _ from "lodash";

interface IFormInput {}

interface JobsErrorDetailsDrawerSectionProperties {
  label: string; // human readable label
  jobErrorKey: string; // property name on JobError
  renderer?: (value: any, sx?: SxProps) => JSX.Element | string; // conditional render (for chips, bold, etc)
  editable?: boolean; // is this section editable
  asDropdown?: boolean;
  options?: { value: string; label: string }[];
}

interface JobsErrorDetailsDrawerSectionProps {
  label: string;
  properties: JobsErrorDetailsDrawerSectionProperties[];
  editable?: boolean;
  fetchJobErrors: () => void;
}

export default function JobsErrorDetailsDrawerSection({
  label,
  properties,
  editable = false,
  fetchJobErrors,
}: JobsErrorDetailsDrawerSectionProps) {
  const { selectedJobError, editingSection, setEditingSection, submittingForm, setSubmittingForm } = useJobsErrorCenterState();

  // If there is no selected job error, reset the form to defualt values
  const formDefaultValues = selectedJobError
    ? {
        defaultValues: Object.fromEntries(
          properties.map(({ jobErrorKey }) => {
            // @ts-ignore
            return [jobErrorKey, selectedJobError[jobErrorKey]];
          }),
        ),
      }
    : {};

  const { handleSubmit, control } = useForm(formDefaultValues);

  const { enqueueSnackbar } = useSnackbar();

  const isEditingSection = editingSection === label;
  // On click of edit button, let all sections know this one is being edited
  const handleBeginEditingSection = () => {
    setEditingSection(label);
  };

  // Save section updates to database // TODO: SA-3948
  const handleSaveSection: SubmitHandler<IFormInput> = async (values: any) => {
    setSubmittingForm(true);

    // Fields edited in this section
    const relevantFields = properties.map(({ jobErrorKey }) => jobErrorKey);

    // Pull out only fields with changes
    const alteredFields = _.reduce(
      selectedJobError,
      (acc: string[], value: any, key: string) => (relevantFields.includes(key) && value !== values[key] ? acc.concat(key) : acc),
      [],
    );

    // Recreate uploadable object format with altered fields and their values
    const uploadableChanges = Object.fromEntries(alteredFields.map((key: string) => [key, values[key]]));

    try {
      if (selectedJobError) {
        // Update
        await JobErrorCenterApi.updateJob({
          ...uploadableChanges,
          jobId: selectedJobError.id,
        });

        // Refresh table
        await fetchJobErrors();
        enqueueSnackbar("Job updated successfully", { variant: "success" });
      }

      setEditingSection(null);
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setSubmittingForm(false);
    }
  };

  if (!selectedJobError) return null;

  return (
    <form onSubmit={handleSubmit(handleSaveSection)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {label}
        </Typography>
        {editable && !isEditingSection && (
          <Tooltip title="Edit section" arrow placement="left">
            <IconButton size="small" onClick={handleBeginEditingSection} disabled={!!editingSection && editingSection !== label}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {editable && isEditingSection && (
          <Stack direction="row" spacing={1}>
            <Button variant="text" sx={{ textTransform: "none" }} disabled={submittingForm} onClick={() => setEditingSection(null)}>
              Cancel
            </Button>
            <LoadingButton
              size="small"
              sx={{ ...primaryColorButton, height: 34 }}
              type="submit"
              loading={submittingForm}
              disabled={submittingForm}
            >
              Save
            </LoadingButton>
          </Stack>
        )}
      </Stack>
      <List>
        {properties.map(({ ...rest }, i) => (
          <JobDetailsDrawerListItem key={i} isEditingSection={isEditingSection} control={control} {...rest} />
        ))}
      </List>
    </form>
  );
}
