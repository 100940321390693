import { Stack } from "@mui/material";
import { Note } from "./Note";
import { AdjusterNote_ClaimsDbModel } from "@safelease/service-utilities";
import { useEffect, useRef, useState } from "react";

type NotesListProps = {
  list: AdjusterNote_ClaimsDbModel[];
};

function NotesList({ list }: NotesListProps) {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  // State to track if it's the first render
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        if (messagesEndRef.current) {
          if (isInitialRender) {
            // For initial render, jump to bottom without animation
            messagesEndRef.current.scrollIntoView(false);
            setIsInitialRender(false); // Mark initial render as complete
          } else {
            // For subsequent renders, use smooth scrolling
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 100); // Delaying the scroll a bit
    };
    scrollToBottom();
  }, [list, isInitialRender]); // Dependency array includes list, so effect runs on list update
  return (
    <Stack
      sx={{
        flex: 1,
        overflow: "auto",
        wordWrap: "break-word",
        padding: "10px",
      }}
      spacing={2}
    >
      {list?.map((note) => <Note key={note.id} data={note} />)}
      <div ref={messagesEndRef} />
    </Stack>
  );
}

export { NotesList };
