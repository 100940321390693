import { ProcessAssignmentNotification, ProcessCompletedNotification } from ".";
import { NotificationType } from "../../types/NotificationType.enum";

interface NotificationGeneratorProps {
  notification: SLNotification;
}

export function NotificationGenerator({ notification }: NotificationGeneratorProps) {
  /* -------------------------------------------- */
  /* Process notifications                        */
  /* -------------------------------------------- */
  if (notification.notificationType === NotificationType.PROCESS_ASSIGNED && notification.process)
    return <ProcessAssignmentNotification process={notification.process} />;

  if (notification.notificationType === NotificationType.PROCESS_COMPLETED && notification.process)
    return <ProcessCompletedNotification process={notification.process} />;

  return null;
}
