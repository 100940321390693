import { GridColDef } from "@mui/x-data-grid-premium";

export interface ProspectColumnDef {
  field: string;
  headerName: string;
  flex: number;
}

export const columnDefs: GridColDef<ProspectColumnDef>[] = [
  {
    field: "placesName",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "placesAddress",
    headerName: "Places Address",
    flex: 1,
  },
  {
    field: "sfdcAddress",
    headerName: "Salesforce Address",
    flex: 1,
  },
  {
    field: "coreFms",
    headerName: "FMS",
    flex: 1,
  },
  {
    field: "placesRating",
    headerName: "Places Rating",
    flex: 1,
  },
];
