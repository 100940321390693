import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Stack, Theme } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { useNavigate } from "react-router";
import { HeaderCard } from "./HeaderCard";
import { BodyCard } from "./BodyCard";

type SelectedLegacyClaimPageProps = {};

export function SelectedLegacyClaimPage({}: SelectedLegacyClaimPageProps) {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        display: "flex",
        flex: 1,
        overflow: "auto",
      }}
      spacing={1}
    >
      <Box>
        <SafeLeaseButton
          size="small"
          onClick={() => {
            navigate("/claims");
          }}
          startIcon={<KeyboardArrowLeft />}
          sx={{ color: (theme: Theme) => theme.palette.grey.A200 }}
        >
          Back to claims dashboard
        </SafeLeaseButton>
      </Box>
      <Stack
        sx={{
          display: "flex",
          flex: 1,
          overflow: "auto",
        }}
        spacing={2}
      >
        <HeaderCard />
        <BodyCard />
      </Stack>
    </Stack>
  );
}
