import { TextField, TextFieldProps, Theme, alpha } from "@mui/material";
import { forwardRef } from "react";

type SafeLeaseTextFieldProps = TextFieldProps & {};

const SafeLeaseTextField = forwardRef<HTMLInputElement, SafeLeaseTextFieldProps>(({ sx, InputProps = {}, ...props }, ref) => {
  return (
    <TextField
      ref={ref}
      variant="standard"
      sx={{
        bgcolor: (theme: Theme) => alpha(theme.palette.grey[400], 0.1),
        borderRadius: 2,
        ...sx,
      }}
      InputProps={{
        sx: {
          borderRadius: 2,
          fontSize: "inherit",
          fontFamily: "Open Sans !important",
          ...InputProps.sx,
        },
        disableUnderline: true,
        ...InputProps,
      }}
      {...props}
    />
  );
});

export default SafeLeaseTextField;
