import Highcharts, { SeriesSankeyOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSankey from "highcharts/modules/sankey";
import { UnitBreakdownData } from ".";
import { categoryColors } from "./categoryColors";

// Initialize the Sankey module
HighchartsSankey(Highcharts);

interface UnitBreakdownSankeyProps {
  data: UnitBreakdownData;
  highlightKey: string | undefined;
}

// Highlight only the selected node
const highlightOnKeyMatchStyles = (highlightKey: string | undefined, matchKey: string) => ({
  borderWidth: highlightKey === matchKey ? 3 : 0,
  borderColor: highlightKey === matchKey ? "#000000" : undefined,
});

export function UnitBreakdownSankey({ data, highlightKey }: UnitBreakdownSankeyProps) {
  const {
    // // Column 1
    // allUnits,

    // Column 2: Column 1 breaks down into these two
    occupied,
    unoccupied,

    // Column 3: Occupied breaks down into these two
    insurable,
    notInsurable,

    // Column 4: Insurable breaks down into these two:
    enrolledInSafeLeasePolicy,
    notEnrolled,

    // Column 5: Not enolled breaks down into these four
    enrolledInPrivatePolicy,
    eligibleForAutoProtect,
    eligibleForRollover,
    other,
  } = data;

  const sankeyData: Array<[string, string, number]> = [
    ["All Units", "Occupied", occupied],
    ["All Units", "Unoccupied", unoccupied],
    ["Occupied", "Insurable", insurable],
    ["Occupied", "Not Insurable", notInsurable],
    ["Insurable", "Enrolled in SafeLease Policy", enrolledInSafeLeasePolicy],
    ["Insurable", "Not Enrolled SafeLease Policy", notEnrolled],
    ["Not Enrolled SafeLease Policy", "Private Policies", enrolledInPrivatePolicy],
    ["Not Enrolled SafeLease Policy", "AutoProtect Eligible", eligibleForAutoProtect],
    ["Not Enrolled SafeLease Policy", "Rollover Eligible", eligibleForRollover],
    ["Not Enrolled SafeLease Policy", "Other", other],
  ];

  const options: Highcharts.Options = {
    title: {
      text: `Steven's Sankey - Unit Status Breakdown - ${new Date().toLocaleDateString()}`,
    },
    series: [
      {
        type: "sankey",
        name: "Unit Status Breakdown",
        keys: ["from", "to", "weight"],
        data: sankeyData,
        dataLabels: {
          enabled: true,
          format: "{point.to}: {point.weight}", // Format to display the values
          style: {
            fontWeight: "normal",
            color: "black", // Customize color if needed
          },
        },
        nodes: [
          {
            id: "All Units",
            color: categoryColors.allUnits,
            ...highlightOnKeyMatchStyles(highlightKey, "allUnits"),
          },
          {
            id: "Occupied",
            color: categoryColors.occupied,
            ...highlightOnKeyMatchStyles(highlightKey, "occupied"),
          },
          {
            id: "Unoccupied",
            color: categoryColors.unoccupied,
            ...highlightOnKeyMatchStyles(highlightKey, "unoccupied"),
          },
          {
            id: "Insurable",
            color: categoryColors.insurable,
            ...highlightOnKeyMatchStyles(highlightKey, "insurable"),
          },
          {
            id: "Not Insurable",
            color: categoryColors.notInsurable,
            ...highlightOnKeyMatchStyles(highlightKey, "notInsurable"),
          },
          {
            id: "Enrolled in SafeLease Policy",
            color: categoryColors.enrolledInSafeLeasePolicy,
            ...highlightOnKeyMatchStyles(highlightKey, "enrolledInSafeLeasePolicy"),
          },
          {
            id: "Not Enrolled SafeLease Policy",
            color: categoryColors.notEnrolled,
            ...highlightOnKeyMatchStyles(highlightKey, "notEnrolled"),
          },
          {
            id: "Private Policies",
            color: categoryColors.enrolledInPrivatePolicy,
            ...highlightOnKeyMatchStyles(highlightKey, "enrolledInPrivatePolicy"),
          },
          {
            id: "AutoProtect Eligible",
            color: categoryColors.eligibleForAutoProtect,
            ...highlightOnKeyMatchStyles(highlightKey, "eligibleForAutoProtect"),
          },
          {
            id: "Rollover Eligible",
            color: categoryColors.eligibleForRollover,
            ...highlightOnKeyMatchStyles(highlightKey, "eligibleForRollover"),
          },
          {
            id: "Other",
            color: categoryColors.other,
            ...highlightOnKeyMatchStyles(highlightKey, "other"),
          },
        ],
      } as SeriesSankeyOptions,
    ],
    tooltip: {
      pointFormat: "<b>{point.from}</b> → <b>{point.to}</b>: <b>{point.weight}</b>",
    },
    credits: { enabled: false },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
