import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { Card, Modal, Stack, Typography } from "@mui/material";
import UserAttributeEditForm from "./UserAttributeEditForm";
import { SafeLeaseButton } from "@safelease/components";
import { DeleteForever } from "@mui/icons-material";
import { AuthServiceApi } from "../../../../../utils/apiInstances/AuthServiceApiInstance";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

type SelectedUserModalProps = {
  setOpen: (open: boolean) => void;
  user: UserType;
};

function SelectedUserModal({ setOpen, user }: SelectedUserModalProps) {
  const [deleteRequestPending, setDeleteRequestPending] = useState(false);
  const firstName = user.Attributes?.find((attr) => attr.Name === "given_name")?.Value ?? "";
  const lastName = user.Attributes?.find((attr) => attr.Name === "family_name")?.Value ?? "";

  const onDeleteButtonClicked = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this user?");
    if (!confirmed) return;
    setDeleteRequestPending(true);
    const email = user.Attributes?.find((attr) => attr.Name === "email")?.Value ?? null;
    if (!email) return;
    try {
      await AuthServiceApi.deleteTenantUser(email);
      window.location.reload(); // very important. this will cause the page to reload and the user to be updated in the table by causing a refetch of the users list
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Stack padding={2} width={500} spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {firstName + " " + lastName}
            </Typography>
            <SafeLeaseButton
              onClick={onDeleteButtonClicked}
              loading={deleteRequestPending}
              sx={{ height: 30 }}
              startIcon={<DeleteForever />}
              color="error"
              variant="contained"
            >
              Delete user
            </SafeLeaseButton>
          </Stack>
          <UserAttributeEditForm user={user} setOpen={setOpen} />
        </Stack>
      </Card>
    </Modal>
  );
}

export { SelectedUserModal };
