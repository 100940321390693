import { Box, CircularProgress, Stack, Theme } from "@mui/material";
import { useSelectedClaimPageStore } from "./useSelectedClaimPageStore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { HeaderCard } from "./HeaderCard";
import { BodyCard } from "./BodyCard";
import { SafeLeaseButton } from "@safelease/components";
import { useQuery } from "@tanstack/react-query";
import { Claim_ClaimsDbModel } from "@safelease/service-utilities";
import { useEffect } from "react";
import { DetailsDrawer } from "./DetailsDrawer";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { useNavigate, useParams } from "react-router";
import { useClaimDocumentViewerModalStore } from "./BodyCard/MetricsAndActionsCard/ClaimDocumentViewerModal/useClaimDocumentViewerModalStore";

type SelectedClaimPageProps = {};

function SelectedClaimPage({}: SelectedClaimPageProps) {
  const navigate = useNavigate();
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const { setClaim, setRefetchClaim, cleanUp } = useSelectedClaimPageStore((state) => ({
    setClaim: state.setClaim,
    setRefetchClaim: state.setRefetchClaim,
    cleanUp: state.cleanUp,
  }));
  const setSelectedDocument = useClaimDocumentViewerModalStore((state) => state.setSelectedDocument);

  const selectedClaimQuery = useQuery<Claim_ClaimsDbModel>({
    queryKey: ["getClaim", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimById(claimId);
      return response.data;
    },
  });

  useEffect(() => {
    if (selectedClaimQuery.status === "success") {
      setRefetchClaim(selectedClaimQuery.refetch);
      setClaim(selectedClaimQuery.data);
    }
    return () => {
      cleanUp();
    };
  }, [selectedClaimQuery.data, selectedClaimQuery.status, cleanUp, setClaim, setRefetchClaim, selectedClaimQuery.refetch]);

  if (selectedClaimQuery.isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (selectedClaimQuery.error) {
    return "Error";
  }

  return (
    <Stack
      sx={{
        display: "flex",
        flex: 1,
        overflow: "auto",
      }}
      spacing={1}
    >
      <Box>
        <SafeLeaseButton
          size="small"
          onClick={() => {
            navigate("/claims");
            setSelectedDocument(null); // Avoid a selected document from showing up on the wrong claim.
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          sx={{ color: (theme: Theme) => theme.palette.grey.A200 }}
        >
          Back to claims dashboard
        </SafeLeaseButton>
      </Box>
      <Stack
        sx={{
          display: "flex",
          flex: 1,
          overflow: "auto",
        }}
        spacing={2}
      >
        <HeaderCard />
        <BodyCard />
      </Stack>
      <DetailsDrawer />
    </Stack>
  );
}

export { SelectedClaimPage };
