import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import { LineItem_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance.ts";
import React, { useState } from "react";
import { useSelectedClaimPageStore } from "../useSelectedClaimPageStore.ts";
import { enqueueSnackbar } from "notistack";

type LineItemOptionsProps = {
  lineItem: LineItem_ClaimsDbModel;
};

function LineItemOptions({ lineItem }: LineItemOptionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const archiveLineItem = async (lineItemId: string) => {
    setIsLoading(true);
    handleClose();
    try {
      const response = await ClaimsApi.archiveLineItem(lineItemId);
      if (response.status === 204) {
        await refetchClaim();
      }
    } catch (error) {
      enqueueSnackbar("Error archiving line item", {
        variant: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress size={28} />
      ) : (
        <>
          <IconButton
            size="small"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ color: "grey" }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem key={lineItem.id} onClick={() => archiveLineItem(lineItem.id)}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </>
  );
}

export { LineItemOptions };
