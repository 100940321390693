import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { appConfig } from "./config/app.config.ts";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: appConfig.aws.cognitoAppClientId,
      userPoolId: appConfig.aws.cognitoUserPoolId,
    },
  },
});

if (appConfig.environment !== "development") {
  Sentry.init({
    environment: appConfig.environment,
    dsn: "https://8e50a131458cebc7187c60eddf45d33e@o1109446.ingest.us.sentry.io/4507420992143360",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true, // This will mask all text in Sentry
        maskAllInputs: true, // This will mask all inputs in Sentry
        blockAllMedia: true, // This will block images from loading so they don't get sent to Sentry
      }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysOnErrorSampleRate: ["production", "demo"].includes(appConfig.environment) ? 1.0 : 0.0, // Capture 100% of the errors in production and demo
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
    </LocalizationProvider>
  </React.StrictMode>,
);
