import { Box, CircularProgress, IconButton, Stack, Theme, Typography } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { TenantActionMessage } from "./TenantActionMessage";
import dayjs from "dayjs";
import { SafeLeaseAvatarWithText } from "@safelease/components";
import { Delete } from "@mui/icons-material";
import { useState } from "react";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { captureException } from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { useTenantCommunicationDrawerStore } from "../useTenantCommunicationDrawerStore";
import { useAuthStore, UserAttributes } from "../../../../../../../hooks/useAuthStore";

type MessageProps = {
  data: GetClaimMessageResponse;
};

function checkIfCurrentUserisAuthor(messageData: GetClaimMessageResponse, userAttributes: UserAttributes | null) {
  //TODO: modify this function to utilize a different attribute like subId or email, currently lacking that data in the api response
  return messageData.userFirstName === userAttributes?.given_name && messageData.userLastName === userAttributes?.family_name;
}

function getTimeSinceMessageCreatedInHours(messageData: GetClaimMessageResponse) {
  // calculate the time difference between the current time and the time the message was created
  const currentTime = dayjs();
  const messageCreationTime = dayjs(messageData.createdAt);
  const timeDiffInHours = currentTime.diff(messageCreationTime, "hour", true);
  return timeDiffInHours;
}

function Message({ data }: MessageProps) {
  const [isDeleteRequestPending, setIsDeleteRequestPending] = useState(false);
  const timeDiffInHours = getTimeSinceMessageCreatedInHours(data);
  const userAttributes = useAuthStore((state) => state.userAttributes);
  const isAuthorOfMessage = checkIfCurrentUserisAuthor(data, userAttributes);
  // show delete button if the message is less than an hour old and the user is the author of the message
  const showDeleteButton = isAuthorOfMessage && timeDiffInHours < 1;

  const refetchMessages = useTenantCommunicationDrawerStore((state) => state.refetchMessages);

  const handleDeleteMessage = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this message?");
    if (!confirmed) return;
    setIsDeleteRequestPending(true);
    try {
      await ClaimsApi.archiveClaimMessage(data.id);
      await refetchMessages();
    } catch (error: any) {
      captureException(error);
      enqueueSnackbar("Error deleting message, please try again.", { variant: "error" });
    } finally {
      setIsDeleteRequestPending(false);
    }
  };

  return (
    <Stack spacing={1.5}>
      {/* Message header section */}
      <Stack direction="row" alignItems="center">
        <Box sx={{ flex: 1 }}>
          <SafeLeaseAvatarWithText
            text={`${data.userFirstName} ${data.userLastName}`}
            formattedDate={dayjs(data.createdAt).format("MM/DD/YY")}
          />
        </Box>
        {showDeleteButton && (
          <>
            {isDeleteRequestPending ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleDeleteMessage}>
                <Delete fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Stack>
      {/* Display a different type of Text wether its a normal message or a document upload request message */}
      {data.tenantAction ? (
        <TenantActionMessage data={data} />
      ) : (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: (theme: Theme) => theme.palette.grey.A200,
            whiteSpace: "pre-wrap", // make sure new lines are displayed
            overflowWrap: "break-word",
          }}
        >
          {data.content}
        </Typography>
      )}
    </Stack>
  );
}

export { Message };
