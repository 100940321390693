import { Card, Modal } from "@mui/material";
import { GetPolicyDataForClaimResponse, Policy } from "@safelease/service-utilities";
import { useState } from "react";
import { SelectPolicy } from "./SelectPolicy";
import { CreateManualPolicy } from "./CreateManualPolicy";
import { UseQueryResult } from "@tanstack/react-query";

type PolicySelectionModalProps = {
  allPolicies: Policy[];
  existingSelectedPolicy: Policy | null;
  setModalOpen: (open: boolean) => void;
  policyDataForClaimQuery: UseQueryResult<GetPolicyDataForClaimResponse, Error>;
};

function PolicySelectionModal({ allPolicies, existingSelectedPolicy, setModalOpen, policyDataForClaimQuery }: PolicySelectionModalProps) {
  const [modalState, setModalState] = useState<"select" | "create">("select");

  return (
    <Modal open={true} onClose={() => setModalOpen(false)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card sx={{ padding: 2, display: "flex", flexDirection: "column", maxHeight: "80vh" }}>
        {modalState === "select" ? (
          <SelectPolicy
            allPolicies={allPolicies}
            existingSelectedPolicy={existingSelectedPolicy}
            setModalOpen={setModalOpen}
            setModalState={setModalState}
            policyDataForClaimQuery={policyDataForClaimQuery}
          />
        ) : (
          <CreateManualPolicy setModalState={setModalState} policyDataForClaimQuery={policyDataForClaimQuery} setModalOpen={setModalOpen} />
        )}
      </Card>
    </Modal>
  );
}

export { PolicySelectionModal };
