import dayjs from "dayjs";

export function validatePolicyDatesForLossDate(
  startDateString?: string | null,
  cancelDateString?: string | null,
  lossDateString?: string | null,
) {
  const claimLossDate = lossDateString ? dayjs(lossDateString) : null;
  const startDate = startDateString ? dayjs(startDateString) : null;
  const cancelDate = cancelDateString ? dayjs(cancelDateString) : null;

  if (!claimLossDate) {
    return false;
  }
  if (startDate && cancelDate) {
    return (
      claimLossDate.isSame(startDate) ||
      claimLossDate.isSame(cancelDate) ||
      (claimLossDate.isAfter(startDate) && claimLossDate.isBefore(cancelDate))
    );
  } else if (startDate) {
    return claimLossDate.isSame(startDate) || claimLossDate.isAfter(startDate);
  } else if (cancelDate) {
    return claimLossDate.isSame(cancelDate) || claimLossDate.isBefore(cancelDate);
  }
  return false;
}
