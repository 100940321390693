import { Skeleton, SxProps } from "@mui/material";
import { GridFilterItem } from "@mui/x-data-grid-premium";
import { Metric, MetricGroup, SafeLeaseCard } from "@safelease/components";
import { ActiveClaimsStatistics } from "@safelease/service-utilities";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { useClaimsNavigatorStore } from "./useClaimsNavigatorStore";

type ActiveClaimsSummarizedMetricsCardProps = {};

function ActiveClaimsSummarizedMetricsCard({}: ActiveClaimsSummarizedMetricsCardProps) {
  const [statusFilterList, setStatusFilterList] = useState<ClaimStatus[]>([]);

  const dataGridApiRef = useClaimsNavigatorStore((state) => state.dataGridApiRef);

  const activeClaimsStatisticsQuery = useQuery<ActiveClaimsStatistics>({
    queryKey: [`getActiveClaimsStatistics`],
    queryFn: async () => {
      const response = await ClaimsApi.getActiveClaimsStatistics();
      return response.data;
    },
  });

  /**
   * Update the status filter in the data grid when the statusFilterList changes
   * Every time we click the Metric component, we add or remove the corresponding status to the statusFilterList
   */
  useEffect(() => {
    if (!dataGridApiRef?.current?.deleteFilterItem || !dataGridApiRef.current?.setFilterModel) return; // The dataGridRef is not ready yet since the component is still loading

    const filterItemForStatus: GridFilterItem = {
      id: "status",
      value: statusFilterList,
      field: "status",
      operator: "isAnyOf",
    };

    // clear the filter item and then add it back with the updated value of statusFilterList
    dataGridApiRef.current.deleteFilterItem(filterItemForStatus);
    dataGridApiRef.current.upsertFilterItem(filterItemForStatus);
  }, [dataGridApiRef, statusFilterList]);
  /**
   * Function to handle the click event on a Metric component
   * The list of statuses to filter is updated based on the Metric component clicked
   * some of them may call this function multiple times for a single click event
   */
  const handleMetricClick = (status: ClaimStatus) => {
    setStatusFilterList((prev) => {
      if (prev.includes(status)) {
        return prev.filter((filteredStatus) => filteredStatus !== status);
      } else {
        return [...prev, status];
      }
    });
  };

  // Styling for the Metric component so that we only define it once and not repeat code for each Metric component
  const MetricContainerSxProps = (statuses: ClaimStatus[]) => {
    const highlightedStatus: boolean = statuses.every((status) => statusFilterList.includes(status));
    const sxProp: SxProps = {
      borderRadius: 1,
      padding: 1,
      backgroundColor: highlightedStatus ? "rgba(39, 121, 251, 0.2)" : null,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(39, 121, 251, 0.2)",
      },
    };
    return sxProp;
  };

  if (activeClaimsStatisticsQuery.isLoading || !activeClaimsStatisticsQuery.data)
    return <Skeleton variant="rounded" width={"100%"} height={90} />;

  return (
    <SafeLeaseCard sx={{ padding: 1, display: "flex", height: 90 }}>
      <MetricGroup>
        <Metric
          containerProps={{
            onClick: () => handleMetricClick(ClaimStatus.NewClaim),
            sx: MetricContainerSxProps([ClaimStatus.NewClaim]),
          }}
          value={activeClaimsStatisticsQuery.data.newClaimCount}
          label="New claims"
          tooltip="New claims"
        />
        <Metric
          containerProps={{
            onClick: () => handleMetricClick(ClaimStatus.NeedsReview),
            sx: MetricContainerSxProps([ClaimStatus.NeedsReview]),
          }}
          value={activeClaimsStatisticsQuery.data.needsReviewCount}
          label="Adjuster review"
          tooltip="Adjuster review"
        />
        <Metric
          containerProps={{
            onClick: () => handleMetricClick(ClaimStatus.NeedsApproval),
            sx: MetricContainerSxProps([ClaimStatus.NeedsApproval]),
          }}
          value={activeClaimsStatisticsQuery.data.needsApprovalCount}
          label="Needs approval"
          tooltip="Needs approval"
        />
        <Metric
          containerProps={{
            onClick: () => handleMetricClick(ClaimStatus.TenantActionNeeded),
            sx: MetricContainerSxProps([ClaimStatus.TenantActionNeeded]),
          }}
          value={activeClaimsStatisticsQuery.data.tenantActionNeededCount}
          label="Waiting for tenant"
          tooltip="Waiting for tenant"
        />
        <Metric
          containerProps={{
            onClick: () => handleMetricClick(ClaimStatus.PendingSettlement),
            sx: MetricContainerSxProps([ClaimStatus.PendingSettlement]),
          }}
          value={activeClaimsStatisticsQuery.data.pendingSettlementCount}
          label="Pending settlement"
          tooltip="Pending settlement"
        />
        <Metric value={activeClaimsStatisticsQuery.data.activeClaimCount} label="Active claims" tooltip="active claims" />
      </MetricGroup>
    </SafeLeaseCard>
  );
}

export { ActiveClaimsSummarizedMetricsCard };
