import { create } from "zustand";

export enum LegacyClaimDetailsDrawerType {
  NOTES = "notes",
  TENANT_MESSAGING = "tenantMessaging",
  CLAIM_FORM_DETAILS = "claimFormDetails",
}

type LegacyClaimDetailsDrawerStore = {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  currentDrawerType: LegacyClaimDetailsDrawerType | null;
  setCurrentDrawerType: (drawerType: LegacyClaimDetailsDrawerType | null) => void;
  closeDrawer: () => void; // close the drawer and reset the store state to a default initial state.
};

export const useLegacyClaimDetailsDrawerStore = create<LegacyClaimDetailsDrawerStore>()((set) => ({
  drawerOpen: false,
  setDrawerOpen: (open) => {
    set({ drawerOpen: open });
  },
  currentDrawerType: null,
  setCurrentDrawerType: (drawerType) => {
    set({ currentDrawerType: drawerType });
  },
  closeDrawer: () => {
    set({
      drawerOpen: false,
      currentDrawerType: null,
    });
  },
}));
