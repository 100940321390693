import { Box, Card, Divider, IconButton, Modal, Stack, Tooltip, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseTextField } from "@safelease/components";
import { useEffect, useState } from "react";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
//eslint-disable-next-line
import _ from "lodash";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { generateBaseTextForOfferMessage } from "./helpers";
import axios from "axios";
import { FileUploadSection } from "./FileUploadSection";

type SendOfferConfirmationModalProps = {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
};

function SendOfferConfirmationModal({ modalOpen, setModalOpen }: SendOfferConfirmationModalProps) {
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const [offerMessage, setOfferMessage] = useState<string>("");

  const offerAmount = claim?.adjusterValue || 0;

  /**
   * handle file uploads
   */
  const [uploadedFiles, setUploadedFiles] = useState<{ file: File; previewUrl: string }[]>([]);

  const handleNewFiles = (files: File[]) => {
    // revoke the object url of the previous file
    for (const file of uploadedFiles) {
      if (file.previewUrl) {
        URL.revokeObjectURL(file.previewUrl);
      }
    }
    //only allow one file to be uploaded
    setUploadedFiles([{ file: files[0], previewUrl: URL.createObjectURL(files[0]) }]);
  };

  // disable the send offer button if there is no offer message or no uploaded files
  const sendOfferButtonDisabled = !offerMessage.trim();

  const handleClose = () => {
    // This is to avoid memory leaks when we set the uploadedFiles to an empty array
    for (const file of uploadedFiles) {
      if (file.previewUrl) {
        URL.revokeObjectURL(file.previewUrl);
      }
    }
    setUploadedFiles([]);
    setModalOpen(false);
  };

  useEffect(() => {
    if (claim) {
      setOfferMessage(generateBaseTextForOfferMessage(claim));
    }
  }, [claim]);

  const sendOfferButtonPressed = async () => {
    // The button that calls this function will be disabled if there is no uploaded file or if the offer message is empty
    setRequestLoading(true);
    try {
      let newAttachment = null; // is used to store the new attachment if we created one.
      if (uploadedFiles.length) {
        const file = uploadedFiles[0]; // this list should only ever have one file
        const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.file.name, file.file.type);
        const { attachment, presignedUrl } = response.data;
        newAttachment = attachment;
        // upload the file to s3
        await axios.put(presignedUrl, file.file, {
          headers: {
            "Content-Type": file.file.type,
          },
        });
      }
      // create the settlement offer
      const settlementOfferPostResponse = await ClaimsApi.createSettlementOffer(claim!.id!, {
        estimateAttachmentId: newAttachment ? newAttachment.id : null,
        message: offerMessage,
        amount: offerAmount,
      });
      // create the tenant action
      const settlementOfferId = settlementOfferPostResponse.data.id;
      const tenantActionPostResponse = await ClaimsApi.createTenantAction({
        type: TenantActionTypes.SETTLEMENT_OFFER,
        claimId: claim?.id as string,
        metadata: {
          [TenantActionTypes.SETTLEMENT_OFFER]: {
            settlementOfferId,
          },
        },
      });
      // create the claim message which will have the tenant action linked to it
      const tenantActionId = tenantActionPostResponse.data.id;
      await ClaimsApi.createClaimMessage({
        claimId: claim?.id as string,
        tenantActionId,
      });
      await refetchClaim();
      setRequestLoading(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Error creating and sending settlement offer, please try again.", {
        variant: "error",
      });
    }
    handleClose();
  };
  return (
    <Modal
      onClose={handleClose}
      open={modalOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ padding: 2, minWidth: 500 }}>
        {/* Header Section */}
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%", paddingBottom: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 20 }}>Sending Offer</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        {/* Icon Section  */}
        <Stack direction="row" spacing={1} divider={<Divider flexItem orientation="vertical" />} paddingY={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocalOfferOutlinedIcon sx={{ fontSize: 22, color: "blue.main" }} />
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>{formatCurrency(offerAmount / 100)}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <PermIdentityOutlinedIcon sx={{ fontSize: 22, color: "blue.main" }} />
            <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
              {claim?.claimantNameOverride ??
                claim?.unit?.cognitoUserDetail?.userFirstName + " " + claim?.unit?.cognitoUserDetail?.userLastName}
            </Typography>
          </Stack>
        </Stack>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Offer message</Typography>
        {/* Text input section */}
        <Box sx={{ paddingBottom: 2 }}>
          <SafeLeaseTextField value={offerMessage} onChange={(e) => setOfferMessage(e.target.value)} multiline rows={8} />
        </Box>
        <Stack spacing={1}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Supporting documentation</Typography>
          <FileUploadSection files={uploadedFiles} handleNewFiles={handleNewFiles} />
        </Stack>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Stack direction="row" spacing={2} paddingTop={3}>
            <SafeLeaseButton onClick={handleClose} variant="outlined" color="navy">
              Cancel
            </SafeLeaseButton>
            <Tooltip title={sendOfferButtonDisabled ? "An offer must have a message and a supporting document before you can send it" : ""}>
              <Box>
                <SafeLeaseButton
                  disabled={sendOfferButtonDisabled}
                  onClick={sendOfferButtonPressed}
                  loading={requestLoading}
                  variant="contained"
                >
                  Send offer
                </SafeLeaseButton>
              </Box>
            </Tooltip>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
}

export { SendOfferConfirmationModal };
