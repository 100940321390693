import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useClaimDetailsDrawerStore } from "../useClaimDetailsDrawerStore";
import { useLegacyClaimDetailsDrawerStore } from "../../../SelectedLegacyClaimPage/BodyCard/LegacyClaimDetailsDrawer/useLegacyClaimDetailsDrawer";

type DrawerHeaderProps = {
  title: string;
  subTitle?: string;
  legacyClaim?: boolean;
};

function DrawerHeader({ title, subTitle, legacyClaim }: DrawerHeaderProps) {
  const closeDrawer = useClaimDetailsDrawerStore((state) => state.closeDrawer);
  const closeLegacyClaimDrawer = useLegacyClaimDetailsDrawerStore((state) => state.closeDrawer);

  return (
    <Stack>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{title}</Typography>
        <IconButton onClick={legacyClaim ? closeLegacyClaimDrawer : closeDrawer}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography sx={{ fontWeight: 500, fontSize: 12 }}>{subTitle}</Typography>
    </Stack>
  );
}

export { DrawerHeader };
