import { Card, Modal, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { SafeLeaseButton } from "@safelease/components";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";

type DateOfLossSelectionModalProps = {
  setModalOpen: (open: boolean) => void;
};

function DateOfLossSelectionModal({ setModalOpen }: DateOfLossSelectionModalProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [newDateOfLoss, setNewDateOfLoss] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const claim = useSelectedClaimPageStore((state) => state.claim);

  useEffect(() => {
    if (claim?.lossDate) {
      setNewDateOfLoss(dayjs(claim.lossDate));
    }
  }, [claim?.lossDate]);

  const updateDateOfLoss = async () => {
    if (!newDateOfLoss) {
      throw new Error("No date of loss selected and update date of loss button clicked");
    }
    setIsLoading(true);

    try {
      if (newDateOfLoss.format("YYYY-MM-DD") !== claim?.lossDate) {
        await ClaimsApi.updateClaim(claimId, { lossDate: newDateOfLoss.format("YYYY-MM-DD") });
        queryClient.invalidateQueries({ queryKey: ["getClaim", claimId] });
        queryClient.invalidateQueries({ queryKey: ["policyData", claimId] });
        enqueueSnackbar("Date of loss updated", { variant: "success" });
      }
      setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Error updating date of loss", { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={true} onClose={() => setModalOpen(false)} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card sx={{ padding: 2, width: 400 }}>
        <Stack spacing={2}>
          <Typography variant="h6">Update Date of Loss</Typography>
          <DatePicker label="Date of loss" value={newDateOfLoss} onChange={(value) => setNewDateOfLoss(value)} />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <SafeLeaseButton variant="outlined" onClick={() => setModalOpen(false)} color="navy" disabled={isLoading}>
              Cancel
            </SafeLeaseButton>
            <SafeLeaseButton variant="contained" onClick={updateDateOfLoss} disabled={!newDateOfLoss} loading={isLoading}>
              Update
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export { DateOfLossSelectionModal };
