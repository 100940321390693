import { Box, Stack, Theme, Typography } from "@mui/material";
import { GetAdHocTenantActionsResponse } from "@safelease/service-utilities";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import dayjs from "dayjs";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { ImageViewer } from "../../../../../../../components/ImageViewer";
import { SafeLeaseAvatarWithText } from "@safelease/components";

type AdHocRequestProps = {
  adHocRequest: GetAdHocTenantActionsResponse;
};

function AdHocRequest({ adHocRequest }: AdHocRequestProps) {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {adHocRequest.completed ? (
            <CheckCircleOutlineIcon sx={{ color: "rgba(37, 155, 36, 1)" }} />
          ) : (
            <WatchLaterOutlinedIcon sx={{ color: "#EED202" }} />
          )}
          <Typography
            sx={{
              fontSize: 12,
              color: (theme: Theme) => theme.palette.grey.A200,
            }}
          >
            {adHocRequest.completed ? "Tenant responded" : "Pending response"}
          </Typography>
        </Stack>
        <Typography sx={{ fontSize: 12, color: (theme: Theme) => theme.palette.grey.A200 }}>
          {dayjs(adHocRequest.createdAt).format("MM/DD/YY")}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            whiteSpace: "pre-wrap", // make sure new lines are displayed
          }}
        >
          {adHocRequest.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.requestMessage}
        </Typography>
        {adHocRequest.completed && (
          <Stack paddingLeft={4} spacing={1}>
            <SafeLeaseAvatarWithText
              text={`${adHocRequest.recipientCognitoUserDetail?.userFirstName} ${adHocRequest.recipientCognitoUserDetail?.userLastName}`}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
                whiteSpace: "pre-wrap", // make sure new lines are displayed
                overflowWrap: "break-word", // Breaks words to prevent overflow
              }}
            >
              {adHocRequest.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.textResponse}
            </Typography>
            <Stack direction="row" spacing={1} paddingY={1} overflow="auto" sx={{ width: "100%" }}>
              {adHocRequest.uploadedAttachments?.map((attachment) => (
                <Box key={attachment.id}>
                  <ImageViewer
                    fileName={attachment.fileName}
                    file={attachment.url as string}
                    fileSize={attachment.fileSize}
                    archived={!!attachment.deletedAt}
                  />
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export { AdHocRequest };
