import { Create } from "@mui/icons-material";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { Policy } from "@safelease/service-utilities";
import dayjs from "dayjs";

type PolicyListProps = {
  allPolicies: Policy[];
  newSelectedPolicyOption: Policy | null;
  setNewSelectedPolicyOption: (policy: Policy) => void;
  setModalState: (state: "select" | "create") => void;
};

function PolicyList({ allPolicies, newSelectedPolicyOption, setNewSelectedPolicyOption, setModalState }: PolicyListProps) {
  return (
    <Stack spacing={2} overflow="auto" padding={1}>
      {allPolicies.map((policy) => {
        const isSelected = newSelectedPolicyOption?.id === policy.id;
        return (
          <Stack
            key={policy.id}
            onClick={() => setNewSelectedPolicyOption(policy)}
            sx={{
              paddingRight: 2,
              paddingY: 1,
              border: "1px solid #1E2A38",
              borderRadius: 1,
              backgroundColor: isSelected ? "#031E300D" : null,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#031E300D",
              },
            }}
            spacing={1}
          >
            <FormControlLabel
              control={
                <Radio
                  sx={{
                    color: "#1E2A38",
                    "&.Mui-checked": {
                      color: "#1E2A38",
                    },
                  }}
                  checked={isSelected}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <Typography sx={{ fontWeight: 700 }}>
                  {formatCurrency(policy.coverage / 100)} {policy.adjusterCreatedPolicy && " *Created by adjuster*"}
                </Typography>
              }
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingLeft={2} spacing={4}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle2">start date:</Typography>
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                  {policy.startDate ? dayjs(policy.startDate).format("MM/DD/YYYY") : "N/A"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle2"> cancel date:</Typography>
                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                  {policy.cancelDate ? dayjs(policy.cancelDate).format("MM/DD/YYYY") : "N/A"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
      <SafeLeaseButton color="navy" variant="outlined" startIcon={<Create />} onClick={() => setModalState("create")}>
        Manually Add Policy
      </SafeLeaseButton>
    </Stack>
  );
}

export { PolicyList };
