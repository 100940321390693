import { create } from "zustand";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

type ClaimsNavigatorStore = {
  dataGridApiRef: React.MutableRefObject<GridApiPremium> | null;
  setDataGridApiRef: (dataGridApiRef: React.MutableRefObject<GridApiPremium>) => void;
  tabValue: "active" | "closed";
  setTabValue: (tabValue: "active" | "closed") => void;
};

export const useClaimsNavigatorStore = create<ClaimsNavigatorStore>()((set) => ({
  dataGridApiRef: null,
  setDataGridApiRef: (dataGridApiRef: any) => {
    set({ dataGridApiRef });
  },
  tabValue: "active",
  setTabValue: (tabValue: "active" | "closed") => {
    set({ tabValue });
  },
}));
