import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import { useClaimDetailsDrawerStore } from "../useClaimDetailsDrawerStore";
import { DrawerHeader } from "../sharedComponents/DrawerHeader";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import FileDisplaySection from "./FileDisplaySection";
import { LineItem_ClaimsDbModel } from "@safelease/service-utilities";
import { AdjusterValueSection } from "./AdjusterValueSection";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore.ts";
import { useTenantCommunicationDrawerStore } from "../TenantCommunicationDrawer/useTenantCommunicationDrawerStore.ts";
import { RequestUploadModal } from "../../SharedComponents/RequestDocumentationButton/RequestUploadModal.tsx";
import { AdditionalMetadata } from "../../SharedComponents/RequestDocumentationButton";
import { getInitialRequestMessageForTenantActionType } from "../../SharedComponents/RequestDocumentationButton/helpers.ts";
import { useParams } from "react-router";

interface LineItemsDrawerProps {}

export function LineItemsDrawer({}: LineItemsDrawerProps) {
  const claimId = useParams<{ claimId: string }>().claimId;
  if (!claimId) return null;

  const selectedLineItemId = useClaimDetailsDrawerStore((state) => state.selectedLineItemId);
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const refetchMessages = useTenantCommunicationDrawerStore((state) => state.refetchMessages);

  const [requestUploadModalOpen, setRequestUploadModalOpen] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [initialRequestMessage, setInitialRequestMessage] = useState<string>("");
  const [tenantActionType, setTenantActionType] = useState<TenantActionTypes | null>(null);
  const [additionalMetadata, setAdditionalMetadata] = useState<AdditionalMetadata>({});

  const { data, isLoading, error, refetch } = useQuery<LineItem_ClaimsDbModel>({
    queryKey: [`getLineItem-${selectedLineItemId}`],
    queryFn: async () => {
      const response = await ClaimsApi.getLineItem(selectedLineItemId as string);
      return response.data;
    },
  });

  // set the correct state depending on which button was clicked to pre populate the request message and open the request upload modal
  const handleItemClick = (params: { type: TenantActionTypes; additionalMetadata?: AdditionalMetadata }) => {
    setTenantActionType(params.type);
    setInitialRequestMessage(getInitialRequestMessageForTenantActionType(params.type));
    setAdditionalMetadata(params.additionalMetadata ?? {});
    setRequestUploadModalOpen(true);
  };

  const onReUploadClick = async (requestMessage: string) => {
    if (!tenantActionType) return;
    setRequestPending(true);
    const metadata = {
      [tenantActionType]: {
        requestMessage,
        ...additionalMetadata,
      },
    };
    try {
      //Create the Tenant Action for the given type based on which button was clicked
      const response = await ClaimsApi.createTenantAction({
        type: tenantActionType,
        claimId: claimId,
        metadata,
      });
      const tenantActionId = response.data.id;

      // Create the claim message for the adjuster to request the document
      await ClaimsApi.createClaimMessage({
        claimId: claimId,
        tenantActionId,
      });

      // refresh the list of claim messages to show the new message
      refetchMessages();
      // refetch the claim becuase the status of the claim can change if a claim goes from having 0 Tenant Actions to having 1 or more
      // this will update the UI to show the new status of the claim
      refetchClaim();
    } catch (error) {
      enqueueSnackbar("Error requesting document, please try again.", {
        variant: "error",
      });
    } finally {
      setRequestPending(false);
      setRequestUploadModalOpen(false);
    }
  };

  if (isLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }
  if (error || !data) {
    console.log(error);
    return <div>error...</div>;
  }

  return (
    <Stack sx={{ width: 700, height: "100%", padding: "16px" }} spacing={2} direction="column" divider={<Divider flexItem />}>
      <DrawerHeader title={data.name || ""} subTitle={data.requestedAmount ? formatCurrency(data.requestedAmount / 100) : ""} />
      <AdjusterValueSection refetchLineItem={refetch} lineItem={data} />
      <Stack overflow="auto" spacing={4} divider={<Divider flexItem />}>
        <FileDisplaySection
          filesToDisplay={data.proofOfPurchaseFiles?.filter((file) => !file.attachment?.deletedAt) || []}
          sectionHeader="Item receipts"
          onReUploadClick={() =>
            handleItemClick({ type: TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES, additionalMetadata: { lineItemId: data.id } })
          }
        />
        <FileDisplaySection
          filesToDisplay={data.proofOfOwnershipFiles?.filter((file) => !file.attachment?.deletedAt) || []}
          sectionHeader="Proof of Ownership"
          onReUploadClick={() =>
            handleItemClick({ type: TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES, additionalMetadata: { lineItemId: data.id } })
          }
        />
      </Stack>
      {requestUploadModalOpen && (
        <RequestUploadModal
          setOpen={setRequestUploadModalOpen}
          initialRequestMessage={initialRequestMessage}
          onSendButtonClick={onReUploadClick}
          requestPending={requestPending}
        />
      )}
    </Stack>
  );
}
