import { ClaimsNavigatorTableRow } from "@safelease/service-utilities";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { Skeleton } from "@mui/material";
import { ClaimsTable } from "./ClaimsTable";

type ClosedClaimsTableProps = {};

function ClosedClaimsTable({}: ClosedClaimsTableProps) {
  const [combinedClosedClaims, setCombinedClosedClaims] = useState<ClaimsNavigatorTableRow[]>([]);
  const [closedLegacyClaims, setClosedLegacyClaims] = useState<ClaimsNavigatorTableRow[]>([]);
  const [closedRegularClaims, setClosedRegularClaims] = useState<ClaimsNavigatorTableRow[]>([]);
  const [pageForRegularClaims, setPageForRegularClaims] = useState(1);
  const [pageForLegacyClaims, setPageForLegacyClaims] = useState(1);

  const regularClaimsQuery = useQuery({
    queryKey: ["getAllClosedClaimsByPage", pageForRegularClaims],
    queryFn: async () => {
      const response = await ClaimsApi.getAllClosedClaimsByPage(pageForRegularClaims);
      const claimsByPage = response.data;
      if (claimsByPage?.length) {
        setClosedRegularClaims((prev) => [...prev, ...claimsByPage]);
        setPageForRegularClaims(pageForRegularClaims + 1);
      }
      return claimsByPage;
    },
  });

  const legacyClaimsQuery = useQuery({
    queryKey: ["getAllLegacyClaimsByPage", pageForLegacyClaims],
    queryFn: async () => {
      const response = await ClaimsApi.getAllLegacyClaimsByPage(pageForLegacyClaims);
      const legacyClaimsByPage = response.data;
      if (legacyClaimsByPage?.length) {
        setClosedLegacyClaims((prev) => [...prev, ...legacyClaimsByPage]);
        setPageForLegacyClaims(pageForLegacyClaims + 1);
      }
      return legacyClaimsByPage;
    },
  });

  useEffect(() => {
    setCombinedClosedClaims([...closedRegularClaims, ...closedLegacyClaims]);
  }, [closedRegularClaims, closedLegacyClaims]);

  if (
    (regularClaimsQuery.isLoading || regularClaimsQuery.isRefetching) &&
    (legacyClaimsQuery.isLoading || legacyClaimsQuery.isRefetching) &&
    !combinedClosedClaims.length
  ) {
    return <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />;
  }

  return <ClaimsTable claimsList={combinedClosedClaims} />;
}

export { ClosedClaimsTable };
