import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { confirmSignIn } from "aws-amplify/auth";
import { useAuthStore } from "../../hooks/useAuthStore";

interface NewPasswordFormProps {}

interface NewPasswordFormValues {
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
}

const validationSchema = z
  .object({
    firstName: z.string().trim().min(1, "First name is required."),
    lastName: z.string().trim().min(1, "Last name is required."),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long.")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
    passwordConfirm: z.string().min(8, "Matching Password is required."),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords do not match",
    path: ["passwordConfirmation"], // This specifies which field the error is associated with
  });

export function NewPasswordForm({}: NewPasswordFormProps) {
  const theme = useTheme();

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setUserRequiresNewPassword = useAuthStore((state) => state.setUserRequiresNewPassword);

  const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  const methods = useForm<NewPasswordFormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onChangePasswordClicked: SubmitHandler<{
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
  }> = async ({ firstName, lastName, password }) => {
    setIsLoading(true);
    try {
      await confirmSignIn({
        challengeResponse: password,
        options: {
          userAttributes: {
            given_name: firstName.trim(),
            family_name: lastName.trim(),
          },
        },
      });
      setUserRequiresNewPassword(false);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onChangePasswordClicked)}>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={4}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="600" fontSize="14px" sx={{ mb: 0.5 }}>
                  First Name
                </Typography>
                <SafeLeaseFormTextField
                  name="firstName"
                  sx={{ width: "100%", height: "2rem", mt: 0.5 }}
                  type="text"
                  helperText={methods.formState.errors.firstName?.message}
                  error={!!methods.formState.errors.firstName}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="600" fontSize="14px" sx={{ mb: 0.5 }}>
                  Last Name
                </Typography>
                <SafeLeaseFormTextField
                  name="lastName"
                  sx={{ width: "100%", height: "2rem", mt: 0.5 }}
                  type="text"
                  helperText={methods.formState.errors.lastName?.message}
                  error={!!methods.formState.errors.lastName}
                />
              </Box>
            </Stack>
            <Box sx={{ flexGrow: 1 }}>
              <SafeLeaseFormTextField
                name="password"
                sx={{ width: "100%", height: "2rem", bgcolor: "grey.50" }}
                label="Password"
                type={isPasswordVisible ? "text" : "password"}
                helperText={methods.formState.errors.password?.message}
                error={!!methods.formState.errors.password?.message}
                endAdornment={
                  <IconButton
                    onClick={togglePasswordVisibility}
                    size="small"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 5,
                      transform: "translateY(-50%)",
                    }}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <SafeLeaseFormTextField
                name="passwordConfirm"
                sx={{ width: "100%", height: "2rem", bgcolor: "grey.50" }}
                label="Confirm Password"
                type={isPasswordVisible ? "text" : "password"}
                helperText={methods.formState.errors.passwordConfirm?.message}
                error={!!methods.formState.errors.passwordConfirm?.message}
                endAdornment={
                  <IconButton
                    onClick={togglePasswordVisibility}
                    size="small"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 5,
                      transform: "translateY(-50%)",
                    }}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <SafeLeaseButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                loading={isLoading}
                sx={{ fontSize: theme.typography.body2.fontSize }}
              >
                Finish Account Setup
              </SafeLeaseButton>
            </Box>
          </Stack>
        </form>
        <SafeLeaseButton
          startIcon={<ArrowBack />}
          color="navy"
          variant="text"
          sx={{
            mt: 2,
            fontWeight: "initial",
            fontSize: theme.typography.caption.fontSize,
          }}
          onClick={() => setUserRequiresNewPassword(false)}
        >
          Back to Sign In
        </SafeLeaseButton>
      </FormProvider>
    </Box>
  );
}
