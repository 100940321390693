import Grid from "@mui/material/Grid";
import { SafeLeaseTextField } from "@safelease/components";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { ProspectsTable } from "./ProspectsTable.tsx";
import { useQuery } from "@tanstack/react-query";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance.ts";
import { useProspectsFiltersStore } from "../useProspectsFiltersStore.ts";
import { useDebounce } from "../../../hooks/useDebounce.tsx";
import { useState } from "react";

export interface ProspectPaginationModel {
  page: number;
  pageSize: number;
}

export function ProspectDataTab() {
  const searchText = useProspectsFiltersStore((state) => state.searchText);
  const setSearchText = useProspectsFiltersStore((state) => state.setSearchText);
  const debouncedSearchText = useDebounce(searchText, 200);
  const [paginationModel, setPaginationModel] = useState<ProspectPaginationModel>({ page: 0, pageSize: 10 });

  const { data, isLoading } = useQuery({
    queryKey: ["getProspectsWithFilters", { searchText: debouncedSearchText, page: paginationModel.page, limit: paginationModel.pageSize }],
    queryFn: async () => {
      const response = await ProspectsApi.getProspectsWithFilters({
        searchText: debouncedSearchText,
        page: paginationModel.page,
        limit: paginationModel.pageSize,
      });
      return response.data;
    },
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SafeLeaseTextField
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={"Search by address"}
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <ProspectsTable
          prospects={data?.prospects || []}
          totalProspectCount={data?.totalCount || 0}
          isLoading={isLoading || false}
          onPaginationModelChange={setPaginationModel}
          paginationModel={paginationModel}
        />
      </Grid>
    </Grid>
  );
}
