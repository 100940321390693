import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import axios from "axios";

export interface AddDocumentationAdditionalMetadata {
  typeSpecificAttachmentDefId?: string;
  lineItemId?: string;
  lineItemName?: string;
  typeSpecificAttachmentDefName?: string;
}

export const submitTenantActionTypeFileUpload = async (
  files: File[],
  claimId: string,
  tenantActionType: TenantActionTypes,
  additionalMetadata?: AddDocumentationAdditionalMetadata,
) => {
  for (const file of files) {
    const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.name, file.type);
    const { presignedUrl, attachment } = response.data;
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    switch (tenantActionType) {
      case TenantActionTypes.UPLOAD_BILLING_LEDGER:
        await ClaimsApi.createBillingLedgerDoc({
          claimId,
          attachmentId: attachment.id,
        });
        break;
      case TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM:
        await ClaimsApi.createProtectionPlanAddendumDoc({
          claimId,
          attachmentId: attachment.id,
        });
        break;
      case TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES:
        if (additionalMetadata?.lineItemId) {
          await ClaimsApi.createProofOfOwnershipFile(additionalMetadata.lineItemId, attachment.id);
        }
        break;
      case TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES:
        if (additionalMetadata?.lineItemId) {
          await ClaimsApi.createProofOfPurchaseFile(additionalMetadata.lineItemId, attachment.id);
        }
        break;
      case TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT:
        if (additionalMetadata?.typeSpecificAttachmentDefId) {
          await ClaimsApi.createTypeSpecificAttachment({
            claimId: claimId,
            typeSpecificAttachmentDefId: additionalMetadata?.typeSpecificAttachmentDefId,
            attachmentId: attachment.id,
          });
        }
        break;
    }
  }
};

export const submitAdditionalDocumentationTypeFileUpload = async (files: File[], claimId: string, createEmailedFacilityNote?: boolean) => {
  for (const file of files) {
    const response = await ClaimsApi.createAttachmentAndGenerateUploadUrl(file.name, file.type);
    const { presignedUrl, attachment } = response.data;
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    await ClaimsApi.createAdditionalDocument({
      claimId,
      attachmentId: attachment.id,
      createEmailedFacilityNote,
    });
  }
};

export function getTitleForTenantActionType(tenantActionType: TenantActionTypes, additionalMetadata?: AddDocumentationAdditionalMetadata) {
  switch (tenantActionType) {
    case TenantActionTypes.UPLOAD_BILLING_LEDGER:
      return "Billing ledger";
    case TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM:
      return "Protection plan addendum";
    case TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES:
      return `Proof of ownership for the '${additionalMetadata?.lineItemName || ""}' line item.`;
    case TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES:
      return `Proof of purchase for the '${additionalMetadata?.lineItemName || ""}' line item.`;
    case TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT:
      return additionalMetadata?.typeSpecificAttachmentDefName || "";
    default:
      return "";
  }
}
