import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { ProspectDataTab } from "./ProspectDataTab.tsx";
import { ProspectDetailsDrawer } from "./ProspectDetailsDrawer.tsx";
import { useParams } from "react-router";

export default function ProspectsPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const params = useParams();
  const prospectId = params["id"];

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Prospects</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Prospect Data" />
            <Tab label="Map Tool" />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {selectedTab === 0 && <ProspectDataTab />}
        {selectedTab === 1 && <div>Map Tool</div>}
      </Grid>
      {prospectId && <ProspectDetailsDrawer />}
    </Grid>
  );
}
