import { TenantActionTypes } from "@safelease/service-utilities/enums";

export interface InitialRequestMessageMetadata {
  [TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT]: {
    label: string;
    description: string;
  };
}

export const getInitialRequestMessageForTenantActionType = (
  tenantActionType: TenantActionTypes,
  metadata?: InitialRequestMessageMetadata,
) => {
  switch (tenantActionType) {
    case TenantActionTypes.UPLOAD_BILLING_LEDGER:
      return "Please upload a copy of your billing ledger.";
    case TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM:
      return "Please upload a copy of your protection plan addendum.";
    case TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES:
      return "We will need proof that you own the item, this is typically done by showing a picture of the item in your possession in the past, owners manuals, pictures of accessories or other supporting documentation.";
    case TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES:
      return "We will need proof that you own the item, this is typically done by showing credit card statements, invoices, online transactions that prove you are the owner.";
    case TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT:
      return `${metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT].label}. ${metadata?.[TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT].description}`;
    default:
      return "";
  }
};
