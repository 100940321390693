import { Box, CircularProgress, Stack } from "@mui/material";
import { GroupAddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { SafeLeaseBreadcrumbs, SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { AuthServiceApi } from "../../../utils/apiInstances/AuthServiceApiInstance";
import { InternalUsersTable } from "./InternalUsersTable";
import { UserType } from "@aws-sdk/client-cognito-identity-provider";

type UserManagementProps = {};

function InternalUserManagement({}: UserManagementProps) {
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<UserType[]>([]);
  const [paginationToken, setPaginationToken] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(1); // using this to keep the query key unique so that it does follow up api calls when we increment this. Once we have done all the fetches we need we stop increasing the page number
  const [lastPageFetched, setLastPageFetched] = useState<number | undefined>(undefined); // this is to keep track of the last page fetched so that we don't append the same data to the list that is already there. When a user would navigate back to this tab from another it was causing duplicate items to be re added to the list

  const usersQuery = useQuery({
    queryKey: [`getUsers-${page}`],
    queryFn: async () => {
      const response = await AuthServiceApi.getAllInternalUsers(paginationToken);
      if (lastPageFetched !== page) setUsersList((currentUsers) => [...currentUsers, ...response.data.users]);
      setLastPageFetched(page);
      if (response.data.paginationToken) {
        // this will be undefined when we have fetched all the users per aws sdk specs
        setPaginationToken(response.data.paginationToken);
        setPage((page) => page + 1);
      }
      return response.data;
    },
  });

  if ((usersQuery.isLoading || usersQuery.isRefetching) && !usersList.length) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack spacing={2} sx={{ display: "flex", flex: 1, overflow: "auto" }}>
      <SafeLeaseBreadcrumbs
        navigate={navigate}
        path={[
          { part: "Home", href: "/" },
          { part: "Internal user management", href: "/internal-user-management" },
        ]}
      />
      <Box>
        <SafeLeaseButton
          onClick={() => navigate("/internal-user-management/create-new-user")}
          startIcon={<GroupAddOutlined />}
          variant="outlined"
        >
          Create new user
        </SafeLeaseButton>
      </Box>
      <SafeLeaseCard sx={{ flex: 1, overflow: "auto" }}>
        <InternalUsersTable usersList={usersList} />
      </SafeLeaseCard>
    </Stack>
  );
}

export { InternalUserManagement };
