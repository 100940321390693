import { ChevronRightOutlined, DescriptionOutlined } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { Metric, SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { LegacyClaim } from "@safelease/service-utilities";
import { LegacyClaimDetailsDrawerType, useLegacyClaimDetailsDrawerStore } from "./LegacyClaimDetailsDrawer/useLegacyClaimDetailsDrawer";

type MetricsAndActionsCardProps = {
  legacyClaim: LegacyClaim;
};

function MetricsAndActionsCard({ legacyClaim }: MetricsAndActionsCardProps) {
  const { setDrawerOpen, setCurrentDrawerType } = useLegacyClaimDetailsDrawerStore((state) => ({
    setDrawerOpen: state.setDrawerOpen,
    setCurrentDrawerType: state.setCurrentDrawerType,
  }));
  const actionButtonClicked = (drawerType: LegacyClaimDetailsDrawerType) => {
    setCurrentDrawerType(drawerType);
    setDrawerOpen(true);
  };
  return (
    <SafeLeaseCard
      sx={{
        padding: "10px",
        boxShadow: 0,
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          padding: "10px",
          boxShadow: 0,
          overflow: "auto",
        }}
      >
        <Stack direction="row" spacing={3} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
          <Metric type="currency" value={legacyClaim.adjusterValue ? legacyClaim.adjusterValue / 100 : 0} label="Adjuster value" />
          <Metric type="currency" value={legacyClaim.requestedAmount ? legacyClaim.requestedAmount / 100 : 0} label="Requested payout" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(LegacyClaimDetailsDrawerType.NOTES)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<DescriptionOutlined />}
              endIcon={<ChevronRightOutlined />}
            >
              Notes
            </SafeLeaseButton>
          </Box>
          <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(LegacyClaimDetailsDrawerType.CLAIM_FORM_DETAILS)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<DescriptionOutlined />}
              endIcon={<ChevronRightOutlined />}
            >
              Claim form
            </SafeLeaseButton>
          </Box>
          {/* TODO: Uncomment this once tenant messaging is implemented */}
          {/* <Box>
            <SafeLeaseButton
              sx={{ whiteSpace: "nowrap" }}
              onClick={() => actionButtonClicked(LegacyClaimDetailsDrawerType.TENANT_MESSAGING)}
              size="small"
              variant="outlined"
              color="navy"
              startIcon={<ChatBubbleOutline />}
              endIcon={<ChevronRightOutlined />}
            >
              With tenant
            </SafeLeaseButton>
          </Box> */}
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}

export { MetricsAndActionsCard };
