import { Box, Stack, Typography } from "@mui/material";
import { Attachment_ClaimsDbModel } from "@safelease/service-utilities";
import { ImageViewer } from "../../../../../../components/ImageViewer";

type ClaimDocumentDisplaySectionProps = {
  question: string;
  attachments: Attachment_ClaimsDbModel[];
};

function ClaimDocumentDisplaySection({ attachments, question }: ClaimDocumentDisplaySectionProps) {
  return (
    <Stack>
      <Stack>
        <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Question</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{question}</Typography>
      </Stack>
      <Stack paddingLeft={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&::before": {
              content: '"•"',
              display: "inline-block",
              marginRight: "6px",
              fontSize: 20,
            },
          }}
        >
          <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Answer</Typography>
        </Box>
        <Stack sx={{ paddingTop: 1 }} direction="row" overflow="auto" spacing={2}>
          {attachments.map((attachment) => (
            <Box key={attachment.id}>
              <ImageViewer
                file={attachment.url}
                fileName={attachment.fileName}
                fileSize={attachment.fileSize}
                archived={!!attachment.deletedAt}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}

export { ClaimDocumentDisplaySection };
