import { create } from "zustand";
import { PrivatePolicyApi } from "../../../utils/apiInstances/PrivatePolicyApiInstance";
import { useQuery } from "@tanstack/react-query";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";

export enum SectionType {
  TENANT_NAME = "tenantName",
  POLICY_NUMBER = "policyNumber",
  EXPIRATION_DATE = "expirationDate",
}

export enum FieldValueSource {
  TENANT_INPUT = "tenantInput",
  AI_EXTRACTED = "aiExtracted",
  MANUAL_OVERRIDE = "manualOverride",
}

export type ReviewFormState = {
  tenantName: {
    valueSource: string | null;
    value: string | null;
  };
  policyNumber: {
    valueSource: string | null;
    value: string | null;
  };
  expirationDate: {
    valueSource: string | null;
    value: string | null;
  };
};
interface UseComplianceCenterStore {
  // Private policies pagination
  // Returning too many private policies isn't viable due to Lambda response limits
  limit: number;
  setLimit: (limit: number) => void;
  offset: number;
  setOffset: (offset: number) => void;

  privatePolicies: SafeleasePrivatePolicy[];
  setPrivatePolicies: (privatePolicies: SafeleasePrivatePolicy[]) => void;

  // Update one private policy locally
  updatePrivatePolicy: (privatePolicy: SafeleasePrivatePolicy) => void;

  loadingComplete: boolean;
  setLoadingComplete: (loadingComplete: boolean) => void;
}

export const useComplianceCenterStore = create<UseComplianceCenterStore>((set) => ({
  // Private policies pagination
  // Returning too many private policies isn't viable due to Lambda response limits
  limit: 3000,
  setLimit: (limit) => set({ limit }),
  offset: 0,
  setOffset: (offset) => set({ offset }),

  privatePolicies: [],

  // Update one private policy locally
  updatePrivatePolicy: (privatePolicy: SafeleasePrivatePolicy) =>
    set((state) => ({
      privatePolicies: state.privatePolicies.map((p) => (p.id === privatePolicy.id ? privatePolicy : p)),
    })),

  // Append new private policies to the end of the list because we fetch them piecewise
  setPrivatePolicies: (privatePolicies) => {
    return set((state) => {
      const combined = [...state.privatePolicies, ...privatePolicies];
      return {
        privatePolicies: combined,
      };
    });
  },
  loadingComplete: false,
  setLoadingComplete: (loadingComplete) => set({ loadingComplete }),
}));

export const useComplianceCenter = () => {
  const { limit, offset, setLimit, setOffset, privatePolicies, setPrivatePolicies, loadingComplete, setLoadingComplete } =
    useComplianceCenterStore();

  const fetch = async function () {
    if (loadingComplete) return;

    const response = await PrivatePolicyApi.list({ limit, offset });
    const listedPrivatePolicies = response.data.privatePolicies;

    setPrivatePolicies(listedPrivatePolicies as SafeleasePrivatePolicy[]);
    if (listedPrivatePolicies.length === 3000) {
      setOffset(offset + 3000);
    } else {
      setLoadingComplete(true);
    }

    return true;
  };

  useQuery({
    queryKey: ["list-private-policies", offset],
    queryFn: () => fetch(),
  });

  return {
    privatePolicies: privatePolicies,
    limit,
    offset,
    setLimit,
    setOffset,
  };
};
