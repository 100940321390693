import { GetAdHocTenantActionsResponse } from "@safelease/service-utilities";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { create } from "zustand";

type AdHocRequestsDrawerStore = {
  /**
   * This will be tied to the refetch function from react-query for the adHocTenantActionsQuery
   * This will allow us to refetch the ad-hoc requests from any component
   */
  refetchAdHocRequests: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<GetAdHocTenantActionsResponse[], Error> | void>;
  setRefetchAdHocRequests: (
    refetchClaim: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<GetAdHocTenantActionsResponse[], Error> | void>,
  ) => void;
};

export const useAdHocRequestsDrawerStore = create<AdHocRequestsDrawerStore>()((set) => ({
  refetchAdHocRequests: async () => {},
  setRefetchAdHocRequests: (refetchAdHocRequests) => {
    set({ refetchAdHocRequests });
  },
}));
