import Grid from "@mui/material/Grid";
import { StarRateRounded, StarHalfRounded, StarBorderRounded } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

export function FiveStarRating({ rating, count }: { rating: number; count: number }) {
  const theme = useTheme();
  // round rating to closest 0.5 for star display
  const roundedRating = Math.round(rating * 2) / 2;

  const fullStars = Math.floor(roundedRating);
  const halfStars = Math.ceil(roundedRating - fullStars);
  const emptyStars = 5 - fullStars - halfStars;

  const arrayOfFullStars = Array.from({ length: fullStars }, (_, i) => i);
  const arrayOfEmptyStars = Array.from({ length: emptyStars }, (_, i) => i);

  return (
    <Grid container alignItems={"center"}>
      <Grid item paddingRight={1}>
        <Typography>{rating}</Typography>
      </Grid>
      {arrayOfFullStars.length > 0 &&
        arrayOfFullStars.map((num) => (
          <Grid item key={`fullStar-${num}`}>
            <StarRateRounded sx={{ color: theme.palette.yellow.main }} />
          </Grid>
        ))}
      {halfStars > 0 && (
        <Grid item>
          <StarHalfRounded sx={{ color: theme.palette.yellow.main }} />
        </Grid>
      )}
      {arrayOfEmptyStars.length > 0 &&
        arrayOfEmptyStars.map((num) => (
          <Grid item key={`emptyStar-${num}`}>
            <StarBorderRounded sx={{ color: "#FFC107" }} />
          </Grid>
        ))}
      {count && (
        <Grid item paddingLeft={1}>
          <Typography>{`(${count})`}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
