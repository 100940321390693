import { Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";

type UploadMessageProps = {
  data: GetClaimMessageResponse;
  requestMessage: string;
};

// TODO: Implement better completed tenant action display that includes a list of all the uploaded attachments
function UploadMessage({ data, requestMessage }: UploadMessageProps) {
  const lineItemDetails = data.tenantAction?.lineItem;
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={data.tenantAction?.completed ? "Tenant has submitted this document" : "Waiting on tenant to submit this document"}>
        {data.tenantAction?.completed ? (
          <TaskOutlinedIcon sx={{ color: "rgba(37, 155, 36, 1)" }} />
        ) : (
          <WatchLaterOutlinedIcon sx={{ color: "#EED202" }} />
        )}
      </Tooltip>
      <Stack>
        {lineItemDetails && <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Item: '{lineItemDetails.name}'</Typography>}
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: (theme: Theme) => theme.palette.grey.A200,
            whiteSpace: "pre-wrap", // make sure new lines are displayed
            overflowWrap: "break-word",
          }}
        >
          {requestMessage}
        </Typography>
      </Stack>
    </Stack>
  );
}

export { UploadMessage };
