import { Box, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { BorderedCheckbox } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/BorderedCheckbox";
import { SafeLeaseButton } from "@safelease/components";
import { ChevronLeft, Search } from "@mui/icons-material";
import { z } from "zod";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { StyledTextFieldForUnitSelectionModal } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/StyledTextFieldForUnitSelectionModal";
import { OrDividingLine } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/OrDividingLine";
import { captureException } from "@sentry/react";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";

const emailSchema = z.string().email({ message: "Invalid email address" });

type EmailUnitLookupStepProps = {};

function EmailUnitLookupStep({}: EmailUnitLookupStepProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultEmailSelected, setDefaultEmailSelected] = useState<boolean>(false);
  const [userProvidedEmail, setUserProvidedEmail] = useState<string>("");
  const [noUnitsFoundErrorMessage, setNoUnitsFoundErrorMessage] = useState<string>("");
  const disableContinueButton = !defaultEmailSelected && !userProvidedEmail.trim();
  // const userAttributes = useAuthStore((state) => state.userAttributes);
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const defaultEmail = claim?.unit?.cognitoUserDetail?.userEmail;
  const { selectedLocation, setLeaseMatchingData, setCurrentStep, setProgress } = useUnitSelectionModalStore((state) => ({
    selectedLocation: state.selectedLocation,
    setLeaseMatchingData: state.setLeaseMatchingData,
    setCurrentStep: state.setCurrentStep,
    setProgress: state.setProgress,
  }));

  const onContinue = async () => {
    if (!selectedLocation || !defaultEmail) return;
    setIsLoading(true);
    let email = defaultEmail;
    if (!defaultEmailSelected) {
      try {
        emailSchema.parse(userProvidedEmail);
        email = userProvidedEmail; // set the email to use for the search to be the userProvided if they did not use the default email
      } catch (err: any) {
        enqueueSnackbar(err.errors[0].message, { variant: "error" });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(true);
    try {
      const response = await ClaimsApi.getSafeleaseLeaseMatchingDataForLocationViaEmail({ locationId: selectedLocation.locationId, email });
      if (response.data.length === 0) {
        setNoUnitsFoundErrorMessage(`Sorry, we couldn't find any units with '${email}'.`);
      } else {
        setLeaseMatchingData(response.data);
        setCurrentStep(UnitSelectionModalStep.UNIT_SELECTION);
        setProgress(75);
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar("There was an error searching for your unit. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack spacing={3} width="100%">
      <Stack spacing={0.5}>
        <Box>
          <SafeLeaseButton
            sx={{ color: "grey.A200" }}
            onClick={() => {
              setCurrentStep(UnitSelectionModalStep.FACILITY_LOCATION_SEARCH);
              setProgress(25);
            }}
            startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
          >
            Go back
          </SafeLeaseButton>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{"Now, let's find the unit via email"}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: (theme) => theme.palette.grey.A200 }}>
          {"Enter the email address used in the FMS."}
        </Typography>
      </Stack>
      <BorderedCheckbox value={defaultEmailSelected} setValue={setDefaultEmailSelected} label={defaultEmail || ""} />
      <OrDividingLine />
      <Stack alignItems="center">
        <StyledTextFieldForUnitSelectionModal
          value={userProvidedEmail}
          setValue={setUserProvidedEmail}
          disabled={defaultEmailSelected}
          placeholder="Enter email"
          startIcon={<Search />}
        />
        {!noUnitsFoundErrorMessage && (
          <Typography
            onClick={() => setCurrentStep(UnitSelectionModalStep.TENANT_NAME_LOOKUP)}
            sx={{ color: (theme: Theme) => theme.palette.grey.A200, cursor: "pointer", fontSize: 14, fontWeight: 500 }}
          >
            Tenant doesn't have an email in the FMS?
          </Typography>
        )}
      </Stack>
      <Stack spacing={1}>
        {/* Conditionally show the larger button to suggest trying with their name if they have already tried to search using email and we failed to match them */}
        {noUnitsFoundErrorMessage && (
          <>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: "error.main" }}>{noUnitsFoundErrorMessage}</Typography>
            <SafeLeaseButton onClick={() => setCurrentStep(UnitSelectionModalStep.TENANT_NAME_LOOKUP)} color="navy" variant="outlined">
              Try searching with the tenants name instead
            </SafeLeaseButton>
          </>
        )}
        <Tooltip title={disableContinueButton ? "Please select/provide an email to continue" : ""} placement="top">
          <Box>
            <SafeLeaseButton
              onClick={onContinue}
              loading={isLoading}
              fullWidth
              disabled={!defaultEmailSelected && !userProvidedEmail.trim()}
              color="navy"
              variant="contained"
            >
              Continue
            </SafeLeaseButton>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export { EmailUnitLookupStep };
