import React from "react";
import { Divider, Typography } from "@mui/material";

interface TitleHeaderProps {
  title: string;
  sx?: object;
}

const TitleHeader: React.FC<TitleHeaderProps> = ({ title, sx }) => {
  return (
    <div>
      <Typography
        sx={{
          color: "#152744",
          fontSize: "18px",
          lineHeight: "30px",
          letterSpacing: "0.18px",
          fontWeight: 400,
          ...sx,
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          border: "0.5px solid #EBEFF7",
          mb: 2,
        }}
      />
    </div>
  );
};

export { TitleHeader };
