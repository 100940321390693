import { create } from "zustand";
import { Configuration_ClaimsDbModel } from "@safelease/service-utilities";

type ClaimsConfigurationsStore = {
  claimsConfigurations: Configuration_ClaimsDbModel[];
  setClaimsConfigurations: (claimsConfigurations: Configuration_ClaimsDbModel[]) => void;
};

export const useClaimsConfigurationsStore = create<ClaimsConfigurationsStore>()((set) => ({
  claimsConfigurations: [],
  setClaimsConfigurations: (claimsConfigurations) => {
    set({
      claimsConfigurations,
    });
  },
}));

/**
 * Get the value of a configuration by name. This function living outside the
 * store ensures that the component that calls it re-renders with up-to-date
 * data if the claimsConfigurations list updates for any reason
 * @param name
 */
export const getConfigurationValue = (name: string) => {
  const claimsConfigurations = useClaimsConfigurationsStore((state) => state.claimsConfigurations);
  return claimsConfigurations.find((config) => config.name === name)?.value?.data ?? undefined;
};
