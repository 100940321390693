import { Close, Delete } from "@mui/icons-material";
import { Box, Card, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { StyledTextFieldForUnitSelectionModal } from "../../../../../../components/ServiceUIComponents/ClaimsService/StyledTextFieldForUnitSelectionModal";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { captureException } from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { OrDividingLine } from "../../../../../../components/ServiceUIComponents/ClaimsService/OrDividingLine";

type ClaimantNameOverrideModalProps = {
  setOpen: (open: boolean) => void;
};

function ClaimantNameOverrideModal({ setOpen }: ClaimantNameOverrideModalProps) {
  const [newClaimantNameOverride, setNewClaimantNameOverride] = useState<string>("");
  const [updateIsLoading, setUpdateIsLoading] = useState<boolean>(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState<boolean>(false);
  const { claim, refetchClaim } = useSelectedClaimPageStore((state) => ({
    claim: state.claim,
    refetchClaim: state.refetchClaim,
  }));
  const tenantName = `${claim?.unit?.cognitoUserDetail?.userFirstName} ${claim?.unit?.cognitoUserDetail?.userLastName}`;
  const currentClaimantOverride = claim?.claimantNameOverride;

  const closeModal = () => {
    setOpen(false);
  };

  const onActionButtonClicked = async (deleteClaimantNameOverride: boolean = false) => {
    let claimantNameValue: string | null; // This is going to be either null or the value provided by the user depending on if this is a delete call or an update call
    if (deleteClaimantNameOverride) {
      const confirmed = window.confirm("Are you sure you want to delete the claimant name override?");
      if (!confirmed) return;
      setDeleteIsLoading(true);
      claimantNameValue = null;
    } else {
      setUpdateIsLoading(true);
      claimantNameValue = newClaimantNameOverride.trim();
    }

    try {
      if (!claim?.id) throw new Error("Missing claim id for selected claim");

      await ClaimsApi.updateClaim(claim.id, {
        claimantNameOverride: claimantNameValue,
      });
      refetchClaim();
      closeModal();
    } catch (error) {
      captureException(error);
      enqueueSnackbar("There was an error updating the claimant name. Please try again", { variant: "error" });
    } finally {
      setUpdateIsLoading(false);
    }
  };

  return (
    <Modal onClose={closeModal} open={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Card>
        <Stack spacing={2} sx={{ width: 600, maxWidth: "90vw", maxHeight: "95vh", overflow: "auto", padding: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: 20, fontWeight: 500 }}>Claimant name override</Typography>
            <IconButton onClick={closeModal}>
              <Close sx={{ color: "black" }} />
            </IconButton>
          </Stack>
          <Divider />
          <Stack>
            <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
              Current tenant name: <strong>{`${tenantName}`}</strong>{" "}
            </Typography>
            {Boolean(currentClaimantOverride) && (
              <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                Current claimant name override: <strong>{`${currentClaimantOverride}`}</strong>{" "}
              </Typography>
            )}
          </Stack>
          <Stack alignItems="center" spacing={1}>
            <StyledTextFieldForUnitSelectionModal
              value={newClaimantNameOverride}
              setValue={setNewClaimantNameOverride}
              placeholder="Enter claimant name override"
            />
            <Box>
              <SafeLeaseButton
                onClick={() => onActionButtonClicked()}
                color="navy"
                variant="contained"
                disabled={!newClaimantNameOverride.trim() || deleteIsLoading}
                loading={updateIsLoading}
              >
                Update
              </SafeLeaseButton>
            </Box>
          </Stack>
          {Boolean(currentClaimantOverride) && (
            <>
              <OrDividingLine />
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <SafeLeaseButton
                  onClick={() => onActionButtonClicked(true)}
                  loading={deleteIsLoading}
                  disabled={updateIsLoading}
                  variant="outlined"
                  color="error"
                  startIcon={<Delete />}
                >
                  Delete claimant name override
                </SafeLeaseButton>
              </Box>
            </>
          )}
        </Stack>
      </Card>
    </Modal>
  );
}

export { ClaimantNameOverrideModal };
