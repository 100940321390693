import { Badge, CircularProgress, MenuItem, Select, Stack } from "@mui/material";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useClaimDocumentViewerModalStore } from "./useClaimDocumentViewerModalStore";
import { DocumentLabels } from "@safelease/service-utilities/enums";
import { RequestDocumentationButton } from "../../../SharedComponents/RequestDocumentationButton";
import { AddDocumentButton } from "../../../SharedComponents/AddDocumentButton";

type DocumentTypeActionsHeaderProps = {};

function DocumentTypeActionsHeader({}: DocumentTypeActionsHeaderProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;

  const { documentList, selectedDocumentLabel, setSelectedDocumentLabel, selectedLineItemId, setSelectedLineItemId } =
    useClaimDocumentViewerModalStore((state) => ({
      documentList: state.documentList,
      selectedDocumentLabel: state.selectedDocumentLabel,
      setSelectedDocumentLabel: state.setSelectedDocumentLabel,
      selectedLineItemId: state.selectedLineItemId,
      setSelectedLineItemId: state.setSelectedLineItemId,
    }));

  const documentLabelsQuery = useQuery({
    queryKey: [`getDocumentLabels`, claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getDocumentLabels(claimId);
      return response.data;
    },
  });

  const getTotalCountForDocumentLabel = (label: string) => {
    if (label === "") {
      return documentList.filter((doc) => doc.label !== DocumentLabels.ARCHIVED).length;
    }
    return documentList.filter((doc) => doc.label === label).length;
  };

  const getUnreadCountForDocumentLabel = (label: string) => {
    return documentList.filter((doc) => doc.label === label && doc.attachmentUserViews.filter((view) => !view.dismissedAt).length === 0)
      .length;
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ paddingBottom: 1, borderBottom: 1, borderColor: "rgba(235, 239, 247, 1)" }}>
      <Stack direction="row" spacing={1}>
        {documentLabelsQuery.isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Select displayEmpty value={selectedDocumentLabel} onChange={(e) => setSelectedDocumentLabel(e.target.value)}>
              <MenuItem value="">{`All documents (${getTotalCountForDocumentLabel("")})`}</MenuItem>
              {documentLabelsQuery.data?.documentLabels.map((label: string) => (
                <MenuItem value={label} key={label} sx={{ minWidth: 240 }}>
                  <Stack direction="row" alignItems="start">
                    <Badge
                      color="primary"
                      badgeContent={getUnreadCountForDocumentLabel(label)}
                      max={9}
                      sx={{
                        "& .MuiBadge-badge": {
                          right: -15,
                          top: 11.3,
                        },
                      }}
                    >
                      {`${label} (${getTotalCountForDocumentLabel(label)})`}
                    </Badge>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
            {selectedDocumentLabel === "Claim items" && (
              <Select displayEmpty value={selectedLineItemId} onChange={(e) => setSelectedLineItemId(e.target.value)}>
                <MenuItem value="">All line items</MenuItem>
                {documentLabelsQuery.data?.lineItemSubLabels.map((label: { id: string; name: string }) => (
                  <MenuItem value={label.id} key={label.id}>
                    {label.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <AddDocumentButton />
        <RequestDocumentationButton />
      </Stack>
    </Stack>
  );
}

export { DocumentTypeActionsHeader };
