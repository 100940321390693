import { ClaimsNavigatorTableRow } from "@safelease/service-utilities";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ClaimsApi } from "../../../../../utils/apiInstances/ClaimsApiInstance";
import { Skeleton } from "@mui/material";
import { ClaimsTable } from "./ClaimsTable";

type ActiveClaimsTableProps = {};

function ActiveClaimsTable({}: ActiveClaimsTableProps) {
  const [activeClaims, setActiveClaims] = useState<ClaimsNavigatorTableRow[]>([]);
  const [page, setPage] = useState(1);

  const claimsQuery = useQuery({
    queryKey: ["getAllActiveClaimsByPage", page],
    queryFn: async () => {
      const response = await ClaimsApi.getAllActiveClaimsByPage(page);
      const claimsByPage = response.data;
      if (claimsByPage?.length) {
        setActiveClaims((prev) => [...prev, ...claimsByPage]);
        setPage(page + 1);
      }
      return claimsByPage;
    },
  });

  if ((claimsQuery.isLoading || claimsQuery.isRefetching) && !activeClaims.length) {
    return <Skeleton variant="rounded" sx={{ width: "100%", height: "100%" }} />;
  }

  return <ClaimsTable claimsList={activeClaims} />;
}

export { ActiveClaimsTable };
