import { SafeLeaseTextField, SafeLeaseTextFieldProps } from "@safelease/components";
import { NumericFormat } from "react-number-format";
import { forwardRef, useEffect, useRef, useState } from "react";
import { LineItem_ClaimsDbModel } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { Stack, Typography } from "@mui/material";
import { formatCurrency } from "@safelease/components/src/utils/formatters";
import { useClaimDetailsDrawerStore } from "../useClaimDetailsDrawerStore";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";

type AdjusterValueSectionProps = {
  lineItem: LineItem_ClaimsDbModel;
  refetchLineItem: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<LineItem_ClaimsDbModel, Error>>;
};

function AdjusterValueSection({ lineItem, refetchLineItem }: AdjusterValueSectionProps) {
  const adjusterValueInputRef = useRef<HTMLInputElement | null>(null);
  const [editAdjusterValue, setEditAdjusterValue] = useState<boolean>(false);
  const focusAdjusterValueInput = useClaimDetailsDrawerStore((state) => state.focusAdjusterValueInput);
  // We need to refetch the claim that is being used to display data outside of the drawer so that things are up to dat after the adjuster value is updated
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);

  const onEnter = async (adjusterValue: string) => {
    const adjusterValueInPennies = Number(adjusterValue.replace(/\D/g, ""));
    try {
      await ClaimsApi.updateLineItem(lineItem.id as string, {
        adjusterValue: adjusterValueInPennies,
      });
      setEditAdjusterValue(false);
      await refetchLineItem();
      await refetchClaim();
    } catch (error) {
      enqueueSnackbar("Failed to update adjuster value", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    // Focus the adjuster value text field if the focusAdjusterValueInput is true
    if (adjusterValueInputRef.current !== null && (focusAdjusterValueInput || editAdjusterValue)) adjusterValueInputRef.current.focus();
  }, [focusAdjusterValueInput, editAdjusterValue]);

  const adjusterValue = lineItem.adjusterValue ? formatCurrency(lineItem.adjusterValue / 100) : undefined;
  return (
    <>
      {adjusterValue && !editAdjusterValue ? (
        <Stack direction="row" spacing={1}>
          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>Adjuster value:</Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              cursor: "pointer",
              ":hover": { color: "#007BFF" },
            }}
            onClick={() => setEditAdjusterValue(true)}
          >
            {adjusterValue}
          </Typography>
        </Stack>
      ) : (
        <NumericFormatWrapper
          onEnter={onEnter}
          inputRef={adjusterValueInputRef}
          placeholder="Enter value"
          label={adjusterValue ? `Adjuster value: ${adjusterValue}` : "Adjuster value"}
        />
      )}
    </>
  );
}
const NumericFormatWrapper = forwardRef(
  (
    // This Omit type is needed or you will get a type error when using this component
    {
      onEnter,
      ...props
    }: Omit<SafeLeaseTextFieldProps, "defaultValue" | "type" | "value"> & {
      onEnter: (adjusterValue: string) => void;
    },
    ref,
  ) => {
    const [adjusterValue, setAdjusterValue] = useState("");

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();

        // submit the update claims request
        onEnter(adjusterValue);
        setAdjusterValue("");
      }
      // 'Shift + Enter' will behave normally and add a new line
    };

    return (
      <NumericFormat
        {...props}
        allowNegative={false}
        fixedDecimalScale
        thousandSeparator=","
        prefix="$"
        decimalScale={2}
        fullWidth
        sx={{ backgroundColor: "white", borderRadius: "5px" }}
        customInput={SafeLeaseTextField}
        value={adjusterValue}
        onChange={(e) => setAdjusterValue(e.target.value)}
        onKeyDown={handleKeyDown}
        getInputRef={ref}
      />
    );
  },
);

export { AdjusterValueSection };
