import { Box, Stack, Typography } from "@mui/material";
import { DrawerHeader } from "../../../SelectedClaimPage/DetailsDrawer/sharedComponents/DrawerHeader";
import { LegacyClaim } from "@safelease/service-utilities";

type AdjusterNotesDrawerProps = {
  legacyClaim: LegacyClaim;
};

function AdjusterNotesDrawer({ legacyClaim }: AdjusterNotesDrawerProps) {
  return (
    <Stack sx={{ width: 600, height: "100%", padding: "16px" }} spacing={2}>
      <DrawerHeader title="Adjuster notes" legacyClaim />
      <Box sx={{ overflow: "auto", paddingY: 2, paddingRight: 1 }}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            whiteSpace: "pre-wrap", // make sure new lines are displayed
          }}
        >
          {legacyClaim.adjusterNotes}
        </Typography>
      </Box>
    </Stack>
  );
}

export { AdjusterNotesDrawer };
