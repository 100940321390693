import { SafeLeaseDataGrid } from "@safelease/components";
import { columnDefs } from "./columnDefs";
import { GridRowParams } from "@mui/x-data-grid-premium";
import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { useState } from "react";
import { SelectedUserModal } from "./SelectedUserModal";

type TenantUsersTableProps = {
  usersList: UserType[];
};

function TenantUsersTable({ usersList }: TenantUsersTableProps) {
  const [selectedUserModalOpen, setSelectedUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);

  return (
    <>
      <SafeLeaseDataGrid
        getRowId={(row) => row.Username}
        rows={usersList}
        columns={columnDefs}
        onRowClick={(params: GridRowParams<UserType>) => {
          setSelectedUser(params.row);
          setSelectedUserModalOpen(true);
        }}
        sx={{
          // Target the rows and customize the border below each row
          ".MuiDataGrid-row": {
            cursor: "pointer",
            "&:not(:last-of-type)": {
              // Target all but the last row to add a border
              borderBottom: "2px solid rgba(235, 239, 247, 1)", // Customize the border color and thickness
            },
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid rgba(235, 239, 247, 1)", // Customize the border color and thickness for the header
          },
          padding: "16px",
        }}
        slotProps={{
          panel: {
            sx: {
              ".MuiSwitch-sizeSmall": {
                width: "44px",
              },
            },
          },
        }}
      />
      {selectedUserModalOpen && selectedUser && <SelectedUserModal setOpen={setSelectedUserModalOpen} user={selectedUser} />}
    </>
  );
}

export { TenantUsersTable };
