import { Stack } from "@mui/material";
import { EmailSubmitContent } from "./EmailSubmitContent";
import { NewPasswordSubmitContent } from "./NewPasswordSubmitContent";
import { ForgotPasswordStages, useForgotPasswordPageStore } from "./useForgotPasswordPageStore";
import AllDoneContent from "./AllDoneContent";

type ForgotPasswordPageProps = {};

function ForgotPasswordPage({}: ForgotPasswordPageProps) {
  const forgotPasswordStage = useForgotPasswordPageStore((state) => state.forgotPasswordStage);

  return (
    <Stack alignItems="center">
      {forgotPasswordStage === ForgotPasswordStages.EMAIL_SUBMIT && <EmailSubmitContent />}
      {forgotPasswordStage === ForgotPasswordStages.NEW_PASSWORD_SUBMIT && <NewPasswordSubmitContent />}
      {forgotPasswordStage === ForgotPasswordStages.ALL_DONE && <AllDoneContent />}
    </Stack>
  );
}

export { ForgotPasswordPage };
