import { styled, Typography, Grid, Button } from "@mui/material";
import { StyledTextField } from "../DataChangeManagerStyledComponents";
import { useDataChangeManager } from "../../useDataChangeManager";
import useActionHandlers from "../../useActionHandlers";

interface BaseActionViewProps {
  actionTitle: string;
  children: any;
}

const ActionTypeText = styled(Typography)({
  fontSize: "24px",
  fontWeight: "bold",
});

const StyledButton = styled(Button)(() => ({
  marginTop: "16px",
  minWidth: "250px",
  width: "50vw",
}));

function BaseActionView({ actionTitle, children }: BaseActionViewProps) {
  const { textFieldState } = useDataChangeManager();
  const { handleTextFieldChange, handleSubmitClick } = useActionHandlers();

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ActionTypeText>{actionTitle}</ActionTypeText>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTextField
          id="note"
          label="Note"
          variant="outlined"
          onChange={handleTextFieldChange}
          multiline
          fullWidth
          value={textFieldState.note}
        />
      </Grid>
      <Grid item></Grid>
      {children}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledButton color="primary" variant="contained" onClick={handleSubmitClick}>
          Submit
        </StyledButton>
      </Grid>
    </>
  );
}

export { BaseActionView };
