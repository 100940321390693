import { Box, CircularProgress } from "@mui/material";
import { SafeLeaseButton, SafeLeaseMenu, SafeLeaseMenuItem } from "@safelease/components";
import { useState } from "react";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { useClaimSpecificDocumentSelectorMenuItems } from "../../../../../../hooks/claims/useClaimSpecificDocumentSelectorMenuItems";
import { useParams } from "react-router";
import { UploadFileModal } from "./UploadFileModal";
import {
  AddDocumentationAdditionalMetadata,
  getTitleForTenantActionType,
  submitAdditionalDocumentationTypeFileUpload,
  submitTenantActionTypeFileUpload,
} from "./helpers";

enum UploadFileCallbackType {
  ADDITIONAL_DOCUMENTATION = "additionalDocumentation",
  TENANT_ACTION = "tenantAction",
}
type AddDocumentButtonProps = {};

function AddDocumentButton({}: AddDocumentButtonProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [additionalMetadata, setAdditionalMetadata] = useState<AddDocumentationAdditionalMetadata>({});
  const [tenantActionType, setTenantActionType] = useState<TenantActionTypes | null>(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [uploadFileCallbackType, setUploadFileCallbackType] = useState<UploadFileCallbackType | null>(null);

  const { documentSelectorMenuItems, loading } = useClaimSpecificDocumentSelectorMenuItems(claimId); // custom hook that returns the data needed for rendering the menu items for the button

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // set the correct state depending on which button was clicked to pre populate the request message and open the request upload modal
  const handleTenantActionClick = (type: TenantActionTypes, additionalMetadata?: AddDocumentationAdditionalMetadata) => {
    setUploadFileCallbackType(UploadFileCallbackType.TENANT_ACTION);
    setTenantActionType(type);
    setAdditionalMetadata(additionalMetadata ?? {}); // provides context to the modal for the line item names or ids and type specific attachment names and ids
    setModalTitle(getTitleForTenantActionType(type, additionalMetadata));
    setUploadFileModalOpen(true);
    handleClose();
  };

  const handleAdditionalDocumentationClick = () => {
    setUploadFileCallbackType(UploadFileCallbackType.ADDITIONAL_DOCUMENTATION);
    setModalTitle("Additional Documentation");
    setUploadFileModalOpen(true);
    handleClose();
  };

  const uploadFilesCallback = async (files: File[], optionalMetadata: { createEmailedFacilityNote?: boolean }) => {
    switch (uploadFileCallbackType) {
      case UploadFileCallbackType.TENANT_ACTION:
        if (tenantActionType) {
          await submitTenantActionTypeFileUpload(files, claimId, tenantActionType, additionalMetadata);
        }
        break;
      case UploadFileCallbackType.ADDITIONAL_DOCUMENTATION:
        await submitAdditionalDocumentationTypeFileUpload(files, claimId, optionalMetadata?.createEmailedFacilityNote);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <SafeLeaseButton onClick={handleClick} startIcon={<AddBoxOutlinedIcon />} variant="text" color="navy">
        Add document
      </SafeLeaseButton>
      <SafeLeaseMenu placement="top-start" open={Boolean(anchorEl)} anchorEl={anchorEl} handleClose={handleClose} sx={{ zIndex: 1000 }}>
        <SafeLeaseMenuItem label="Additional documentation" onClick={handleAdditionalDocumentationClick} />
        <SafeLeaseMenuItem
          label="Plan addendum"
          onClick={() => handleTenantActionClick(TenantActionTypes.UPLOAD_PROTECTION_PLAN_ADDENDUM)}
        />
        <SafeLeaseMenuItem label="Billing ledger" onClick={() => handleTenantActionClick(TenantActionTypes.UPLOAD_BILLING_LEDGER)} />
        {documentSelectorMenuItems?.typeSpecificAttachmentDefs.map((def) => (
          <SafeLeaseMenuItem
            label={def.name}
            key={def.id}
            onClick={() =>
              handleTenantActionClick(TenantActionTypes.UPLOAD_TYPE_SPECIFIC_ATTACHMENT, {
                typeSpecificAttachmentDefId: def.id,
                typeSpecificAttachmentDefName: def.name,
              })
            }
          />
        ))}
        <SafeLeaseMenuItem label="Receipts">
          {documentSelectorMenuItems?.lineItems.map((lineItem) => (
            <SafeLeaseMenuItem
              label={`${lineItem.name}`}
              key={lineItem.id}
              onClick={() =>
                handleTenantActionClick(TenantActionTypes.UPLOAD_PROOF_OF_PURCHASE_FILES, {
                  lineItemId: lineItem.id,
                  lineItemName: lineItem.name,
                })
              }
            />
          ))}
        </SafeLeaseMenuItem>
        <SafeLeaseMenuItem label="Proof of ownership">
          {documentSelectorMenuItems?.lineItems.map((lineItem) => (
            <SafeLeaseMenuItem
              label={`${lineItem.name}`}
              key={lineItem.id}
              onClick={() =>
                handleTenantActionClick(TenantActionTypes.UPLOAD_PROOF_OF_OWNERSHIP_FILES, {
                  lineItemId: lineItem.id,
                  lineItemName: lineItem.name,
                })
              }
            />
          ))}
        </SafeLeaseMenuItem>
      </SafeLeaseMenu>
      {uploadFileModalOpen && (
        <UploadFileModal setOpen={setUploadFileModalOpen} title={modalTitle} uploadFilesCallback={uploadFilesCallback} />
      )}
    </Box>
  );
}

export { AddDocumentButton };
