import { Box, CircularProgress, Divider, Stack } from "@mui/material";
import { DrawerHeader } from "../sharedComponents/DrawerHeader";
import { MessagesList } from "./MessagesList/MessagesList";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { useQuery } from "@tanstack/react-query";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import { enqueueSnackbar } from "notistack";
import { ClaimsApi } from "../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useEffect, useState } from "react";
import { TextFieldWithSubmissionButtons } from "../sharedComponents/TextFieldWithSubmissionButtons";
import { useParams } from "react-router";
import { useTenantCommunicationDrawerStore } from "./useTenantCommunicationDrawerStore";
import { RequestDocumentationButton } from "../../SharedComponents/RequestDocumentationButton";

type TenantCommunicationDrawerProps = {};

function TenantCommunicationDrawer({}: TenantCommunicationDrawerProps) {
  const { claimId } = useParams<{ claimId: string }>();
  if (!claimId) return null;
  const refetchClaim = useSelectedClaimPageStore((state) => state.refetchClaim);
  const setRefetchMessages = useTenantCommunicationDrawerStore((state) => state.setRefetchMessages);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);

  const messagesQuery = useQuery<GetClaimMessageResponse[]>({
    queryKey: ["getClaimMessages", claimId],
    queryFn: async () => {
      const response = await ClaimsApi.getClaimMessages(claimId);
      return response.data;
    },
  });

  useEffect(() => {
    if (messagesQuery.status === "success") {
      setRefetchMessages(messagesQuery.refetch);
    }
  }, [messagesQuery.status, setRefetchMessages, messagesQuery.refetch]);

  const handleSendMessage = async (content: string) => {
    // Any message that gets sent from this function will be from the adjuster and basic messages without any upload requests
    // the upload request will be sent from the Request documentation button.
    setSendingMessage(true);
    try {
      await ClaimsApi.createClaimMessage({
        claimId,
        content: content,
      });
      await messagesQuery.refetch();
      await refetchClaim();
    } catch (error) {
      enqueueSnackbar("Error sending message, please try again.", {
        variant: "error",
      });
    }
    setSendingMessage(false);
  };

  return (
    <Stack sx={{ width: 600, height: "100%", padding: "16px" }} spacing={2}>
      {messagesQuery.isLoading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : messagesQuery.error ? (
        <>error...</>
      ) : (
        <>
          <DrawerHeader title="Tenant communication" subTitle={`Last communication on ${new Date().toLocaleDateString()}`} />
          <Divider />
          <MessagesList list={messagesQuery.data || []} />
          <RequestDocumentationButton />
          <TextFieldWithSubmissionButtons loading={sendingMessage} onSubmit={handleSendMessage} buttonLabel="Send message" />
        </>
      )}
    </Stack>
  );
}

export { TenantCommunicationDrawer };
