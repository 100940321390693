import { HelpOutline } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";
import { getCalculatedPrivatePolicyStatus } from "../../../utils/helpers/privatePolicy";

interface PrivatePolicyStatusChipProps {
  privatePolicy: SafeleasePrivatePolicy;
  showInfo?: boolean;
}
/**
 * Component that displays a chip with varying colors and labels based on the
 * status of the private policy.
 */
export function PrivatePolicyStatusChip({ privatePolicy, showInfo = false }: PrivatePolicyStatusChipProps) {
  const status = getCalculatedPrivatePolicyStatus(privatePolicy).statusGroup;
  const { label, ...sx }: StatusConfig = status ? statusConfigs[status] : defaultStatusConfig;

  const needsReviewNotes =
    (privatePolicy?.validationStatus === "humanReview" || !privatePolicy?.validationStatus) && showInfo
      ? "Policies we cannot automatically validate based on user inputs or AI-extracted are sent to human review."
      : null;
  const archivedAtNotes = status === "archived" ? privatePolicy.archivedAtNotes : null;

  if (!needsReviewNotes && !archivedAtNotes) {
    return <Chip size="small" label={label} sx={{ ...sx, fontWeight: 600 }} />;
  }

  return (
    <Tooltip title={needsReviewNotes || archivedAtNotes}>
      <Chip size="small" label={label} sx={{ ...sx, fontWeight: 600 }} icon={<HelpOutline />} />
    </Tooltip>
  );
}

// Status config visuals
type StatusConfig = {
  label: string;
  color: string;
  bgcolor: string;
};

const statusConfigs: {
  [key: string]: StatusConfig;
} = {
  flagged: {
    label: "Flagged",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
  accepted: {
    label: "Accepted",
    color: "white",
    bgcolor: "rgb(37, 155, 36)",
  },
  rejected: {
    label: "Rejected",
    color: "white",
    bgcolor: "rgb(233,100,95)",
  },
  expired: {
    label: "Expired",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[100],
  },
  pending: {
    label: "Pending",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
  tenantEnrollOverride: {
    label: "Tenant Enroll Override",
    color: "white",
    bgcolor: "rgb(17, 128, 255)",
  },
  archived: {
    label: "Archived",
    color: "rgb(3, 30, 48)",
    bgcolor: grey[100],
  },
  null: {
    label: "Flagged",
    color: "rgb(3, 30, 48)",
    bgcolor: "rgb(255, 195, 0)",
  },
};

const defaultStatusConfig: StatusConfig = {
  label: "Flagged",
  color: "rgb(3, 30, 48)",
  bgcolor: "rgb(255, 195, 0)",
};
