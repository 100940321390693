import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { BorderedCheckbox } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/BorderedCheckbox";
import { useState } from "react";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import { ChevronLeft } from "@mui/icons-material";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";
import { GetSafeleaseLocationForGooglePlaceIdResponse, SafeleaseLeaseMatchingData } from "@safelease/service-utilities";
import { useSelectedClaimPageStore } from "../../../../useSelectedClaimPageStore";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";

export type UnitSelectionVerificationData = {
  leaseMatchingData: SafeleaseLeaseMatchingData[] | null;
  selectedLocation: GetSafeleaseLocationForGooglePlaceIdResponse | null;
  selectedUnitId: number;
};

type UnitSelectionStepProps = {};

function UnitSelectionStep({}: UnitSelectionStepProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { leaseMatchingData, setCurrentStep, setProgress, selectedLocation, setUnitSelectionModalOpen } = useUnitSelectionModalStore(
    (state) => ({
      leaseMatchingData: state.leaseMatchingData,
      setCurrentStep: state.setCurrentStep,
      setProgress: state.setProgress,
      selectedLocation: state.selectedLocation,
      setUnitSelectionModalOpen: state.setUnitSelectionModalOpen,
    }),
  );
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);

  const { claim, refetchClaim } = useSelectedClaimPageStore((state) => ({ claim: state.claim, refetchClaim: state.refetchClaim }));
  const currentUnitId = claim?.unitId;

  const queryClient = useQueryClient();

  const onCompleteVerificationButtonClicked = async () => {
    setIsLoading(true);

    try {
      const selectedLeaseData = leaseMatchingData?.find((leaseData) => leaseData.Unit.id === selectedUnitId);
      if (!selectedLeaseData) throw new Error("Selected lease data not found");
      if (!selectedLocation) throw new Error("Missing selected location data");
      if (!currentUnitId) throw new Error("Missing unit id for selected claim");
      await ClaimsApi.updateSafeleaseMatchDataForUnit(currentUnitId, {
        name: selectedLeaseData.Unit.name,
        matchedAllSafeleaseData: true,
        adjusterManualUnitName: false,
        safeleaseMatchData: {
          safeleaseLeaseId: selectedLeaseData.id,
          safeleaseUnitId: selectedLeaseData.Unit.id,
          safeleasePolicies: selectedLeaseData.Policies,
          safeleaseLocationDetails: selectedLocation,
        },
      });
      refetchClaim();
      queryClient.invalidateQueries({ queryKey: ["policyData", claimId] });
      setUnitSelectionModalOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar("There was an error completing the verification. Please try again", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={1} width="100%">
      <Box>
        <SafeLeaseButton
          sx={{ color: "grey.A200" }}
          onClick={() => {
            setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
            setProgress(50);
          }}
          startIcon={<ChevronLeft sx={{ color: "navy.main" }} />}
        >
          Go back
        </SafeLeaseButton>
      </Box>
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>👋🏼 We found these units linked to the email provided</Typography>
      <Stack spacing={1.5} sx={{ overflow: "auto", paddingY: 1 }}>
        {leaseMatchingData?.map((leaseData) => (
          <BorderedCheckbox
            disabled={!!selectedUnitId && selectedUnitId !== leaseData.Unit.id}
            key={leaseData.id}
            value={selectedUnitId === leaseData.Unit.id}
            label={leaseData.Unit.name}
            setValue={(value) => {
              if (value) {
                setSelectedUnitId(leaseData.Unit.id);
              } else {
                setSelectedUnitId(null);
              }
            }}
          />
        ))}

        <Typography
          onClick={() => setCurrentStep(UnitSelectionModalStep.INCOMPLETE_DATA_SUBMISSION)}
          sx={{
            color: (theme: Theme) => theme.palette.grey.A200,
            cursor: "pointer",
            fontSize: 14,
            fontWeight: 500,
            alignSelf: "center",
          }}
        >
          Manually set unit name without matching safelease data
        </Typography>
      </Stack>
      {selectedUnitId && (
        <SafeLeaseButton
          loading={isLoading}
          onClick={() => {
            onCompleteVerificationButtonClicked();
          }}
          variant="contained"
          color="navy"
        >
          Complete verification
        </SafeLeaseButton>
      )}
    </Stack>
  );
}

export { UnitSelectionStep };
