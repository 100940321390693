import { useEffect } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { AdminRequestsHistory } from "./AdminRequestsHistory";
import {
  CredentialHelper,
  DeleteLocationView,
  UpdateUserRoleView,
  UpdateLocationDateView,
  OffboardFacilitiesView,
  UpdateLocationServiceIdView,
  UpdateLocationSfdcPushingView,
  DisconnectFmsFromLocationView,
  UpdateRelationshipBillingRequiredView,
} from "./views";
import { SafeLeaseLinearProgress as Loader } from "../../../components/SafeLeaseLinearProgress";
import { Box, MenuItem, Select, styled, Typography, InputLabel, Grid, Divider } from "@mui/material";
import { useDataChangeManager } from "../useDataChangeManager";
import { actionTypes } from "./config";
import { enqueueSnackbar } from "notistack";
import { ActionValueEnum } from "./config";
import Error from "../../../components/SafeLeaseError";
import useActionHandlers from "../useActionHandlers";

interface DataChangeManagerProps {}

const StyledInputLabel = styled(InputLabel)(() => ({
  textAlign: "center",
  display: "block",
}));

function DataChangeManager({}: DataChangeManagerProps) {
  const { getAllLocationsQuery, actionType, confirmDialogOpen } = useDataChangeManager();
  const { handleConfirmDialogClose, handleConfirmAction, handleUpdateActionChange, addAdminRequestMutation } = useActionHandlers();

  useEffect(() => {
    if (addAdminRequestMutation.isSuccess) {
      enqueueSnackbar("Request submitted successfully!", { variant: "success" });
    }
  }, [addAdminRequestMutation.isSuccess]);

  if (addAdminRequestMutation.isPending || getAllLocationsQuery.isLoading) {
    return <Loader />;
  }

  if (addAdminRequestMutation.isError || getAllLocationsQuery.isError) {
    return <Error />;
  }

  // Ex: getActionLabel("deleteLocation") => "Delete Location"
  const getActionLabel = (value: string) => {
    const action = actionTypes.find((action) => action.value === value);
    return action ? action.label : "";
  };

  const actionTypeMenuItems = actionTypes.map((action: { value: string; label: string }) => {
    return (
      <MenuItem key={action.value} value={action.value}>
        {action.label}
      </MenuItem>
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmationDialog
        open={confirmDialogOpen}
        handleClose={handleConfirmDialogClose}
        handleConfirmAction={handleConfirmAction}
        actionLabel={getActionLabel(actionType)}
      />
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h4" gutterBottom>
            Data Change Manager
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <StyledInputLabel id="update-action-label">Update Action</StyledInputLabel>
          <Select id="update-action-select" value={actionType} onChange={handleUpdateActionChange} displayEmpty fullWidth>
            {actionTypeMenuItems}
          </Select>
        </Grid>

        {/* Delete location view */}
        {actionType === ActionValueEnum.DELETE_LOCATION && <DeleteLocationView />}

        {/* Update live date */}
        {actionType === ActionValueEnum.UPDATE_LIVE_DATE && <UpdateLocationDateView dateType={"liveDate"} />}

        {/* Offboard Facility */}
        {actionType === ActionValueEnum.OFFBOARD_FACILITIES && <OffboardFacilitiesView />}

        {/* Credential Helper */}
        {actionType === ActionValueEnum.UPDATE_ACCOUNT_CREDENTIALS && <CredentialHelper />}

        {/* Update Location Service ID */}
        {actionType === ActionValueEnum.UPDATE_LOCATION_SERVICE_ID && <UpdateLocationServiceIdView />}

        {/* Update Core User Role */}
        {actionType === ActionValueEnum.UPDATE_USER_ROLE && <UpdateUserRoleView />}

        {/* Update Location Cancel Date */}
        {actionType === ActionValueEnum.UPDATE_LOCATION_CANCEL_DATE && <UpdateLocationDateView dateType="cancelDate" />}

        {/* Update Location Exempt SFDC Pushing */}
        {actionType === ActionValueEnum.UPDATE_LOCATION_EXEMPT_SFDC_PUSHING && <UpdateLocationSfdcPushingView />}

        {/* Disconnect Fms From Location */}
        {actionType === ActionValueEnum.DISCONNECT_FMS_FROM_LOCATION && <DisconnectFmsFromLocationView />}

        {/* Update Relationship Billing Required */}
        {actionType === ActionValueEnum.UPDATE_RELATIONSHIP_BILLING_REQUIRED && <UpdateRelationshipBillingRequiredView />}
      </Grid>
      {/* Admin Requests History */}
      {actionType === "adminRequestsHistory" && (
        <Box mt={2}>
          <AdminRequestsHistory />
        </Box>
      )}
    </Box>
  );
}

export { DataChangeManager };
