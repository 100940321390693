import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import AddIcon from "@mui/icons-material/Add";
import { useSelectedClaimPageStore } from "../../useSelectedClaimPageStore";
import { ImageViewer } from "../../../../../../components/ImageViewer";
import { UploadAdditionalDocumentsModal } from "./UploadAdditionalDocumentsModal";
import { useState } from "react";

type AdditionalDocumentsSectionProps = {};

function AdditionalDocumentsSection({}: AdditionalDocumentsSectionProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState<boolean>(false);
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography sx={{ fontWeight: 700, color: "rgba(3, 30, 48, 1)", fontSize: 14 }}>Additional documentation</Typography>
        <SafeLeaseButton onClick={() => setUploadFileModalOpen(true)} size="small" color="navy" variant="outlined" startIcon={<AddIcon />}>
          Add file
        </SafeLeaseButton>
      </Stack>
      {claim?.additionalClaimDocuments?.length === 0 ? (
        <Typography>No files</Typography>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1} paddingY={1} overflow="auto" sx={{ width: "100%" }}>
          {claim?.additionalClaimDocuments?.map((file) => (
            <Box key={file.id}>
              <ImageViewer
                fileName={file.attachment?.fileName || ""}
                file={file.attachment?.url as string}
                fileSize={file.attachment?.fileSize ?? null}
                archived={!!file.attachment?.deletedAt}
              />
            </Box>
          ))}
        </Stack>
      )}
      {uploadFileModalOpen && <UploadAdditionalDocumentsModal setUploadFileModalOpen={setUploadFileModalOpen} />}
    </Stack>
  );
}

export { AdditionalDocumentsSection };
