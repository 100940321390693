import { create } from "zustand";

export interface ProspectsFiltersStore {
  searchText: string;
  setSearchText: (searchText: string) => void;
}

export const useProspectsFiltersStore = create<ProspectsFiltersStore>((set) => ({
  searchText: "",
  setSearchText: (searchText: string) => {
    set({ searchText });
  },
}));
