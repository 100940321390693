import { Box, Grid, Slide, Stack, Typography } from "@mui/material";
import BackdropImage from "../../assets/signin-backdrop-blue.jpg";
import SafeLeaseLogo from "../../assets/safelease-logo.png";
import SafeLeaseWhiteWordmark from "../../assets/safelease-white-wordmark.png";
import { SignInForm } from "./SignInForm";
import { NewPasswordForm } from "./NewPasswordForm";
import { useSignInPageStore } from "./ForgotPasswordPage/useForgotPasswordPageStore";
import { ForgotPasswordPage } from "./ForgotPasswordPage/ForgotPasswordPage";
import { useAuthStore } from "../../hooks/useAuthStore";

type SignInPageProps = {};

export function SignInPage({}: SignInPageProps) {
  const { page } = useSignInPageStore();

  const userRequiresNewPassword = useAuthStore((state) => state.userRequiresNewPassword);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#fafafc",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          backgroundSize: "40px 40px" /* Adjust the size as needed */,
          backgroundImage:
            "linear-gradient(to right, rgba(0,0,0,0.04) 1px, transparent 1px), linear-gradient(to bottom, rgba(0,0,0,0.04) 1px, transparent 1px)",
        }}
      />
      <Box
        sx={{
          height: 700,
          width: 1000,
          borderRadius: 8,
          boxShadow: "0 0 20px rgba(0,0,0,0.2)",
          backgroundImage: `url(${BackdropImage})`,
          backgroundSize: "125% 125%",
          p: 2,
          zIndex: 1,
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={6} sx={{ p: 2 }}>
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start" sx={{ height: "100%" }}>
              <img src={SafeLeaseWhiteWordmark} alt="SafeLease Wordmark" style={{ height: 30, width: "auto", objectFit: "contain" }} />
              <Box>
                <Typography variant="h3" sx={{ color: "white", fontWeight: 600 }}>
                  Operations Hub
                </Typography>
                <Typography variant="h6" sx={{ color: "white", fontWeight: 500 }}>
                  Centralizing and Simplifying Our Success.
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                bgcolor: (theme) => theme.palette.background.paper,
                borderRadius: 6,
                p: 2, // Used to offset in the flexbox container
                boxShadow: "0 0 20px rgba(0,0,0,0.075)",
                width: "100%",
                flexGrow: 1,
                textAlign: "left",
                color: (theme) => theme.palette.text.primary,
                position: "relative",
              }}
            >
              <Box sx={{ p: 6, overflow: "hidden" }}>
                <Stack direction="column" justifyContent="space-between" alignItems="space-between" sx={{ width: "100%" }}>
                  <Stack direction="column" spacing={4}>
                    <img
                      src={SafeLeaseLogo}
                      alt="SafeLease Logo"
                      style={{
                        height: 80,
                        width: "auto",
                        objectFit: "contain",
                        marginRight: "auto",
                      }}
                    />
                    <Box id="signin-container" sx={{ height: 420, width: "100%", position: "relative" }}>
                      <Slide in={page === "signIn"} appear={true} direction="right" unmountOnExit mountOnEnter>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                          }}
                        >
                          <Slide in={!userRequiresNewPassword} appear={true} direction="right" mountOnEnter unmountOnExit>
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                              }}
                            >
                              <Box>
                                <Typography variant="h4" fontWeight="700">
                                  Welcome back!
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: (theme) => theme.palette.text.secondary,
                                    mb: 4,
                                  }}
                                >
                                  Sign in to your SafeLease account to continue.
                                </Typography>
                              </Box>
                              <SignInForm />
                            </Box>
                          </Slide>
                        </Box>
                      </Slide>
                      <Slide in={userRequiresNewPassword} appear={true} direction="left" unmountOnExit mountOnEnter>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                          }}
                        >
                          <Box>
                            <Typography variant="h4" fontWeight="700">
                              Finish Account Setup
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: (theme) => theme.palette.text.secondary,
                                mb: 4,
                              }}
                            >
                              Please create a password to finish setting up your account.
                            </Typography>
                          </Box>
                          <NewPasswordForm />
                        </Box>
                      </Slide>

                      <Box>
                        <Slide in={page === "forgotPassword"} appear={true} direction="left" unmountOnExit mountOnEnter>
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                            }}
                          >
                            <ForgotPasswordPage />
                          </Box>
                        </Slide>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
