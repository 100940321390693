import { Document, Page, pdfjs } from "react-pdf";
import { useRef, useState } from "react";
import ControlPanel from "./ControlPanel";
import { Box, CircularProgress, Stack, Theme, Typography } from "@mui/material";
import { ExpandedPDFModal } from "../../services/compliance/pages/ExpandedPDFModal";
import { ReportGmailerrorredOutlined } from "@mui/icons-material";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PDFViewerProps {
  file: string;
  handleClose?: () => void;
  expandedView?: boolean;
}

/* Display a PDF, with the ability to scroll, zoom, and navigate pages */
export function PDFViewer({ file, handleClose, expandedView = false }: PDFViewerProps) {
  // Grabs the file extension, removes any query parameters
  const fileExtension = file?.split(".")?.pop()?.split("?")[0] ?? "";
  const isPDF = fileExtension === "PDF" || fileExtension === "pdf";

  const containerRef = useRef(null);

  const [displayExpandedModal, setDisplayExpandedModal] = useState<boolean>(false);

  const [scale, setScale] = useState<number>(1.0);
  const [rotation, setRotation] = useState<number>(0);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(isPDF);
  const [loadFailed, setLoadFailed] = useState<boolean>(false);

  // When a document loads successfully, set the number of pages and stop loading
  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  // When a document fails to load, stop loading
  const onDocumentLoadFailure = () => {
    setIsLoading(false);
    setLoadFailed(true);
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          borderBottom: "1px solid",
          borderColor: (theme: Theme) => theme.palette.divider,
          width: "100%",
        }}
      >
        <ControlPanel
          scale={scale}
          setScale={setScale}
          setRotation={setRotation}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={file}
          expanded={displayExpandedModal}
          handleClose={() => setDisplayExpandedModal(false)}
          handleOpen={() => setDisplayExpandedModal(true)}
          showZoomControls={true}
        />
      </Stack>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          overflow: "scroll",
          flexGrow: 1,
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            // @ts-ignore
            height: containerRef.current?.getBoundingClientRect().height,
            cursor: expandedView ? "zoom-out" : "zoom-in",
          }}
          onClick={() => (expandedView ? handleClose && handleClose() : setDisplayExpandedModal(true))}
        >
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <Box
            sx={{
              transform: `rotate(${rotation}deg)`,
            }}
          >
            {file && !isPDF && (
              <img
                src={file}
                alt="declaration page"
                style={{
                  transform: `scale(${scale})`,
                  height: "100%",
                  width: "100%",
                }}
              />
            )}
            {file && isPDF && !loadFailed && (
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onDocumentLoadFailure}
                onPassword={() => setLoadFailed(true)}
              >
                <Page
                  pageNumber={pageNumber}
                  scale={scale}
                  // @ts-ignore
                  width={containerRef.current?.getBoundingClientRect().width}
                />
              </Document>
            )}
          </Box>
          {loadFailed && isPDF && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Stack direction="column" alignItems="center" spacing={2}>
                <ReportGmailerrorredOutlined sx={{ height: 100, width: "auto", color: (theme: Theme) => theme.palette.warning.main }} />
                <Typography>Failed to load declaration page. Please invalidate policy.</Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
      <ExpandedPDFModal open={displayExpandedModal} handleClose={() => setDisplayExpandedModal(false)} file={file} />
    </Box>
  );
}
