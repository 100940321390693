import { pdfjs } from "react-pdf";
import { useState } from "react";
import { ExpandedPDFModal } from "./ExpandedPDFModal";
import { ImagePreview } from "./ImagePreview";
import { SxProps } from "@mui/material";

// Do not remove, this is the script that loads PDFs
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ImageViewerProps {
  file: string;
  fileName: string;
  fileSize: number | null;
  previewContainerSx?: SxProps;
  archived?: boolean;
  hideOverlay?: boolean;
}

/* Display a PDF, with the ability to scroll, zoom, and navigate pages */
export function ImageViewer({ file, previewContainerSx, fileName, fileSize, archived = false, hideOverlay = false }: ImageViewerProps) {
  const [displayExpandedModal, setDisplayExpandedModal] = useState<boolean>(false);

  return (
    <>
      <ImagePreview
        fileName={fileName}
        fileSize={fileSize}
        previewContainerSx={previewContainerSx}
        file={file}
        handleExpandImage={() => setDisplayExpandedModal(true)}
        archived={archived}
        hideOverlay={hideOverlay}
      />
      <ExpandedPDFModal open={displayExpandedModal} handleClose={() => setDisplayExpandedModal(false)} file={file} fileName={fileName} />
    </>
  );
}
