import { useState } from "react";
import { Theme, Typography, Box, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { PrivatePolicyApi } from "../../../utils/apiInstances/PrivatePolicyApiInstance";
import { SafeleasePrivatePolicy } from "@safelease/service-utilities";

interface PrivatePolicyNotesEditorProps {
  privatePolicy: SafeleasePrivatePolicy;
}

export function PrivatePolicyNotesEditor({ privatePolicy }: PrivatePolicyNotesEditorProps) {
  const [editing, setEditing] = useState(false);
  const [notes, setNotes] = useState(privatePolicy.notes);

  const startEditing = function () {
    setEditing(true);
  };

  const save = async function () {
    await PrivatePolicyApi.updateStatus({
      privatePolicyId: privatePolicy.id,
      notes,
    });

    // Update the UI
    privatePolicy.notes = notes;

    setEditing(false);
  };

  return (
    <div>
      <Typography variant="body1" sx={{ color: (theme: Theme) => theme.palette.grey[600] }}>
        Notes
        <Box sx={{ float: "right", cursor: "pointer" }}>{editing ? <SaveIcon onClick={save} /> : <EditIcon onClick={startEditing} />}</Box>
      </Typography>

      {!editing && <Box sx={{ whiteSpace: "pre-wrap" }}>{notes}</Box>}

      {editing && <TextField value={notes} onChange={(e) => setNotes(e.target.value)} multiline sx={{ width: "100%" }} />}
    </div>
  );
}
