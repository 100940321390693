import { Box, LinearProgressProps } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

interface SafeLeaseLinearProgressProps extends LinearProgressProps {
  styles?: object;
}

export function SafeLeaseLinearProgress({ styles, ...props }: SafeLeaseLinearProgressProps) {
  return (
    <Box sx={{ marginTop: "-20px", width: "100%", ...styles }} {...props}>
      <LinearProgress />
    </Box>
  );
}
