import { FileUploadDropzone } from "../../../../../../../../components/FileUploadDropzone";
import { ImageViewer } from "../../../../../../../../components/ImageViewer";

type FileUploadSectionProps = {
  files: { file: File; previewUrl: string }[];
  handleNewFiles: (files: File[]) => void;
};

function FileUploadSection({ files, handleNewFiles }: FileUploadSectionProps) {
  if (files.length) {
    return <ImageViewer file={files[0].previewUrl} fileName={files[0].file.name} fileSize={files[0].file.size ?? 0} />;
  } else {
    return (
      <FileUploadDropzone
        handleNewFiles={handleNewFiles}
        uploadButtonProps={{
          variant: "outlined",
          color: "navy",
        }}
      />
    );
  }
}

export { FileUploadSection };
