export interface ClaimDetailsForEmailToFacility {
  tenantName: string;
  adjusterName: string;
  unitNumber: string;
  facilityName: string;
  facilityAddress: string;
  causeOfLoss: string;
  dateOfLoss: string;
}

export enum EmailFacilityDropdownValues {
  BURGLARY = "burglary",
  BURGLARY_FOLLOW_UP = "burglaryFollowUp",
  WATER_DAMAGE = "waterDamage",
  GENERIC_BLANK_AD_HOC = "genericBlankAdHoc",
}

const tenantDetailsString = (claimDetails: ClaimDetailsForEmailToFacility) =>
  `${claimDetails.tenantName}%0D%0A%0D%0A${claimDetails.unitNumber}%0D%0A%0D%0A${claimDetails.facilityAddress}%0D%0A%0D%0A${claimDetails.causeOfLoss}%0D%0A%0D%0ADate of loss ${claimDetails.dateOfLoss}%0D%0A%0D%0A`;
const signatureString = (claimDetails: ClaimDetailsForEmailToFacility) =>
  `Best, %0D%0A${claimDetails.adjusterName}%0D%0ASafeLease Claims%0D%0AThis message (including any attachments) may contain confidential, proprietary, privileged and/or private information. The information is intended to be for the use of the individual or entity designated above. If you are not the intended recipient of this message, please notify the sender immediately, and delete the message and any attachments. Any disclosure, reproduction, distribution or other use of this message or any attachments by an individual or entity other than the intended recipient is prohibited.`;
export function generateMailToLinkForEmailToFacility(
  emailTypeValue: EmailFacilityDropdownValues,
  claimDetails: ClaimDetailsForEmailToFacility,
) {
  const email = ""; // TODO: add the ability to get the recipient email from contact info we have on the core app
  let subject: string = "";
  let body: string = "";
  switch (emailTypeValue) {
    case EmailFacilityDropdownValues.BURGLARY:
      subject = `${claimDetails.tenantName} Burglary Claim Information/Documentation Request`;
      body =
        `Hello ${claimDetails.facilityName} Team,%0D%0A%0D%0A` +
        `I hope this message finds you well. My name is ${claimDetails.adjusterName}, and I am the Claims Adjuster for SafeLease. I am currently investigating a burglary claim for one of our tenants and would appreciate your assistance in gathering some critical information.%0D%0A%0D%0A` +
        `Tenant Details:%0D%0A%0D%0A` +
        tenantDetailsString(claimDetails) +
        `To properly document the claim and meet the requirements of the protection plan addendum, we need to confirm visible evidence of forced entry.%0D%0A%0D%0A` +
        `Below are the specific details required to proceed with processing the claim:%0D%0A%0D%0A` +
        `        1. Burglary Details:%0D%0A%0D%0A` +
        `                - Date the burglary occurred%0D%0A` +
        `                - Any specific observations or information related to the incident%0D%0A%0D%0A` +
        `        2. Security Camera Footage or Photos:%0D%0A%0D%0A` +
        `                - Footage or photos showing the burglary, signs of forced entry, or damage to the storage unit, lock, latch, or door%0D%0A%0D%0A` +
        `        3. Internal Incident Report:%0D%0A%0D%0A` +
        `                - If available, please provide a copy of the internal incident report documenting the burglary%0D%0A%0D%0A` +
        `        3. Gate Access Report:%0D%0A%0D%0A` +
        `                - A gate access report for the tenant to help us review entry and exit times related to the incident%0D%0A%0D%0A` +
        `Your prompt cooperation in providing this documentation will help expedite the claim review process. If you have any of the requested information, please reply to this email with the relevant documents.%0D%0A%0D%0A` +
        `Thank you for your time and assistance with this matter. I appreciate your help in moving this claim forward.%0D%0A%0D%0A` +
        signatureString(claimDetails);
      break;
    case EmailFacilityDropdownValues.BURGLARY_FOLLOW_UP:
      subject = `${claimDetails.tenantName} Burglary Follow Up`;
      body =
        `Hello ${claimDetails.facilityName} Team,%0D%0A%0D%0A` +
        `I apologize for reaching out once more, but I wanted to follow up on my request for details regarding the burglary and any documentation to confirm forced entry.%0D%0A%0D%0A` +
        tenantDetailsString(claimDetails) +
        `Thank you very much for your time and assistance.%0D%0A%0D%0A` +
        signatureString(claimDetails);
      break;
    case EmailFacilityDropdownValues.WATER_DAMAGE:
      subject = `${claimDetails.tenantName} Water Damage Claim Information/Documentation Request`;
      body =
        `Hello ${claimDetails.facilityName} Team,%0D%0A%0D%0A` +
        `I hope this message finds you well. My name is ${claimDetails.adjusterName}, and I am the Claims Adjuster for SafeLease. I am currently investigating a water damage claim for one of our tenants and would appreciate your assistance in gathering some critical information.%0D%0A%0D%0A` +
        `Tenant Details:%0D%0A%0D%0A` +
        tenantDetailsString(claimDetails) +
        `The information I’m requesting is crucial to determine whether the water damage is covered, as certain causes of water damage are specifically excluded under the protection plan.%0D%0A%0D%0A` +
        `Below are the specific details required to proceed with processing the claim:%0D%0A%0D%0A` +
        `        1. Cause of Water Entry:%0D%0A%0D%0A` +
        `                - Can you provide information on how the water entered the unit?%0D%0A%0D%0A` +
        `        2. Inspection Details:%0D%0A%0D%0A` +
        `                - Has the storage unit been inspected to identify the source of the water leakage? If so, please provide details on the exact location and source of the water leak.%0D%0A%0D%0A` +
        `        3. Photographs:%0D%0A%0D%0A` +
        `                - Please provide photographs showing where the water entry occurred within the storage unit (e.g., roof, walls, floor).%0D%0A%0D%0A` +
        `        4. Repair Status:%0D%0A%0D%0A` +
        `                - Have any repairs been initiated to address the water leak? If so, please provide details.%0D%0A%0D%0A` +
        `Your prompt cooperation in providing this information will help expedite the review and processing of the claim. If you have any of the requested documentation, please reply to this email with the relevant details.%0D%0A%0D%0A` +
        `Thank you for your time and assistance with this matter. It is greatly appreciated.%0D%0A%0D%0A` +
        signatureString(claimDetails);
      break;
    case EmailFacilityDropdownValues.GENERIC_BLANK_AD_HOC:
      subject = claimDetails.tenantName;
      body =
        "Thank you for providing the information and documentation.%0D%0A%0D%0A" +
        `I appreciate your time and assistance.%0D%0A%0D%0A` +
        tenantDetailsString(claimDetails) +
        `Have a great rest of your day!%0D%0A%0D%0A` +
        signatureString(claimDetails);
      break;
  }
  return `mailto:${email}?subject=${subject}&body=${body}`;
}
