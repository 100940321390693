import { Drawer } from "@mui/material";
import { ClaimDetailsDrawerType, useClaimDetailsDrawerStore } from "./useClaimDetailsDrawerStore";
import { AdjusterNotesDrawer } from "./AdjusterNotesDrawer";
import { TenantCommunicationDrawer } from "./TenantCommunicationDrawer";
import { LineItemsDrawer } from "./LineItemsDrawer";
import { ClaimFormDetailsDrawer } from "./ClaimFormDrawer";
import { AdHocRequestsDrawer } from "./AdHocRequestsDrawer";

/**
 * Controller component for rendering the correct drawer component based on the current drawer type from the store
 */

type DetailsDrawerProps = {};

function DetailsDrawer({}: DetailsDrawerProps) {
  const drawerOpen = useClaimDetailsDrawerStore((state) => state.drawerOpen);
  const currentDrawerType = useClaimDetailsDrawerStore((state) => state.currentDrawerType);
  const closeDrawer = useClaimDetailsDrawerStore((state) => state.closeDrawer);

  const drawerTypeToComponentMap = {
    [ClaimDetailsDrawerType.NOTES]: <AdjusterNotesDrawer />,
    [ClaimDetailsDrawerType.TENANT_MESSAGING]: <TenantCommunicationDrawer />,
    [ClaimDetailsDrawerType.CLAIM_FORM_DETAILS]: <ClaimFormDetailsDrawer />,
    [ClaimDetailsDrawerType.LINE_ITEM_DETAILS]: <LineItemsDrawer />,
    [ClaimDetailsDrawerType.AD_HOC_REQUESTS]: <AdHocRequestsDrawer />,
  };

  return (
    <Drawer open={drawerOpen} onClose={closeDrawer} anchor="right">
      {drawerTypeToComponentMap[currentDrawerType as ClaimDetailsDrawerType]}
    </Drawer>
  );
}

export { DetailsDrawer };
