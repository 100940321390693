import { Theme, Typography, TypographyProps } from "@mui/material";

interface ClickableTypographyProps extends TypographyProps {}

function ClickableTypography({ children, sx = {}, ...props }: ClickableTypographyProps) {
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: 20,
        cursor: "pointer",
        ":hover": { color: "#007BFF" },
        color: (theme: Theme) => theme.palette.blue.dark,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export { ClickableTypography };
