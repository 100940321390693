import { Attachment_ClaimsDbModel, TypeSpecificAttachment_ClaimsDbModel } from "@safelease/service-utilities";
import { ClaimDocumentDisplaySection } from "./ClaimDocumentDisplaySection";
import _ from "lodash";

type TypeSpecificAttachmentSectionProps = {
  allTypeSpecificAttachments: TypeSpecificAttachment_ClaimsDbModel[];
};

function TypeSpecificAttachmentSection({ allTypeSpecificAttachments }: TypeSpecificAttachmentSectionProps) {
  /**
   * Group all type specific attachments by their definition label
   * each specific type of attachment could have multiple attachments
   */
  const typeSpecificAttachmentsGroupedByDefLabel = _.groupBy(allTypeSpecificAttachments, (tsa) => tsa.typeSpecificAttachmentDef?.label);
  return (
    <>
      {Object.entries(typeSpecificAttachmentsGroupedByDefLabel).map(([label, typeSpecificAttachments]) => {
        return (
          <ClaimDocumentDisplaySection
            key={label}
            attachments={
              (typeSpecificAttachments
                ?.map((tsa) => tsa.attachment)
                ?.filter((attachment) => attachment !== undefined) as Attachment_ClaimsDbModel[]) || []
            }
            question={label}
          />
        );
      })}
    </>
  );
}

export { TypeSpecificAttachmentSection };
