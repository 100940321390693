import { Box, Divider, Stack, Typography } from "@mui/material";
import { DrawerHeader } from "../../../SelectedClaimPage/DetailsDrawer/sharedComponents/DrawerHeader";
import dayjs from "dayjs";
import { LegacyClaim } from "@safelease/service-utilities";
import { ClaimDocumentDisplaySection } from "../../../SelectedClaimPage/DetailsDrawer/ClaimFormDrawer/ClaimDocumentDisplaySection";

type ClaimFormDrawerProps = {
  legacyClaim: LegacyClaim;
};

function ClaimFormDrawer({ legacyClaim }: ClaimFormDrawerProps) {
  return (
    <Stack sx={{ width: 600, height: "100%", padding: "16px" }} spacing={2} divider={<Divider />}>
      <DrawerHeader
        legacyClaim
        title="Claim submission form"
        subTitle={`Submitted on ${legacyClaim.claimDate ? dayjs(legacyClaim.claimDate).format("MM/DD/YY") : "N/A"}`}
      />
      <Stack overflow="auto" spacing={3} divider={<Divider />} padding={1}>
        <ClaimDocumentDisplaySection question="Billing Ledger" attachments={legacyClaim.billingLedgerAttachments} />
        <ClaimDocumentDisplaySection question="Protection plan addendum" attachments={legacyClaim.protectionPlanAttachments} />
        <QuestionDisplay question="Current on payment" answer={legacyClaim.currentOnPayment ? "Yes" : "No"} />
        {legacyClaim.department && <QuestionDisplay question="Department" answer={legacyClaim.department} />}
        {legacyClaim.dateOfReport && <QuestionDisplay question="Date of report" answer={legacyClaim.dateOfReport} />}
        {legacyClaim.reportNumber && <QuestionDisplay question="Report number" answer={legacyClaim.reportNumber} />}
        <QuestionDisplay question="Has protection plan" answer={legacyClaim.hasPlan ? "Yes" : "No"} />
        <QuestionDisplay question="Cause description" answer={legacyClaim.causeDescription || "N/A"} />
        <QuestionDisplay question="How discovered" answer={legacyClaim.howDiscovered || "N/A"} />
      </Stack>
    </Stack>
  );
}

function QuestionDisplay({ question, answer }: { question: string; answer: string }) {
  return (
    <Stack>
      <Stack>
        <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Question</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{question}</Typography>
      </Stack>
      <Stack paddingLeft={3}>
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "&::before": {
                content: '"•"',
                display: "inline-block",
                marginRight: "6px",
                fontSize: 20,
              },
            }}
          >
            <Typography sx={{ fontSize: 12, fontWeight: 500, color: "grey.A200" }}>Answer</Typography>
          </Box>
          <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
            {/* as of now we only allow for one response per question but this could change in the future */}
            {answer}
          </Typography>
        </>
      </Stack>
    </Stack>
  );
}

export { ClaimFormDrawer };
