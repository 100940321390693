// UI - libs
import { Button, Card, Grid, Stack } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-premium";

// UI - internal
import Error from "../../../components/SafeLeaseError";
import { SafeLeaseLinearProgress as Loader } from "../../../components/SafeLeaseLinearProgress";
import { SafeLeaseDataGrid } from "@safelease/components";
import { jobsErrorReportGridColDefs } from "./jobsErrorCenterColumnDefs";
import JobErrorDetailsDrawer from "./JobErrorDetailsDrawer/JobErrorDetailsDrawer";
import JobsErrorCenterMetricCard from "./JobsErrorCenterMetricCard";
import { navyButton } from "../../../utils/styles/mui-overrides";
// Hooks & State
// Data
// Libs
import dayjs from "dayjs";
import React, { useEffect } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";
import { useJobsErrorCenter, useJobsErrorCenterState } from "../useJobsErrorCenterStore";
import { TransitionColumnDef } from "../../../types/JobErrorCenter/TransitionColumnDef";
import { useQuery } from "@tanstack/react-query";
import { JobError } from "@safelease/service-utilities/types/Jobs/JobResponses";
import { TitleHeader } from "../../../components/ServiceUIComponents/JobErrorService/TitleHeader";

// Report summarizing the errors experienced in jobs
// Meat is in the table
const JobsErrorCenter: React.FC = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const { visibleColumns, updateVisibleColumns, selectedJobError, setSelectedJobError } = useJobsErrorCenterState();

  const { jobs, fetchJobErrors, shouldUpdateSelectedJobError } = useJobsErrorCenter();

  const queryResult = useQuery({
    queryKey: ["jobs-error-report"],
    queryFn: fetchJobErrors,
  });

  useEffect(() => {
    if (queryResult.data) {
      shouldUpdateSelectedJobError(selectedJobError, jobs);
    }
  }, [queryResult.data, selectedJobError]);

  if (queryResult.isError) return <Error />;
  if (queryResult.isPending) return <Loader />;

  const remapJobRows = (jobs: JobError[]) => {
    return jobs.map((job: JobError) => {
      return {
        ...job,
        navigate,
        fetchJobErrors,
      };
    });
  };

  const remappedColumns: GridColDef[] = jobsErrorReportGridColDefs.map(
    ({ field, headerName, key, title, renderer, styles = {}, ...rest }: TransitionColumnDef) => ({
      field: field ? field : key,
      headerName: headerName ? headerName : title,
      ...(styles?.width ? { width: styles?.width } : {}), // Apply width if present
      ...(renderer
        ? {
            renderCell: (params: GridRenderCellParams<Number>) => renderer(params.value, { row: params.row }),
          }
        : {}), // Render a different UI for the cell if a renderer is specified
      ...rest,
    }),
  );

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TitleHeader title="Jobs Error Center" sx={{ mb: 1 }} />
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/engineering-error-report");
          }}
          disableElevation
          sx={{ ...navyButton }}
        >
          Engineering Error Report
        </Button>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={jobs.filter((error: JobError) => error.triagingStatus === "Critical").length}
            label="Critical errors"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={jobs.filter((error: JobError) => error.triagingStatus === "Critical" || error.triagingStatus === "Medium").length}
            label="Jobs in failure state"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <JobsErrorCenterMetricCard
            value={
              jobs.filter((error: JobError) => {
                const dateInstance = dayjs(error.failedAt);
                const daysOld = dayjs().diff(dateInstance, "day");
                return daysOld > 10;
              }).length
            }
            label="Errors over 10 days old"
          />
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{
              minHeight: size.height - 360,
            }}
            elevation={0}
          >
            <SafeLeaseDataGrid
              sx={{
                borderColor: "#EBEFF7",
                "& .MuiDataGrid-cell": {
                  borderColor: "#EBEFF7",
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none", // Removes cell selection outline
                },
              }}
              columns={remappedColumns}
              rows={remapJobRows(jobs)}
              autosizeOnMount
              getRowHeight={() => "auto"}
              editMode="cell"
              onRowClick={(params) => {
                setSelectedJobError(params.row);
              }}
              columnVisibilityModel={visibleColumns}
              onColumnVisibilityModelChange={(newModel) => updateVisibleColumns(newModel)}
              initialState={{
                pinnedColumns: {
                  right: ["id"],
                },
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <JobErrorDetailsDrawer fetchJobErrors={fetchJobErrors} />
    </div>
  );
};

export default JobsErrorCenter;
