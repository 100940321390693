import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { BaseActionView } from "./BaseActionView";
import { useDataChangeManager } from "../../useDataChangeManager";
import { RelationshipDropDown } from "../RelationshipDropDown";

interface UpdateRelationshipBillingRequiredViewProps {}

function UpdateRelationshipBillingRequiredView({}: UpdateRelationshipBillingRequiredViewProps) {
  const { relationshipBillingRequired, relationshipSelectorId, setRelationshipBillingRequired } = useDataChangeManager();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setRelationshipBillingRequired(value === "true");
  };

  return (
    <BaseActionView actionTitle="Update Relationship Billing Required">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormControl variant="outlined">
          <InputLabel id="relationshipBillingRequired">Select Value</InputLabel>
          <Select
            id="locationExemptFromSfdcPushing"
            labelId="locationExemptFromSfdcPushing"
            value={relationshipBillingRequired.toString()}
            label="Select Value"
            onChange={handleSelectChange}
            sx={{ minWidth: "250px", width: "50vw" }}
            disabled={!relationshipSelectorId}
          >
            <MenuItem value={"false"}>False</MenuItem>
            <MenuItem value={"true"}>True</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </BaseActionView>
  );
}

export { UpdateRelationshipBillingRequiredView };
