import { useEffect, useState } from "react";
import { useDebounce } from "../../../../../../../../../hooks/useDebounce.tsx";
import { Stack, TextareaAutosize, Typography } from "@mui/material";
import { ClaimsApi } from "../../../../../../../../../utils/apiInstances/ClaimsApiInstance.ts";
import { useClaimDocumentViewerModalStore } from "../../useClaimDocumentViewerModalStore.ts";

type DocumentNotesProps = {};

function DocumentNotes({}: DocumentNotesProps) {
  const { selectedDocument, updateSelectedDocument } = useClaimDocumentViewerModalStore((state) => ({
    selectedDocument: state.selectedDocument,
    updateSelectedDocument: state.updateSelectedDocument,
  }));

  const [notes, setNotes] = useState(selectedDocument?.notes || "");

  const debouncedNotes = useDebounce(notes, 1000);

  useEffect(() => {
    if (!selectedDocument) return;
    ClaimsApi.updateDocumentNotes(selectedDocument.id, { notes }).then((response) => {
      if (response.data.notes !== selectedDocument.notes) {
        updateSelectedDocument({ ...selectedDocument, notes: response.data.notes });
      }
    });
  }, [debouncedNotes]);

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Notes</Typography>
      <TextareaAutosize
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Add notes..."
        minRows={4}
        maxRows={16}
        style={{ borderRadius: 4, padding: 9, borderColor: "#CBCBCB" }}
      />
    </Stack>
  );
}

export { DocumentNotes };
