import { Box, Stack, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseFormTextField } from "@safelease/components";
import { ForgotPasswordStages, useForgotPasswordPageStore, useSignInPageStore } from "./useForgotPasswordPageStore";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { enqueueSnackbar } from "notistack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { resetPassword } from "aws-amplify/auth";

type EmailSubmitContentProps = {};

type ForgotPasswordFormValues = {
  email: string;
};

const validationSchema = z.object({
  email: z.string().min(1, "Email is required.").email(),
});

function EmailSubmitContent({}: EmailSubmitContentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { setPage } = useSignInPageStore();
  const { setForgotPasswordStage, setEmail } = useForgotPasswordPageStore((state) => ({
    setForgotPasswordStage: state.setForgotPasswordStage,
    setEmail: state.setEmail,
  }));

  const methods = useForm<ForgotPasswordFormValues>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const goBackToSignIn = () => {
    setPage("signIn");
  };

  const onButtonClicked: SubmitHandler<ForgotPasswordFormValues> = async (data) => {
    setLoading(true);
    try {
      await resetPassword({ username: data.email });
      setEmail(data.email);
      setForgotPasswordStage(ForgotPasswordStages.NEW_PASSWORD_SUBMIT);
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onButtonClicked)}>
        <Stack spacing={3} alignItems="center">
          <Stack spacing={0.5} alignItems="center">
            <Typography sx={{ fontSize: 23, fontWeight: 500 }}>Forgot Password?</Typography>
            <Typography sx={{ fontSize: 14, color: "rgba(146, 146, 146, 1)" }}>No worries, we'll send you a reset code</Typography>
          </Stack>
          <Box>
            <SafeLeaseFormTextField
              label="Email"
              name="email"
              placeholder="Enter your email"
              sx={{ width: 400 }}
              helperText={methods.formState.errors.email?.message}
              error={!!methods.formState.errors.email?.message}
            />
          </Box>
          <SafeLeaseButton type="submit" variant="contained" size="small" fullWidth loading={loading}>
            Reset password
          </SafeLeaseButton>
          <Box>
            <SafeLeaseButton
              startIcon={<ArrowBackIcon />}
              onClick={goBackToSignIn}
              sx={{ "&:focus": { outline: "none" } }}
              color="navy"
              size="small"
            >
              Back to login
            </SafeLeaseButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
}

export { EmailSubmitContent };
