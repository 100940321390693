import { SafeleaseLeaseMatchingData, SafeleaseLocationMatchData } from "@safelease/service-utilities";
import { create } from "zustand";

export enum UnitSelectionModalStep {
  FACILITY_LOCATION_SEARCH = "facilityLocationSearch",
  EMAIL_UNIT_LOOKUP = "emailUnitLookup",
  TENANT_NAME_LOOKUP = "tenantNameLookup",
  UNIT_SELECTION = "unitSelection",
  INCOMPLETE_DATA_SUBMISSION = "incompleteDataSubmission",
}

type UnitSelectionModalStore = {
  unitSelectionModalOpen: boolean;
  setUnitSelectionModalOpen: (unitSelectionModalOpen: boolean) => void;
  currentStep: UnitSelectionModalStep;
  setCurrentStep: (currentStep: UnitSelectionModalStep) => void;
  progress: number;
  setProgress: (progress: number) => void;
  selectedLocation: SafeleaseLocationMatchData | null;
  setSelectedLocation: (selectedLocation: SafeleaseLocationMatchData | null) => void;
  leaseMatchingData: SafeleaseLeaseMatchingData[] | null;
  setLeaseMatchingData: (leaseMatchingData: SafeleaseLeaseMatchingData[]) => void;
  resetStore: () => void;
};

export const useUnitSelectionModalStore = create<UnitSelectionModalStore>()((set) => ({
  unitSelectionModalOpen: false,
  setUnitSelectionModalOpen: (unitSelectionModalOpen) => {
    set({ unitSelectionModalOpen });
  },
  currentStep: UnitSelectionModalStep.FACILITY_LOCATION_SEARCH,
  setCurrentStep: (currentStep) => {
    set({ currentStep });
  },
  progress: 0,
  setProgress: (progress) => {
    set({ progress });
  },
  selectedLocation: null,
  setSelectedLocation: (selectedLocation) => {
    set({ selectedLocation });
  },
  leaseMatchingData: null,
  setLeaseMatchingData: (leaseMatchingData) => {
    set({ leaseMatchingData });
  },
  resetStore: () => {
    set({
      currentStep: UnitSelectionModalStep.FACILITY_LOCATION_SEARCH,
      progress: 0,
      selectedLocation: null,
      leaseMatchingData: null,
    });
  },
}));
