import { Box, CircularProgress, Divider, Drawer, IconButton, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { ProspectsApi } from "../../../utils/apiInstances/ProspectsApiInstance.ts";
import { Close as CloseIcon, HouseSiding, Laptop, Monitor } from "@mui/icons-material";
import { FiveStarRating } from "../../../components/FiveStarRating.tsx";
import dayjs from "dayjs";

export function ProspectDetailsDrawer() {
  const params = useParams();
  const navigate = useNavigate();
  const prospectId = params["id"];

  if (!prospectId) {
    return null;
  }

  const { data, isLoading } = useQuery({
    queryKey: ["getProspectById", prospectId],
    queryFn: async () => {
      const response = await ProspectsApi.getProspectById(prospectId);
      return response.data;
    },
  });

  const prospectDetails = [
    {
      value: data?.coreFms || "",
      icon: <Laptop />,
      link: false,
    },
    {
      value: `REIT: ${data?.inspectedReit || "—"}`,
      icon: <HouseSiding />,
      link: false,
    },
    {
      value: data?.placesWebsite || "—",
      icon: <Monitor />,
      link: true,
    },
  ];

  return (
    <Drawer anchor="right" onClose={() => navigate("/prospects")} open={!!prospectId} PaperProps={{ sx: { width: "50%" } }}>
      {isLoading || !data ? (
        <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ backgroundColor: "white", p: 2 }}>
              <Typography variant="h4">{data.placesName ? data.placesName : data.sfdcName || "—"}</Typography>
              <Typography variant="caption" sx={{ color: "gray" }}>
                {`Places address: ${data.placesAddress} | Salesforce address: ${data.sfdcAddress}`}
              </Typography>
            </Box>
            <IconButton sx={{ position: "absolute", top: 16, right: 16 }} disabled={false} onClick={() => navigate("/prospects")}>
              <CloseIcon />
            </IconButton>
            <Box padding={2}>
              <Grid item container justifyContent={"space-between"} alignItems={"center"}>
                {data.placesRating && data.placesReviewCount && (
                  <Grid item>
                    <FiveStarRating rating={data.placesRating} count={data.placesReviewCount} />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="caption" sx={{ color: "gray" }}>
                    Last assessed on {dayjs(data?.sfdcLastReadAt).format("MM/DD/YY")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container padding={2} rowGap={1}>
            {prospectDetails.map((detail) =>
              detail.value ? (
                <Grid item container key={detail.value} columnGap={1}>
                  {detail.icon}
                  {detail.link ? (
                    <Link onClick={() => window.open(`${detail.value}`, "_blank")}>Website</Link>
                  ) : (
                    <Typography variant="body1">{detail.value}</Typography>
                  )}
                </Grid>
              ) : null,
            )}
            <Typography variant="body1">{`Account owner: ${data.sfdcAccountOwner}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
}
