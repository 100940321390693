import { Divider, Stack, Theme, Typography } from "@mui/material";
import { useState } from "react";
import { SafeLeaseButton } from "@safelease/components";
import { UnitSelectionModalStep, useUnitSelectionModalStore } from "../useUnitSelectionModalStore";
import { SafeleaseLocationMatchData } from "@safelease/service-utilities";
import { BorderedCheckbox } from "../../../../../../../../components/ServiceUIComponents/ClaimsService/BorderedCheckbox";
import { AddressInput } from "./AddressInput";
import { IdInput } from "./IdInput";
import { AxiosResponse } from "axios";
import * as Sentry from "@sentry/react";
import { enqueueSnackbar } from "notistack";

type LocationSearchStepProps = {};

function LocationSearchStep({}: LocationSearchStepProps) {
  const { setSelectedLocation, setCurrentStep, setProgress } = useUnitSelectionModalStore((state) => ({
    setSelectedLocation: state.setSelectedLocation,
    setCurrentStep: state.setCurrentStep,
    setProgress: state.setProgress,
  }));
  const [noLocationMatched, setNoLocationMatched] = useState<boolean>(false);
  const [matchedLocation, setMatchedLocation] = useState<SafeleaseLocationMatchData | null>(null);
  const [matchedLocationSelected, setMatchedLocationSelected] = useState<boolean>(false);
  const [currentSearchMethod, setCurrentSearchMethod] = useState<"address" | "id">("address");

  const onContinueButtonClick = () => {
    setSelectedLocation(matchedLocation);
    setCurrentStep(UnitSelectionModalStep.EMAIL_UNIT_LOOKUP);
    setProgress(50);
  };

  const clearFormState = () => {
    setMatchedLocationSelected(false);
    setNoLocationMatched(false);
    setMatchedLocation(null);
    setSelectedLocation(null);
  };

  const toggleSearchMethod = () => {
    clearFormState();
    setCurrentSearchMethod((currentMethod) => (currentMethod === "address" ? "id" : "address"));
  };

  /**
   * This is a shared callback function that will be used by both the address and the id input components
   * the apiCall is the only thing that changes between the two components and that is set the the respective api call that will be awaited in this function
   * example of the api call: ClaimsApi.getSafeleaseLocationForGooglePlaceId(facilityLocationUserInput.place_id)
   */
  const onSearchButtonClickCallback = async (
    apiCall: Promise<AxiosResponse<SafeleaseLocationMatchData, any>>,
    setIsLoading: (loading: boolean) => void,
  ) => {
    setIsLoading(true);
    clearFormState();
    setIsLoading(true);
    try {
      const response = await apiCall;
      const matchedLocation = response.data;
      setMatchedLocation(matchedLocation);
    } catch (error: any) {
      if (error.response?.data?.type === "LocationNotFound") {
        setNoLocationMatched(true);
      } else {
        Sentry.captureException(error);
        enqueueSnackbar("There was an error searching for your location. Please try again", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={3} width="100%">
      <Stack spacing={0.5}>
        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Find the facility</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 500, color: (theme: Theme) => theme.palette.grey.A200 }}>
          {currentSearchMethod === "address" ? "Enter the storage facility’s address below." : "Enter the location id below."}
        </Typography>
      </Stack>
      {currentSearchMethod === "address" ? (
        <AddressInput onSearchButtonClickCallback={onSearchButtonClickCallback} />
      ) : (
        <IdInput onSearchButtonClickCallback={onSearchButtonClickCallback} />
      )}
      <Typography
        onClick={toggleSearchMethod}
        sx={{ color: (theme: Theme) => theme.palette.grey.A200, cursor: "pointer", fontSize: 14, fontWeight: 500, alignSelf: "center" }}
      >
        {currentSearchMethod === "address" ? "Search by location id instead?" : "Search by address instead?"}
      </Typography>
      {/* Successfully matched a facility */}
      {matchedLocation && (
        <Stack spacing={1.5}>
          <Divider />
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>👋🏼 We found a matching address</Typography>
          <BorderedCheckbox
            value={matchedLocationSelected}
            setValue={setMatchedLocationSelected}
            label={`${matchedLocation.address} ${matchedLocation.city}, ${matchedLocation.state} ${matchedLocation.postalCode}`}
          />
        </Stack>
      )}
      {noLocationMatched && (
        <Typography sx={{ color: "error.main", fontSize: 14, fontWeight: 500 }}>
          No location found for the given address, please make sure the address is correct and try again
        </Typography>
      )}
      {matchedLocationSelected && (
        <SafeLeaseButton onClick={onContinueButtonClick} color="navy" variant="contained">
          Continue
        </SafeLeaseButton>
      )}
    </Stack>
  );
}

export { LocationSearchStep };
