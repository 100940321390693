import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { BaseActionView } from "./BaseActionView";
import { useDataChangeManager } from "../../useDataChangeManager";
import { StyledTextField } from "../DataChangeManagerStyledComponents";
import { LocationDropdown } from "../LocationDropDown";
import useActionHandlers from "../../useActionHandlers";
import { RelationshipDropDown } from "../RelationshipDropDown";
import { filterLocationsForRelationship } from "../../../../utils/helpers/filter-locations-relationship";

interface CredentialHelperProps {}

function CredentialHelper({}: CredentialHelperProps) {
  const {
    relationshipSelectorId,
    actionType,
    selectedLocationIds,
    credentials,
    setTextFieldState,
    setCredential,
    initializeCredentials,
    getAllLocationsQuery,
    getAccountForLocationQuery,
  } = useDataChangeManager();
  const { handleTextFieldChange } = useActionHandlers();
  const { data: accountData, refetch: refetchGetAccountForLocationQuery } = getAccountForLocationQuery;

  // Fetch account data when a location is selected
  useEffect(() => {
    if (actionType === "updateAccountCredentials" && selectedLocationIds.length) {
      refetchGetAccountForLocationQuery();
    }
  }, [selectedLocationIds]);

  useEffect(() => {
    // Initialize credential fields after account data is fetched
    if (accountData?.credentials && typeof accountData.credentials === "object") {
      initializeCredentials(accountData.credentials);
      setTextFieldState("accountId", accountData.id.toString());
    }
  }, [accountData]);

  // Update the credentialFields state as users edit any field
  const handleCredentialFieldChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCredential(key, value);
  };

  // Map the credentials to text fields dynamically
  // Account credentials are stored as an object with key-value pairs
  // Ex: {"url": "https://www.singingriverstorage.com", "password": "password-here", "username": "safelease"}
  // Each key-value pair will be mapped to a text field
  const mapCredentialsToTextFields = () => {
    return Object.keys(credentials).map((key) => (
      <Grid item xs={12} sm={12} md={12} lg={12} key={key}>
        <StyledTextField
          id={key}
          label={key}
          variant="outlined"
          onChange={handleCredentialFieldChange(key)}
          value={credentials[key] || ""}
        />
      </Grid>
    ));
  };

  return (
    <BaseActionView actionTitle="Credential Helper">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RelationshipDropDown />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LocationDropdown
          locations={filterLocationsForRelationship(getAllLocationsQuery?.data, relationshipSelectorId) || []}
          sx={{ minWidth: "250px", width: "50vw" }}
          disabled={!relationshipSelectorId}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTextField
          id="accountId"
          label="Account ID"
          variant="outlined"
          onChange={handleTextFieldChange}
          disabled={true}
          value={accountData?.id || ""}
          InputLabelProps={{
            shrink: true, // Force the label to always shrink (stay at the top)
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <StyledTextField
          id="accountType"
          label="Account Type"
          variant="outlined"
          onChange={handleTextFieldChange}
          disabled={true}
          value={accountData?.type || ""}
          InputLabelProps={{
            shrink: true, // Force the label to always shrink (stay at the top)
          }}
        />
      </Grid>
      {/* Conditionally render credential fields if account credentials are not null */}
      {accountData?.credentials && mapCredentialsToTextFields()}
    </BaseActionView>
  );
}

export { CredentialHelper };
