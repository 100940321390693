import { Stack } from "@mui/material";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AuthServiceApi } from "../../../../../utils/apiInstances/AuthServiceApiInstance";
import { enqueueSnackbar } from "notistack";
import { UserType } from "@aws-sdk/client-cognito-identity-provider";
import { StyledToolEnabledToggleFormElement } from "../../../../../components/ServiceUIComponents/UserManagementService/StyledToolEnabledToggleFormElement";
import { BugReport, LockReset, Email } from "@mui/icons-material";
import { SafeLeaseButton } from "@safelease/components";

type UserAttributeFormValues = {
  email: string;
  phone_number: string;
  temporaryPassword: string;
  isDebuggingEnabled: "true" | "false";
  needsPasswordReset: "true" | "false";
  isEmailVerified: "true" | "false";
};

type UserAttributeEditFormProps = {
  user: UserType;
  setOpen: (open: boolean) => void;
};

function UserAttributeEditForm({ user, setOpen }: UserAttributeEditFormProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const useFormMethods = useForm<UserAttributeFormValues>({
    defaultValues: {
      isDebuggingEnabled:
        (user.Attributes?.find((attr) => attr.Name === "custom:isDebuggingEnabled")?.Value as "true" | "false") ?? "false",
      needsPasswordReset:
        (user.Attributes?.find((attr) => attr.Name === "custom:needsPasswordReset")?.Value as "true" | "false") ?? "false",
      isEmailVerified: (user.Attributes?.find((attr) => attr.Name === "custom:isEmailVerified")?.Value as "true" | "false") ?? "false",
    },
  });

  const { handleSubmit, control } = useFormMethods;

  const onUpdateButtonClicked: SubmitHandler<UserAttributeFormValues> = async (data) => {
    setLoading(true);

    const userEmail = user.Attributes?.find((attr) => attr.Name === "email")?.Value ?? "";
    try {
      // loop through all custom attributes and update each one
      for (const [key, value] of Object.entries(data)) {
        await AuthServiceApi.updateCustomAttributeForTenantUser({
          email: userEmail,
          customAttributeName: key,
          newValue: value,
        });
      }
      enqueueSnackbar("User updated successfully.", { variant: "success" });
      window.location.reload(); // very important. this will cause the page to reload and the user to be updated in the table by causing a refetch of the users list
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message ?? "Something went wrong. Please try again later.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...useFormMethods}>
      <form onSubmit={handleSubmit(onUpdateButtonClicked)} style={{ height: "100%" }}>
        <Stack height="100%" spacing={2}>
          <StyledToolEnabledToggleFormElement
            name="isDebuggingEnabled"
            label="Session debugging enabled"
            control={control}
            icon={<BugReport />}
          />
          <StyledToolEnabledToggleFormElement
            name="needsPasswordReset"
            label="Needs password reset"
            control={control}
            icon={<LockReset />}
          />
          <StyledToolEnabledToggleFormElement name="isEmailVerified" label="Email verified" control={control} icon={<Email />} />
          <Stack direction="row" spacing={1}>
            <SafeLeaseButton onClick={() => setOpen(false)} variant="outlined" color="navy" sx={{ width: "100%" }} disabled={loading}>
              Cancel
            </SafeLeaseButton>
            <SafeLeaseButton type="submit" variant="contained" sx={{ width: "100%" }} loading={loading}>
              Update
            </SafeLeaseButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}

export default UserAttributeEditForm;
