import { Box, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { GetClaimMessageResponse } from "@safelease/service-utilities";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { SafeLeaseAvatarWithText } from "@safelease/components";
import { TenantActionTypes } from "@safelease/service-utilities/enums";
import { ImageViewer } from "../../../../../../../../components/ImageViewer";

type AdHocRequestMessageProps = {
  data: GetClaimMessageResponse;
};

function AdHocRequestMessage({ data }: AdHocRequestMessageProps) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <Tooltip title={data.tenantAction?.completed ? "Tenant has answered" : "Waiting on tenant to reply to this ad-hoc request"}>
          {data.tenantAction?.completed ? (
            <CheckCircleOutlineIcon sx={{ color: "rgba(37, 155, 36, 1)" }} />
          ) : (
            <WatchLaterOutlinedIcon sx={{ color: "#EED202" }} />
          )}
        </Tooltip>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: (theme: Theme) => theme.palette.grey.A200,
            whiteSpace: "pre-wrap", // make sure new lines are displayed
            overflowWrap: "break-word",
          }}
        >
          {data.tenantAction?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.requestMessage}
        </Typography>
      </Stack>
      {data.tenantAction?.completed && (
        <Stack paddingLeft={4} spacing={1}>
          <SafeLeaseAvatarWithText
            text={`${data?.tenantAction.recipientCognitoUserDetail?.userFirstName} ${data?.tenantAction.recipientCognitoUserDetail?.userLastName}`}
          />
          <Typography sx={{ fontSize: 14, fontWeight: 500, whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
            {data.tenantAction?.metadata?.[TenantActionTypes.AD_HOC_REQUEST]?.textResponse}
          </Typography>
          <Stack direction="row" spacing={2} paddingY={1} overflow="auto" sx={{ width: "100%" }}>
            {data.tenantAction.uploadedAttachments?.map((attachment) => (
              <Box key={attachment.id}>
                <ImageViewer
                  fileName={attachment.fileName}
                  file={attachment.url}
                  fileSize={attachment.fileSize}
                  archived={!attachment.deletedAt}
                />
              </Box>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export { AdHocRequestMessage };
