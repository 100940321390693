import { Box } from "@mui/material";
import { SafeLeaseButton } from "@safelease/components";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useNewRequestModalStore } from "./NewRequestModal/useNewRequestModalStore";

type NewRequestButtonSectionProps = {};

function NewRequestButtonSection({}: NewRequestButtonSectionProps) {
  const setNewRequestModalOpen = useNewRequestModalStore((state) => state.setNewRequestModalOpen);
  return (
    <Box>
      <SafeLeaseButton onClick={() => setNewRequestModalOpen(true)} startIcon={<AddBoxOutlinedIcon />} variant="text" color="navy">
        New request
      </SafeLeaseButton>
    </Box>
  );
}

export { NewRequestButtonSection };
