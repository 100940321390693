import { Stack, Typography } from "@mui/material";
import { PolicyList } from "./PolicyList";
import { GetPolicyDataForClaimResponse, Policy } from "@safelease/service-utilities";
import { SafeLeaseButton } from "@safelease/components";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import { ClaimsApi } from "../../../../../../../../utils/apiInstances/ClaimsApiInstance";
import { useParams } from "react-router";
import { UseQueryResult } from "@tanstack/react-query";

type SelectPolicyProps = {
  allPolicies: Policy[];
  existingSelectedPolicy: Policy | null;
  setModalOpen: (open: boolean) => void;
  setModalState: (state: "select" | "create") => void;
  policyDataForClaimQuery: UseQueryResult<GetPolicyDataForClaimResponse, Error>;
};

function SelectPolicy({ allPolicies, existingSelectedPolicy, setModalOpen, setModalState, policyDataForClaimQuery }: SelectPolicyProps) {
  const { claimId } = useParams();
  if (!claimId) return null;
  const [newSelectedPolicyOption, setNewSelectedPolicyOption] = useState(existingSelectedPolicy);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdatePolicy = async () => {
    try {
      setIsLoading(true);
      if (!newSelectedPolicyOption) throw new Error("No policy selected but update button was clicked");
      // Only call the update api if the selected policy is different from the existing one
      if (newSelectedPolicyOption.id !== existingSelectedPolicy?.id) {
        await ClaimsApi.updateSelectedPolicyForClaim(claimId, newSelectedPolicyOption);
        policyDataForClaimQuery.refetch();
        enqueueSnackbar("Policy updated", { variant: "success" });
      }
      setModalOpen(false);
    } catch (error) {
      enqueueSnackbar("Error updating policy", { variant: "error" });
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Stack spacing={4} sx={{ flex: 1, overflow: "auto" }}>
      <Typography sx={{ fontWeight: 600, fontSize: 18 }}>Select a Policy</Typography>
      <PolicyList
        allPolicies={allPolicies}
        newSelectedPolicyOption={newSelectedPolicyOption}
        setNewSelectedPolicyOption={setNewSelectedPolicyOption}
        setModalState={setModalState}
      />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <SafeLeaseButton variant="outlined" color="navy" onClick={() => setModalOpen(false)} disabled={isLoading}>
          Cancel
        </SafeLeaseButton>
        <SafeLeaseButton variant="contained" color="navy" onClick={handleUpdatePolicy} loading={isLoading}>
          Update
        </SafeLeaseButton>
      </Stack>
    </Stack>
  );
}

export { SelectPolicy };
