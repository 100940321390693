import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { useSelectedClaimPageStore } from "../useSelectedClaimPageStore";
import { SafeLeaseButton } from "@safelease/components";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { ClaimDetailsDrawerType, useClaimDetailsDrawerStore } from "../DetailsDrawer/useClaimDetailsDrawerStore";
import { formatCurrency, shortUuid } from "@safelease/components/src/utils/formatters";
import { ImageViewer } from "../../../../../components/ImageViewer";
import { LineItemOptions } from "./LineItemOptions";

type LineItemsTableProps = {};

function LineItemsTable({}: LineItemsTableProps) {
  const claim = useSelectedClaimPageStore((state) => state.claim);
  const setDrawerOpen = useClaimDetailsDrawerStore((state) => state.setDrawerOpen);
  const setCurrentDrawerType = useClaimDetailsDrawerStore((state) => state.setCurrentDrawerType);

  const setSelectedLineItemId = useClaimDetailsDrawerStore((state) => state.setSelectedLineItemId);

  const setFocusAdjusterValueInput = useClaimDetailsDrawerStore((state) => state.setFocusAdjusterValueInput);

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>Claimed items</Typography>
      <TableContainer elevation={0} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                borderBottom: "2px solid rgba(235, 239, 247, 1)", // Use your desired color and thickness
              }}
            >
              <TableCell>Item ID</TableCell>
              <TableCell>Item title</TableCell>
              <TableCell>Requested payout</TableCell>
              <TableCell>Adjuster value</TableCell>
              <TableCell>Proof of purchase</TableCell>
              <TableCell>Proof of ownership</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claim?.lineItems?.map((lineItem) => {
              const proofOfOwnershipFiles = lineItem.proofOfOwnershipFiles?.filter((file) => !file.attachment?.deletedAt) || [];
              const proofOfOwnershipFile = proofOfOwnershipFiles.length ? proofOfOwnershipFiles[0]?.attachment : null;
              const proofOfPurchaseFiles = lineItem.proofOfPurchaseFiles?.filter((file) => !file.attachment?.deletedAt) || [];
              const proofOfPurchaseFile = proofOfPurchaseFiles.length ? proofOfPurchaseFiles[0]?.attachment : null;
              return (
                <TableRow
                  key={lineItem.id}
                  sx={{
                    "&:not(:last-child)": {
                      borderBottom: "2px solid rgba(235, 239, 247, 1)",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {shortUuid(lineItem.id)}
                  </TableCell>
                  <TableCell>{lineItem.name}</TableCell>
                  <TableCell>{formatCurrency(lineItem.requestedAmount / 100)}</TableCell>
                  <TableCell>
                    {lineItem.adjusterValue ? (
                      formatCurrency(lineItem.adjusterValue / 100)
                    ) : (
                      <SafeLeaseButton
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setCurrentDrawerType(ClaimDetailsDrawerType.LINE_ITEM_DETAILS);
                          setSelectedLineItemId(lineItem.id);
                          setFocusAdjusterValueInput(true);
                          setDrawerOpen(true);
                        }}
                      >
                        Add value
                      </SafeLeaseButton>
                    )}
                  </TableCell>
                  <TableCell>
                    {proofOfPurchaseFile ? (
                      <ImageViewer
                        fileName={proofOfPurchaseFile?.fileName || ""}
                        file={proofOfPurchaseFile?.url as string}
                        fileSize={proofOfPurchaseFile?.fileSize ?? null}
                        archived={!!proofOfPurchaseFile?.deletedAt}
                        previewContainerSx={{ width: 75, height: 75 }}
                        hideOverlay
                      />
                    ) : (
                      "No file attached"
                    )}
                  </TableCell>
                  <TableCell>
                    {proofOfOwnershipFile ? (
                      <ImageViewer
                        fileName={proofOfOwnershipFile?.fileName || ""}
                        file={proofOfOwnershipFile?.url as string}
                        fileSize={proofOfOwnershipFile?.fileSize ?? null}
                        archived={!!proofOfOwnershipFile?.deletedAt}
                        previewContainerSx={{ width: 75, height: 75 }}
                        hideOverlay
                      />
                    ) : (
                      "No file attached"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <SafeLeaseButton
                        endIcon={<ChevronRightOutlinedIcon />}
                        size="small"
                        sx={{ color: (theme: Theme) => theme.palette.grey.A200 }}
                        onClick={() => {
                          setCurrentDrawerType(ClaimDetailsDrawerType.LINE_ITEM_DETAILS);
                          setSelectedLineItemId(lineItem.id);
                          setDrawerOpen(true);
                        }}
                      >
                        View details
                      </SafeLeaseButton>
                      <LineItemOptions lineItem={lineItem} />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export { LineItemsTable };
