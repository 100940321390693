import { ClosedClaimsStatistics } from "@safelease/service-utilities";
import { GridFilterItem } from "@mui/x-data-grid-premium";
import { useQuery } from "@tanstack/react-query";
import { ClaimsApi } from "../../../../utils/apiInstances/ClaimsApiInstance";
import { useEffect, useState } from "react";
import { ClaimStatus } from "@safelease/service-utilities/enums";
import { Metric, MetricGroup, SafeLeaseCard } from "@safelease/components";
import { Skeleton, SxProps } from "@mui/material";
import { useClaimsNavigatorStore } from "./useClaimsNavigatorStore";

type ClosedClaimsSummarizedMetricsCardProps = {};

function ClosedClaimsSummarizedMetricsCard({}: ClosedClaimsSummarizedMetricsCardProps) {
  const [statusFilterList, setStatusFilterList] = useState<ClaimStatus[]>([]);

  const dataGridApiRef = useClaimsNavigatorStore((state) => state.dataGridApiRef);

  const closedClaimsStatisticsQuery = useQuery<ClosedClaimsStatistics>({
    queryKey: [`getClosedClaimsStatistics`],
    queryFn: async () => {
      const response = await ClaimsApi.getClosedClaimsStatistics();
      return response.data;
    },
  });

  /**
   * Update the status filter in the data grid when the statusFilterList changes
   * Every time we click the Metric component, we add or remove the corresponding status to the statusFilterList
   */
  useEffect(() => {
    if (!dataGridApiRef?.current?.deleteFilterItem || !dataGridApiRef.current?.setFilterModel) return; // The dataGridRef is not ready yet since the component is still loading

    const filterItemForStatus: GridFilterItem = {
      id: "status",
      value: statusFilterList,
      field: "status",
      operator: "isAnyOf",
    };

    // clear the filter item and then add it back with the updated value of statusFilterList
    dataGridApiRef.current.deleteFilterItem(filterItemForStatus);
    dataGridApiRef.current.upsertFilterItem(filterItemForStatus);
  }, [dataGridApiRef, statusFilterList]);

  /**
   * Function to handle the click event on a Metric component
   * The list of statuses to filter is updated based on the Metric component clicked
   * some of them may call this function multiple times for a single click event
   */
  const handleMetricClick = (status: ClaimStatus) => {
    setStatusFilterList((prev) => {
      if (prev.includes(status)) {
        return prev.filter((filteredStatus) => filteredStatus !== status);
      } else {
        return [...prev, status];
      }
    });
  };

  // Styling for the Metric component so that we only define it once and not repeat code for each Metric component
  const MetricContainerSxProps = (statuses: ClaimStatus[]) => {
    const highlightedStatus: boolean = statuses.every((status) => statusFilterList.includes(status));
    const sxProp: SxProps = {
      borderRadius: 1,
      padding: 1,
      backgroundColor: highlightedStatus ? "rgba(39, 121, 251, 0.2)" : null,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(39, 121, 251, 0.2)",
      },
    };
    return sxProp;
  };

  if (closedClaimsStatisticsQuery.isLoading || !closedClaimsStatisticsQuery.data)
    return <Skeleton variant="rounded" width={"100%"} height={90} />;

  return (
    <SafeLeaseCard sx={{ padding: 1, display: "flex", height: 90 }}>
      <MetricGroup>
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedSettled);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedSettled]),
          }}
          value={closedClaimsStatisticsQuery.data.totalPaidClaimCount}
          label="Total Closed - Settled"
          tooltip="Total paid claims"
        />
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedDenied);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedDenied]),
          }}
          value={closedClaimsStatisticsQuery.data.totalDeniedClaimCount}
          label="Total Closed - Denied"
          tooltip="Total denied claims"
        />
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedDuplicate);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedDuplicate]),
          }}
          value={closedClaimsStatisticsQuery.data.totalDuplicateClaimCount}
          label="Total Closed - Duplicate"
          tooltip="Total duplicate claims"
        />
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedIncomplete);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedIncomplete]),
          }}
          value={closedClaimsStatisticsQuery.data.totalIncompleteClaimCount}
          label="Total Closed - Incomplete"
          tooltip="Total incomplete claims"
        />
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedInactive);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedInactive]),
          }}
          value={closedClaimsStatisticsQuery.data.totalInactiveClaimCount}
          label="Total Closed - Inactive"
          tooltip="Total inactive claims"
        />
        <Metric
          containerProps={{
            onClick: () => {
              handleMetricClick(ClaimStatus.ClosedByTenant);
            },
            sx: MetricContainerSxProps([ClaimStatus.ClosedByTenant]),
          }}
          value={closedClaimsStatisticsQuery.data.totalTenantClosedClaimCount}
          label="Total Closed - Tenant Closed"
          tooltip="Total tenant closed claims"
        />
        <Metric value={closedClaimsStatisticsQuery.data.totalClosedClaimCount} label="Total Closed" tooltip="Total closed claims" />
      </MetricGroup>
    </SafeLeaseCard>
  );
}

export { ClosedClaimsSummarizedMetricsCard };
